//import { API_URL, API_V2_URL, API_QMS_URL, BUCKET_NAME, API_FR_URL } from "@env"

export class Serviceurls {

    // static DOMAIN = "https://dev.api.emedhub.in/v2/";
    // static BASE_DOMAIN = "https://dev.api.emedhub.in/";
    static WEB_SOCKET_DOMAIN = "wss://api.emedhub.in/ws/";


    static UAT_DOMAIN = "https://uat.v3.api.emedhub.in/v2/"
    static UAT_BASE_DOMAIN = "https://uat.v3.api.emedhub.in/"

    // beta or line url ----
    // api.emedhub.in
    // static UAT_DOMAIN = "https://api.emedhub.in/v2/"
    // static UAT_BASE_DOMAIN = "https://api.emedhub.in/"

    static DOMAIN = this.UAT_DOMAIN;
    static BASE_DOMAIN = this.UAT_BASE_DOMAIN;

    // static DOMAIN = API_V2_URL;
    // static BASE_DOMAIN = API_URL;
    // static QMA_DOMAIN = API_QMS_URL;
    // s3 Bucket name in Document Page
    //static s3BUCKET_NAME = BUCKET_NAME

    // user role
    static REG_EX_MOBILENO = /[^.[0-9]]*/
    static ROLE_FO = "FO";
    static ROLE_DOCTOR = "doctor";
    static ROLE_OPT = "OPHTHALMOLOGY";
    static ROLE_OPTOMETRY = "OPTOMETRY";
    static ROLE_COUNSELLOR = "COUNSELLOR"

    static LANGUAGES = this.DOMAIN + "languages/"
    static GET_STATE = this.BASE_DOMAIN + "pharmacy/states/";
    static API_SIGN_IN = "auth/login/";
    static HOME_APPOINTMENTS = "doctor/appointments/list/";
    static REG_EX_MOBILENO = /[^.[0-9]]*/

    /**start ---Pharmacy Management API *
    //  * Module List*/
    static GET_MODULES_LIST = "auth/user/linked/modules/"
    /**Reports - Sales Report */
    static GET_SALES_OVERVIEW = "administrator/pharmacy/reports/sales_overview/";
    static GET_SALES_DETAILED = "administrator/pharmacy/reports/sales_detailed/";
    static GET_SALES_GST = "administrator/pharmacy/reports/sales_gst/";
    static GET_SALES_GST_SPLITUP = "administrator/pharmacy/reports/sales_gst_splitup/";
    static GET_SALES_RETURN = "administrator/pharmacy/reports/sales_return/";
    static GET_SALES_MONTHLY = "administrator/pharmacy/reports/sales_monthly/";
    static GET_SALES_DAYEND = "administrator/pharmacy/reports/sales_dayend/";
    static GET_SALES_CONSOLIDATE = "administrator/pharmacy/reports/sales_consolidated/";
    /**Reports - Purchase Report */
    static GET_PURCHASE_OVERVIEW = "administrator/pharmacy/reports/purchase_overview/"
    static GET_PURCHASE_DETAILS = "administrator/pharmacy/reports/purchase_detailed/"
    static GET_PURCHASE_RETURN = "administrator/pharmacy/reports/purchase_return/"
    static GET_PURCHASE_GST = "administrator/pharmacy/reports/purchase_gst/"
    static GET_PURCHASE_MONTHLY = "administrator/pharmacy/reports/purchase_monthly/"
    /**Reports - Purchase Report */
    /** Stock Adjustment Edit Form */
    static STOCK_ADJUSTMENT_EDIT_FROM = "administrator/pharmacy/inventory/stock_adjustment";
    /** Stock Adjustment Edit Form*/
    /**Inventory - Stock Adjustment*/
    static GET_STOCK_ADJUST_LIST = "administrator/pharmacy/inventory/stock_adjustment"
    static GET_DRUG_LIST = "administrator/dashboard/dosage_types/"
    /**Inventory - Stock Adjustment - History*/
    static GET_STOCK_ADJUST_HISTORY_LIST = "administrator/pharmacy/inventory/stock_adjustment/history/"
    static INVENTORY_STOCK_LIST_GET = "lab/product/stock/list/"
    static INVENTORY_STOCK_LIST_PUT = "lab/product/correction/"
    static INVENTORY_STOCK_LIST_DETAILS_GET = "lab/product/stock/detail/"
    static INVENTORY_ACTIVE_INVENTORY_GET = "lab/drug/list/"
    static INVENTORY_ACTIVE_INVENTORY_POST = "lab/drug/list/"
    static INVENTORY_ACTIVE_INVENTORY_BULK_POST = "lab/drug/list/status/"
    static INVENTORY_NEW_DRUG_GET = 'lab/new/product/registry/'
    static INVENTORY_NEW_DRUG_PUT = 'lab/new/product/registry/'
    static INVENTORY_STOCK_EXPIRY_GET = "lab/report/expiry/"
    static INVENTORY_NO_MOVEMENT_GET = "lab/no/movement/product/"
    /**Reports - Stock Report ----- start */
    static PHARMACY_REPORT_STOCK_REPORT = "administrator/pharmacy/reports/stock_report/"
    static PHARMACY_REPORT_MIS_REPORT = "administrator/pharmacy/reports/stock_mis/"
    static PHARMACY_REPORT_Lowstock = "administrator/pharmacy/reports/stock_lowstock/"
    static PHARMACY_REPORT_Expiry_Stock = "administrator/pharmacy/reports/stock_expiry/"
    static PHARMACY_REPORT_SlowMovement_Stock = "administrator/pharmacy/reports/stock_slowmovement/"
    static PHARMACY_REPORT_NoMovement_Stock = "administrator/pharmacy/reports/stock_nomovement/"
    /**Reports - Stock Report ----- end */
    /**Pharmacy Management API ---- end*/

    /**Laboratory API**/
    static LAB_VENDOR = "lab/vendor/detail/"
    // Lab Billing
    static BILLING_REFERRED_DOCTOR = "lab/billing/referred-by/"
    static BILLING_REFERED_SOURCE = "fo/referrel-list/"
    static LAB_COMPLETE_BILL_POST = "lab/bill/complete/new/"
    static LAB_PARTIAL_BILL_POST = "lab/partial/bill/new/"
    static LAB_PARTIAL_BILL_LIST = "lab/partial/bill/list/"
    static LAB_PATINET_STATUS = "lab/result/summary/"
    static LAB_PREVIOUS_REPORT = "lab/report/previous/"
    static LAB_PATENT_SPECIMAN_COLLECTED = "lab/speciman/collection/"
    static LAB_HOME_SEARCH = "api/search/"
    static LAB_CREATE_PATIENT_DETAILS = "lab/create/patient/profile/"
    static LAB_SAVED_BILL_LIST = "lab/partial/bill/list/"
    static LAB_SAVED_BILL_DELETE = "lab/bill/cancel/new/"
    static LAB_PARTIAL_BILLING = "lab/partial/billing/"
    static LAB_PATIENT_DETAILS = "lab/patient/details/"
    static LAB_PRODUCT_DETAILS = "lab/new/product/registry/"
    static LIST_VENDOR = "lab/vendors/list/"
    static LAB_TEST_CONFIG_GET = "lab/test/config/"
    static LIST_VENDOR = "lab/vendor/detail/search/"
    static LAB_PATIENT_TRANSMIT_GET = "lab/transmitted/test/search/"
    static LAB_PATIENT_TRANSMIT_SEARCH = "lab/home/patient/search/"
    static LAB_TEST_REPORT_PRINT = "lab/bill/report/"

    static REPORTS_OUTSOURCE_GET = "lab/report/outsource/"
    static LAB_CREATE_BILL_PARTIAL_PRINT = "lab/partial/bill/new/"
    static LAB_CREATE_BILL_COMPLETED_PRINT = "lab/bill/complete/new/"

    static LAB_CLINIC_LIST_GET = "lab/clinic/data/"

    static LAB_GROUP_BASED_LIST_GET = "lab/group/report/"
    static LAB_TEST_BASED_LIST_GET = "lab/test/report/"

    //Result
    static LAB_PATIENT_RESULT_GET = "lab/test/result/"
    static LAB_PATIENT_RESULT_POST = "lab/test/result/"
    static LAB_PATIENT_RESULT_PUT = 'lab/test/result/'
    static LAB_PATIENT_BACTERIA_GET = 'lab/bacteria/list/'
    static LAB_PATIENT_ANTI_BACTERIA_GET = 'lab/anti-bacteria/list/'
    static LAB_PATIENT_OUTSOURCE_PATCH = "lab/test/result/"
    static LAB_PATIENT_OUTSOURCE_GET = "lab/test/result/"
    static LAB_PATIENT_RESULT_DELETE = "lab/test/result/"
    static LAB_PATIENT_RESULT_PRINT = "lab/test/report/print/"
    static LAB_RESULT_SIGNATURE = "lab/sign/details/"
    static LAB_FOR_TECHNICIANS = "lab/test/technician/"
    //Lab purchase 
    static LAB_VENDOR_DETAIL = "lab/vendor/detail/list/"  //INDIVIDUAL VENDOR DETAILS
    static LAB_PO_BRAND_SUGGESTION = "lab/po/brand/search/"
    static LAB_PO_PRODUCTDETAILS = "lab/po/brand/detail/"
    static LAB_PURCHASEORDER_LIST = "lab/purchase/order/list/"
    static LAB_WANTED_LIST = "lab/wanted/stock/list/"
    static LAB_WANTED_LIST_POST = "lab/move/to/order/"
    static LAB_ORDER_LIST = "lab/move/to/order/"
    static LAB_CONFIRM_ORDER_LIST = "lab/order/detail/" //GET
    static LAB_CONFIRM_PLACE_ORDER = "lab/purchase/order/"
    static LAB_DIRECT_PO = "lab/product/direct/po/"    // post
    static LAB_TEST_GROUP_GET = "lab/test/labset/"
    static LAB_REFERRED_DOCTOR = "lab/referral/doctor/";
    static LAB_CANCEL_BILL = "lab/bill/cancel/new/"
    static LAB_OUTSOURCE_CONFIG = "lab/outsource/config/"
    static LAB_VENDOR_PAYMENT_LIST = "lab/vendor/payment/"
    static LAB_VENDOR_PAYMENT_MONTH = "lab/vendor/payment/month/"
    static LAB_VENDOR_HISTORY = "lab/vendor/payment/history/"
    static LAB_OUTSOURCE_CONFIG = "lab/outsource/config/";
    static LAB_PATIENT_REPORT = "lab/patient/report/";
    static LAB_DOCTOR_REPORT = "lab/doctor/report/";
    static LAB_BILL_REPORT = "lab/bill/report/";
    static LAB_OUTSOURCE_MAPPING = "lab/outsource/mapping/"
    static LAB_SEARCH_PATIENT = "lab/patient/search/"
    static LAB_CONSUMABLES_LIST_GET = "lab/consumable/search/"
    static LAB_CONSUMABLES_GET = "lab/consumable/service/map/"
    static LAB_DATA_FROM_PURCHASE = "lab/po/stock/items/"
    static LAB_ADD_TO_STOCK = "lab/product/add/po/"
    static LAB_PATIENT_DETAILS_GET = "lab/patient/demographics/"
    static LAB_PATIENT_REPORT_PRINT = "lab/patient/report/"
    static LAB_DOCTOR_REPORT_PRINT = "lab/doctor/report/"
    static LAB_BILL_REPORT_PRINT = "lab/bill/report/"
    static LAB_PATIENT_OUTSOURCE_PRINT = "lab/report/outsource/"
    static LAB_CHECK_BATCH_NO = "lab/po/batch/list/"
    static LAB_PREVIOUS_PRICE = "lab/previous/prize/detail/"
    static LAB_CULTURE_TEST_CONFIG = "lab/culture/"
    static LAB_CENSUS_REPORT = "lab/census/report/"

    //FO - OP Service Configuration API
    static FO_CONFIG_OPSRCNAME = "fo/service/config/new/"
    static FO_CONFIG_OPSRCTEMPLATE = "fo/template/"
    static FO_CONFIG_OPSPECIALIZATION = "fo/specialization/"
    static FO_CONFIG_OPDOCTORNAME = "fo/doctor/name/"
    static FO_CONFIG_OPAPPOINTMENT = "fo/appointment/type/"
    static FO_CONFIG_OPBILLPREFIX = "fo/billprefix/config/"  // yet to come
    static FO_CONFIG_OPTOKENPREFIX = "fo/prefix/config/"
    static FO_CONFIG_OPPATIENTTAG = "fo/patient/tag/"
    static FO_CONFIG_OPSRCNAMEANDTYPE = "fo/search/service/"
    static FO_CONFIG_OPSPLGET = "fo/specilation/type/"
    static FO_POV_LIST = "fo/pov/"
    static SCHEDULE_POST_API = "fo/doctor/schedule/"
    static SCHEDULE_GET_API = "fo/doctor/schedule/list/"
    static SCHEDULE_TIME_GET = "doctorv4/slot/list/"
    // static SCHEDULE_TIME_GET = "doctor/schedule/time/"
    //FO - Corporate Configuration API
    static FO_CONFIG_CORPORATE = "fo/corporate/detail/";
    //FO - Referral Configuration API
    static FO_CONFIG_REF_SOURCE = "fo/referral/source/"
    static FO_CONFIG_REF_DOCTOR = "fo/referral/doctor/"
    //FO - User Configuration
    static FO_CONFIG_USER_CONFIGURATION = 'fo/user/list/config/'
    //FO - Home screen API
    static FO_HOME_OP = "fo/appointment/list/"
    static FO_HOME_OP_STATUS = "fo/appointment/status/update/"
    static FO_HOME_OP_POV_UPDATE = "fo/appointment/update/pov/"
    //FO - Get Selected Modules Data
    static MODULES_DATA_GET = 'auth/user/token/'
    //FO - Patient Details
    static FO_DOCTOR_VISIT_REPORT = "fo/visit/report/"
    static FO_TEST_REPORT = "fo/test/report/"
    //FO - Billing Transaction API
    static OVERALL_BILLS_GET = "fo/overall/billing/"
    static CREDIT_BILLS_GET = "fo/credit/report/new/"
    static COMPLETE_BILLS_GET = "fo/complete/billing/"
    static DAY_END_STATUS_GET = "fo/dayend/bill/report/"
    static DAY_END_STATUS_Filter = "doctor/billing/transaction/"
    static DAY_END_USER_DETAIL_GET = "fo/user/list/config/"
    //FO - Reports
    static FO_REPORTS_SERVICE_OVERVIEW = "fo/service/overview/"
    static FO_REPORTS_CONSULT_WISE = "fo/consultation/report/"
    static FO_REPORTS_SERVICE_DETAILED = "fo/service/detailed/"
    static FO_REPORTS_IP_SERVICE_OVERVIEW = "ip/report/service/overview/"
    static FO_REPORTS_IP_SERVICE_DETAILED = "ip/report/service/detailed/"
    static FO_REPORTS_IP_LEGER_REPORT = "ip/leger/report/view/"

    static FO_REPORTS_CORPORATE_PATIENT = "fo/corporate/patient/report/"
    static FO_REPORTS_CORPORATE_PAYMENT = "fo/corporate/summary/report/"
    static FO_REPORTS_INSURANCE_PATIENT = "ip/insurance/patient/report/"

    static FO_REPORTS_APPOINTMENT_PATIENT = "fo/patient/appointment/report/"
    static FO_COUNSELLOR_GET = "fo/counsellor/list/"
    static FO_REPORTS_COUNSELLOR_SURGERY = "fo/counsellor-surgery/report/"
    static FO_REPORTS_APPOINTMENT_DOCTOR = "fo/doctor/appointment/report/"
    static FO_REPORTS_APPOINTMENT_SPECIALIZATION = "fo/speciality/appointment/report/"
    static FO_REPORTS_APPOINTMENT_TYPE = "fo/appointment/type/report/"

    static FO_REPORTS_DEMOGRAPHICS = "fo/patient/demographic/report/"

    static FO_REPORTS_REFERRAL_DOCTOR = "fo/referral/doctor/report/"
    static FO_REPORTS_REFERRAL_SOURCE = "fo/referral/source/report/"
    static FO_REPORTS_REFERRAL_VALUE = "ip/consultation/report-other/"

    static FO_REPORTS_POST = "fo/new/report/request/"
    static FO_REPORTS_IPADMISSIION = 'ip/report/ip-admission/list/'
    static FO_REPORTS_IP_DISCHARGE = 'ip/report/ip-discharge/list/'
    static FO_REPORTS_CONCESSION = "fo/concession/report/"
    static FO_REPORT_CREDIT_ADJUSTED_REPORTS = "fo/credit/settlement/"

    //Accounts Reports
    static FO_REPORTS_ACCOUNTS_CONCESSION = "accounts/concession/task/"
    static FO_REP_CRED_SERVICE = "accounts/credit/service/report/task/"
    static FO_REPORTS_ACCOUNTS_DOCTORSHARE = "accounts/doctor/share/report/task/"
    static FO_REPORTS_ACCOUNTS_CREDITSETTLEMENTREPORT = "accounts/credit/settlement/report/task/"

    //FO - Custom Config
    static CUSTOM_CONFIG = "fo/custom-config/"
    static CUSTOM_BARCODE_CONFIG = "fo/custom-barcode-config/"

    //FO - Nurse Notes
    static FO_NURSE_NOTES = "fo/patient/vital/"
    static FO_STAFF_LIST = "fo/staff/list/"
    static FO_INSURANCE_LIST = "fo/insurance/list/"
    static FO_PATIENT_LAST_VISIT_DATE_AND_BALANCE_AMOUNT = "fo/patient/outstanding/visits/"
    //FO - IP Service Configuration
    static FO_CONFIG_IPSRCNAME = "ip/service/configuration/"
    static FO_CONFIG_IPSRCPACKAGE = "ip/package/"
    static FO_CONFIG_IPSRCHISTORY = "ip/service/history/"
    // IP - Room Configurations
    static HOSPITAL_CONFIG = "ip/hospital/config/"
    static WARD_CONFIG = "ip/ward/config/"
    static BED_CONFIG = "ip/bed/config/"
    static USER_ROOM_TYPE = "ip/room/type/"
    static HISTORY_DATA = "ip/history/data/"
    static BED_SELECT = "ip/bed/avail/detail/"
    static BED_AVAIL = "ip/report/beds/"

    //Lab
    static LAB_METHOD_LIST = "lab/test/methods/"
    static LAB_MATERIAL_TYPE = "lab/material/Type/list/"
    static LAB_PRODUCT_TYPE = "lab/product/type/list/"
    static LAB_PRODUCT_UNIT = "lab/product/unit/list/"
    static LAB_SMS_POST = "lab/bill/sms/"

    // IP Admission Create
    static IP_PATIENT_CREATE = "ip/patient/create/"
    static IP_ADMISSION_DETAILS = "ip/admission/create/"
    static IP_ADVANCE_PAYMENT = "ip/advance/payment/"


    static IP_SERVICE_BILL_POST = 'ip/service/billing/'
    static IP_PATIENT_DETAILS = "ip/patientheader/"
    static GET_CORPORATE_CLAIM_DETAILS = "fo/corporate/claim-detail/"

    static LAB_E_SIGNATURE_CONFIG = "lab/esign/"
    //FO IP Home Patient Details

    static FO_PATIENT_SERVICE_RECEIPT = "ip/patientdetails/"
    static FO_PATIENT_ADMISSION = "ip/admissondetails/"
    static FO_PATIENT_LABORATORY = "ip/testtransmit/"
    static FO_PATIENT_ROOM_CHANGE = "ip/roomchange/"
    static FO_PATIENT_DETAILS = "ip/patientheader/"
    static IP_RECEIVE_PAYMENT_GET = "ip/receive/payment/"
    static IP_REFUND_PAYMENT_POST = "ip/refund/payment/"
    static FO_PATIENT_DETAILS_IP_ACTION = "ip/action/update/"
    //IP Discharge Summary Config
    static DIS_SUM_LAYOUT = "ip/discharge/summery/"
    static SUR_NOTES_LAYOUT = "ip/surgery/notes/"
    // Fo Appointment Create
    static FO_CREATE_APPOINTMENT = "fo/patient/appoinment/"
    static OP_PATIENT_DEMO_UPDATE = "op/patient/update/"
    static FO_PATIENT_SEARCH = "fo/patient/search/"
    // Fo Ip Home
    static FO_IP_HOME = "ip/home/ipappoinment/"
    //FO IP Template Config 
    static IP_TEMPLATE_DIS_SUM = "ip/discharge/summary/template/"
    static GET_ICD_CODE = "ip/icdcode/search/"
    static IP_TEMPLATE_SUR_NOTE = "ip/surgery/notes/template/"

    //FO - Demographics config
    static FODemographicsLayout = 'fo/field/order/'

    //Fo - Sms Configuration 
    static FO_SMS_REQUEST = "fo/sms/request/"
    static FO_SMS_CONFIG = "fo/sms/configuration/"
    static FO_SMS_CONFIG_ALERT_REMOVE = "fo/ignore/sms-threshold/"

    //FO - Patient Create
    static FO_PATIENT_CREATE = 'fo/patient/create/'
    static FO_PATIENT_UPDATE = 'fo/patient/update/'
    static FO_PATIENT_APPOINTMENT = 'fo/patient/appoinment/'
    // Fo Ip Home
    static FO_IP_HOME = "ip/home/ipappoinment/"
    static GET_RADIOLOGY_LIST = "radiology/clinic/linked/radiology/"
    static FO_PATIEMT_BILLING = "fo/billing/invoice/"
    static FACE_RECOG_SEARCH = "fo/patient/face/search/"
    static FO_APPOINT_REQUESTS = "fo/appointment/request/list/"

    static FO_PATIEMT_BILLING = "fo/billing/invoice/"
    static FACE_RECOG_SEARCH = "fo/patient/face/search/"

    static IP_ROOM_CHANGE = "ip/room/change/"
    static IP_ROOM_CHANGE_POST = 'ip/room/create/'

    static IP_DIS_SUM_TEM = "ip/discharge/layout/template/"
    static IP_DIS_SUM_POST = "ip/discharge/summary/"
    static IP_DIS_SUM_COPY_DATA = "ip/dischage-summary/copy/"

    // IP Patient Doctor Notes
    static IP_DOCNOTES_SYMPTOMS = 'ip/symptoms/'
    static IP_DOCNOTES_EXAMINATION = 'ip/examinations/'
    static IP_DOCNOTES_DIAGNOSIS = 'ip/diagnosis/'
    static IP_DOCNOTES_PROVISIONAL_DIAG = 'ip/diagnosisnotes/'
    static IP_PREVIOUS_TEST_RECORDS = 'ip/discharge/summary/test-reports/'
    static IP_CLINICNOTES_HISTORY = "ip/clinicnotes/history/"
    static IP_DOCNOTES_SYMPTOMS_SUGGEST = "ip/symptoms/search/"
    static IP_DOCNOTES_SYMPTOMS_FREQUENT = "ip/frequent/symptoms/"
    static IP_DOCNOTES_EXAMINATION_SUGGEST = "ip/examinations/search/"
    static IP_DOCNOTES_EXAMINATION_FREQUENT = "ip/frequent/examinations/"
    static IP_DOCNOTES_DIAGNOSIS_FREQUENT = "ip/frequent/icd/diagnosis/"


    // FO IP MEDICATION AND LAB 
    static IP_MEDICATION_GET = "ip/medications/"
    static IP_LAB_GET = "ip/inpatient/labbilling/"

    //FO IP Clinical Notes
    static FO_IP_CLINICAL_NOTES_VITAL = 'ip/inpatient/vitals/'
    static IP_SUR_NOTE_TEM = "ip/surgery/layout/template/"
    static IP_SN_CREATE = "ip/surgery/notes/create/"
    static FO_IP_CLINICAL_NURSE_NOTES = "ip/nursenotes/"
    static FO_IP_HISTORY = "ip/clinicnotes/history/"
    static FO_IP_PHARMALIST_GET = "ip/pharmacy/list/"
    static FO_IP_BRANDNAMELIST_GET = "ip/medication/drug/search/"
    static FO_IP_BRANDDETAILS_GET = "ip/medication/drug/detail/"
    static FO_IP_FREQUENTDRUG = "ip/frequent/medications"
    static FO_IP_LABLIST = "ip/lab/list/"
    static FO_IP_LABTESTSET = "ip/lab/test/set/"
    static IP_PAIN_CHART = "ip/painchart/"

    static FO_GET_APPOINMENT_DETAILS = "fo/appointment/details/"
    static FO_GET_IP_ADMISSION_DETAILS = "ip/admission/details/"
    static OP_INVOICE_PRINT = "fo/invoice/print/"
    static IP_RECEIVE_PAYMENT_PRINT = "ip/final/bill/print/"
    static IP_INVOICE_PRINT = "ip/bill/print/"
    static FO_PATIENT_APPOINTMENT_DETAILS = "fo/appointment/details/"
    static FO_PATIENT_APPOINTMENT_SAVED_GET = "fo/patient/appointment/saved/bill/"
    static FO_PATIENT_APPOINTMENT_COMPLETED_GET = "fo/patient/complete/bill/"
    static FO_PATIENT_BILL_DETAILS_GET = "fo/patient/bill/"
    static FO_PATIENT_COUNT_DETAILS_GET = "fo/appointment/status-count/"
    // Print Config
    static PRINT_CONFIG = "core/printconfig/"
    static PRINT_HISTORY = "core/printconfig/history/"
    // Bill Report 
    static RECEIPT_REPORT = "fo/recept/billing/"
    //User Access
    static USER_ACCESS_CONFIGURATION = "fo/user/access/"
    static USER_ACCESS_ROLE_GET = "fo/login/role/"
    static USER_ACCESS_GET_LIST = "fo/user/access/list/"
    static USER_ACCESS_GET_MODULES = "fo/lincked/modulelist/"
    // Admission history 
    static ADMISSION_HISTORY_GET = "fo/appointment/history/"
    static AUTOBILLING_GET = "fo/hospital/config/"
    static IP_AUTOBILLING_GET = "ip/ipauto-billing/"

    //pharmacy invioce print
    static PHARMACY_INVOICE_PRINT = "pharmacy/billing/"
    static LAB_PARTIAL_BILLS = "lab/partial/bill/new/"
    static LAB_COMPLETED_BILLS = "lab/bill/complete/new/"

    //IP HOME BILL DETAILED PRINT
    static IP_PATIENT_BILL_DETAILED_PRINT = "ip/detail/bill/print/"

    static IP_PATIENT_DISCHARGE_SLIP = "ip/dischage-summary/slip/"

    // Optical CRM Sales Report
    static OPTICAL_CRM_CUS_DETAILS = "optical-crm/customer-detail/"
    // Optometrist Report
    static GET_OPTOMETRIST_DETAILS = "fo/optometrist/appoitment/report/"
    static GET_OPTOMETRIST_NAME_LIST = "doctor/clinic/optometrist/list/"

    //Prescription Report
    static GET_PRESCRIPTION_REPORT_DATA = "fo/prescription/report/"

    //Doctor Notes,Op card and prescription Print
    static OP_DOCTOR_VISIT_REPORT_PRINT = "fo/doctor/notes/print/"
    static OP_DOCTOR_PRECSCRIPTION_PRINT = "fo/medication/print/"
    static OP_CARD_PRINT = "fo/barcode/print/"
    static OP_CUSTOMIZED_CARD_PRINT = "fo/custom-barcode/print/"

    static FO_APPINMENT_PRIENT = "fo/appointment/list/print/"

    //Ip Barcode Label Print
    static IP_BARCODE_PRINT = "ip/label/print/"
    static COMMON_BARCODE_PRINT = "fo/patient/label/print/"
    static IP_DISCHARGE_SUMMARY_TEST = "ip/inpatient/discharge/summery/labtest/"

    //Ip Patient Admission Print
    static IP_PATIENT_ADMISSION_PRINT = "ip/ipadmission/print/"
    static IP_APP_PRINT = "ip/admission/list/print/"
    static OP_AUTO_BILLING = "fo/auto/billing/new/"
    static IP_DIS_SUM_PRINT = "ip/discharge/summary/"

    static IP_APPROX_AMOUNT = "ip/bill-value/"
    static FO_RECEIPT_BILL = "fo/patient/receipt/bill/" // fo receipt bill
    static LAB_RECEIVE_PAYMENT = "lab/receive/payment/"

    static OP_PATIENT_CREDIT_BILL_POST = "fo/receive/payment/"

    static LAB_STATUS_AND_TEST_REPORT_AMOUNT_GET = "lab/admission/bill/"
    static LAB_RECEIPT_REPORT = "lab/receipt/report/"

    static DOCTOR_NOTES_PRINT = "doctorv4/print/visit-report/"
    static OPT_PATIENT_DOCTOR_NOTES_PRINT = "doctor/opt/visit/report/"
    static PRINT_PRESCRIPTION = "doctorv4/print/prescription/"
    static OPT_PRESCRIPTION_PRINT = "doctor/opt/medication/print/"

    static SUMMERY_BILL_REPORT = "fo/consolidate/bill/summary/"
    static SUR_NOTE_PRINT = "ip/surgery/notes/print/"
    static RECEIPT_INDIV_PRINT = "ip/receipt/print/"
    static OP_DETAILED_BILL_PRINT = "fo/detail/invoice/print/"
    static OP_SUMMARY_BILL_PRINT = "fo/summary/bill/report/"
    static OP_PRES_TEMPLATE = "fo/prescription/sheet/"

    static IP_PATIENT_INDIVIDUAL_SAVED_BILL = "ip/saved/bill/list/"
    static IP_PATIENT_INDIVIDUAL_BILLS = "ip/bill/list/"
    static IP_PATIENT_INDIVIDUAL_RECEIPT_Bill = "ip/receipt/bill/list/"
    static IP_ADMISSION_BILL_SUMMARY_PRINT = "ip/summary/bill/detailed/print/"
    static IP_ADMISSION_BILL_APPROX_PRINT = "ip/approx/bill/print/"
    //Cancel Bill

    static OP_CANCEL_BILL = "fo/cancel/bill/"
    static IP_CANCEL_BILL = "ip/cancel/bill/"
    static LAB_CANCEL_BILL_REFUND = "lab/cancel/bill/"

    static IV_IM_TRACK = "ip/ivim/chart/"

    // Corporate Billing
    static GET_PATIENT_BILLING_DEATILS = "fo/corporate/ip/bills/";
    static CORPORATE_BILLING = "core/employer/bill/";
    static CORP_LIST_GET = "core/employer/"
    static DOCTOR_SHARE_REPORT = "fo/hospital/doctor/share/"
    static FO_REPORTS_TAT = "fo/tat/report/"
    static FO_REPORTS_PURPOSEOFVISIT = "fo/patient/pov/view/"
    static OP_NURSE_NOTES = "fo/nurse-notes/"
    static OP_NURSE_HIS = "fo/nurse-notes/history/"
    static FO_RESERVATION_BEDSLT = "ip/bed/reservation/detail/"
    static FO_RESERVATION_GET = "ip/room/reservation/"
    static FO_REPORTS_REASON_DIAGNOSIS = "fo/complaints/diagnosis/view/"
    static IP_COMPLAINTS_DATASET = "ip/reason/"

    static MEDICAL_RECORD_PRINT = "fo/medical/record/"
    static IP_ATTENDER_CHECKOUT = "ip/attender/checkout/"
    static FO_RESERVATION_FILTER_GET = "ip/patient/attender/reservation/"
    static FO_RESERVATION_COUNT_LIST = "ip/ward/room/availability/count/"

    // Ip Estimation 
    static IP_ESTIMATE_BILL_GET = "ip/estimate/bill/"
    static IP_ESTIMATE_LIST_GET = "ip/estimate/list/"
    static EXPENSE_CATEGORY_GET = "doctor/expense/category/"
    static EXPENSE_BILL_GET = "doctor/expense/bill/report/"

    //PHARMACY ===> URL'S STARTS
    // Pharmacy Sales Report
    static PHARMA_SALES_OVERVIEW_REPORT_GET = 'pharmacy/reports/sales/overview/'
    static PHARMA_SALES_DETAILED_REPORT_GET = 'pharmacy/reports/sales/detailed/'
    static PHARMA_SALES_RETURN_REPORT_GET = 'pharmacy/sales/return/report/new/'
    static PHARMA_SALES_MONTHLY_REPORT_GET = 'pharmacy/reports/sales/monthly/'
    static PHARMA_DAY_END_REPORT_GET = 'pharmacy/reports/sales/day-end/'
    static PHARMA_PRODUCT_WISE_SALE = 'pharmacy/product/sales/report/'
    static PHARMA_RETURN_TAX_WISE_SALES = 'pharmacy/sales/return/tax/report/'
    static SALES_RETURN_HSN_CODE_WISE = 'pharmacy/sales/return/hsn/report/'
    static SALES_RETURN_HSN_CODE_RATE_WISE = 'pharmacy/sales/return/hsn/tax/report/'
    static SALES_REPORTS_PROFITANALYSIS = 'pharmacy/profit/analysis/report/'
    static SALES_BILL_TAX_WISE = 'pharmacy/reports/sales/invoice/tax/'
    static PHARMA_USER_SALES_PERFORMANCE = 'pharmacy/user/performance/'

    //Purchase Report
    static PHARMA_PURCHASE_OVERVIEW_REPORT_GET = 'pharmacy/reports/purchase/overview/'
    static PHARMA_PURCHASE_DETAILED_REPORT_GET = 'pharmacy/reports/purchase/detailed/'
    static PHARMA_PURCHASE_RETURN_REPORT_GET = 'pharmacy/reports/purchase-return/'
    static PHARMA_PURCHASE_MONTHLY_REPORT_GET = 'pharmacy/reports/purchase/monthly/'
    static PHARMA_PURCHASE_CONSOLIDATED_REPORT_GET = 'pharmacy/consolidate-report/'
    static PHARMA_PURCHASE_BILL_TAXWISE_GET = 'pharmacy/reports/purchase/invoice/tax/'
    static PHARMA_PURCHASE_BILL_RETURN_TAXWISE_GET = 'pharmacy/reports/purchase/return/invoice/tax/'
    //Pharmacy Inventory Report
    static PHARMA_INVENTORY_STOCK_OVERVIEW_REPORT_GET = 'pharmacy/reports/stock/'
    static PHARMA_INVENTORY_BRAND_GET = 'pharmacy/drugs/auto-complete/'
    static PHARMA_INVENTORY_GENERIC_GET = 'pharmacy/drug-details/'
    static PHARMA_INVENTORY_VENDOR_GET = 'pharmacy/vendor/search/'
    static PHARMA_INVENTORY_MIS_REPORT_GET = 'pharmacy/reports/mis-report/'
    static PHARMA_INVENTORY_EXPIRY_STOCK_REPORT_GET = 'pharmacy/reports/drug-expiry/'
    static PHARMA_INVENTORY_LOW_STOCK_REPORT_GET = 'pharmacy/reorder/threshold/'
    static PHARMA_INVEMTORY_SLOW_MOVEMENT_REPORT_GET = 'pharmacy/slow-movement/'
    static PHARMA_INVENTORY_NO_MOVEMENT_REPORT_GET = 'pharmacy/no-movements/'
    static PHARMA_INVENTORY_STOCK_CORRECTION_REPORT_GET = 'pharmacy/stock_correction/report/'
    static PHARMA_INVENTORY_STOCK_VALUE_REPORT_GET = 'pharmacy/stockvalue/report/'
    static PHARMA_INVENTORY_EDIT_DRUG_POST = 'pharmacy/druglist/update/'

    //Pharmacy GST Report
    static DAY_HSN_GSTSUMMARY = 'pharmacy/gst/report/new/'

    // Pharmacy billing
    static PHARMA_BILLING_BRAND_SEARCH = 'pharmacy/drugs/search/'
    static PHARMA_BILLING_DRUG_DETAILS_GET = 'v2/pharmacy/billing/drug/details/'
    static PHARMA_RETURN_BILLING_DRUG_DETAILS_GET = 'pharmacy/billing/drugs/'
    static PHARMACY_BULK_RETURN_POST = 'pharmacy/billing/ip/return/'
    static PHARMA_BILLING_RECEIVE_PAYMENT_POST = "pharmacy/billing/new/"
    static PHARMA_BILLING_SAVED_POST = 'v2/pharmacy/partial/billing/'
    static PHARMA_BILLING_CREDIT_POST = 'v2/pharmacy/credit/billing/'
    static PHARMA_RETURN_DRUG_GET = 'pharmacy/return/drugs/search/'
    static PHARMA_SALES_BILL_PRINT = 'pharmacy/sales-bill/print/'
    static PHARMA_BILL_TOKEN_PRINT = 'pharmacy/bill-token/print/'

    // Pharmacy Purchase
    static PURCHASE_WANTED_LIST = "pharmacy/wanted/list/"
    static PURCHASE_ORDER_LIST = "pharmacy/purchase-orders/list/"
    static PURCHASE_ORDER_PO_REQUEST_LIST = "pharmacy/purchase/request/list/"
    static PURCHASE_CONFIRM_ORDER_LIST = "pharmacy/order/list/"
    static PURCHASE_CONFIRMORDER_POPUP = "pharmacy/product/list/order/"
    static PURCHASE_CONFIRMORDER_PRINT = "pharmacy/confirm_order/print/"
    static PURCHASE_PLACEORDER_POST = "pharmacy/purchase-order/"
    static PURCHASE_VENDOR_LIST = "pharmacy/vendors/list/"
    static PURCHASE_VENDOR_PAYMENT = "pharmacy/vendor/payment/transaction/"
    static PURCHASE_VENDOR_PAYMENT_PRINT = "pharmacy/vendor/payment/transaction/detailed/"
    static PURCHASE_VENDOR_MONTHLY = "pharmacy/vendor/payment/transaction/month/"
    static PURCHASE_VENDOR_MONTHLY_POST = "pharmacy/vendor/payment/"
    static PURCHASE_WANTED_LIST_CART = "pharmacy/wanted/list/cart/"
    static PURCHASE_VENDOR_ADD = "pharmacy/vendor/"
    static PURCHASE_BATCHNO_GET = "pharmacy/po-return/drug-detail/"
    static PURCHASE_RETURN_POST = "pharmacy/po-return/new/"
    static PURCHASE_MANUFACTURER_GET =  "pharmacy/manufacturer_list/"  

    //Inventory
    static LIST_STOCK = "pharmacy/stock-list/"
    static ACTIVE_DRUG_LIST = "pharmacy/new/druglist/"
    static PHARMA_GENERIC_NAME_LIST = "pharmacy/generic_name/registry/"
    static PHARMA_ADD_NEW_DRUG = "pharmacy/drug/registry/"
    static PHARMA_STOCK_DETAILS = "pharmacy/stock-details"
    static PHARMA_STOCK_RETURN_POST = "pharmacy/mark-return/"
    static PHARMA_ACTIVE_DRUG_POST = "pharmacy/new/druglist/status/"

    // Pharmacy Invoice
    static PHARMACY_OVERALL_BILL_LIST = "v2/pharmacy/overall/bill/list/"
    static PHARMACY_CREDIT_BILL_LIST = "v2/pharmacy/credit/billing/transaction/"
    static PHARMACY_PATIENT_CREDIT_BILL = "v2/pharmacy/credit/billing/"
    static PHARMACY_SAVED_BILL_LIST = "pharmacy/saved/bill/new/"
    static PHARMACY_RECEIPT_BILL_LIST = "pharmacy/receipt/bills/"
    static RECEIPT_PHARMACY_PRINT = "pharmacy/receipt/print/"

    // Pharmacy Directpo
    static PURCHASE_BRAND_NAME_GET = "pharmacy/po/drugs/search/"
    static PURCHASE_BATCH_DETAILS_GET = "pharmacy/po/drug-details/"
    static PURCHASE_DRUG_DETAILS_GET = "pharmacy/drug-details/"
    static PURCHASE_BATCH_LIST_POST = "pharmacy/batch/list/"
    static PURCHASE_DIRECT_PO_POST = "v2/pharmacy/direct/po/"
    static PURCHASE_PO_NUMBER_GET = "pharmacy/po-number/get/"
    static PURCHASE_PO_DETAILS_GET = "v2/pharmacy/stock/entry/items/"
    static PURCHASE_ADD_TO_STK_POST = "v2/pharmacy/add/to/stock/"
    static PURCHASE_DC_POST = "pharmacy/dc_entry/"
    static PRESCRIPTION_HOME = "v2/pharmacy/transmitted/prescription/list/"
    static SAVED_BILL_HOME = "v2/pharmacy/partially/saved/bills/list/"
    static IP_RETURN_HOME = "pharmacy/medication/return/list/"
    static IP_REQUEST_HOME = "pharmacy/medication/list/"
    static APPOINTMENT_HOME = "pharmacy/appointments/list/"
    static PURCHASE_PREVIOUS_PRICE_GET = "pharmacy/get/previous-drug-details"
    static IP_ADMISSION_LIST_HOME = "pharmacy/ipadmission/list"
    static PHARMACY_PO_CANCEL = "pharmacy/purchase/order/cancel/"

    //Pharmacy Settings
    static PHARMA_STOCK_CORRECTION_LIST = "pharmacy/inventory/stock_adjustment/"
    static PHARMA_DOSAGE_TYPE_LIST = "pharmacy/dosage-types/"
    static PHARMA_CONFIG_SETTINGS = "pharmacy/config/"
    static PHARMA_HEADER_SEARCH = "fo/patient/search/"
    static PHARMA_STOCK_CORRECTION_HISTORY = "pharmacy/inventory/stock_adjustment/history/"
    static PHARMA_USER_CONFIG = "pharmacy/login_user_list/"

    //Pharma Billing
    static PHARMA_DOCTOR_LIST = "pharmacy/billing/doctor/list/"
    static PHARMA_SAVED_BILL_GET = "v2/pharmacy/partial/billing/"
    static PHARMA_PATIENT_DETAILS_GET = "pharmacy/search/patient/"
    static PHARMA_BILLS_GET = "pharmacy/billing/"
    static PHARMA_RETURN_BILL = "pharmacy/bill/return/new/"
    static PHARMA_SAVED_BILL_DELETE = "v2/pharmacy/partially/saved/bill/"
    static PHARMA_PRESCRIPTION_GET = "pharmacy/prescription/"
    static PHARMA_PRESCRIPTION_HISTORY = "v2/pharmacy/invoice/history/"
    static PHARMA_REF_DOCTOR = "pharmacy/referred/doctor/bill/"
    static PHARMA_PATIENT_LIST = "pharmacy/patient/report/"
    static PHARMA_ADD_DOCTOR = "pharmacy/referred/doctor/"
    static PHARMA_HEADER_SEARCH = "pharmacy/search/patient/"
    static PHARMA_GST_SALES = "pharmacy/gst/report/new/"
    static PHARMA_PURCHASE_GST = "pharmacy/purchase/gst/report/new/"

    // Sub Location URLs
    static SUBLOC_PATIENT_SEARCH = "sublocation/search/"
    static SUBLOC_DRUG_SEARCH = "sublocation/drug/search/"
    static SUBLOC_BATCH_DETAILS_SEARCH = "sublocation/drug/details/"
    static SUBLOC_BILLING_POST = "sublocation/billing/"
    static SUBLOC_GENERIC_CONSUMP_POST = "sublocation/consumption/"
    static PHARMA_SUB_LOCATION = "pharmacy/sublocation/view/"
    static PHARMA_USER_ACCESS_SUB_LOC = "sublocation/active/list"
    static PHARMA_USER_ACCESS_POST = "sublocation/user/access/"
    static PHARMA_USER_ACCESS_GET = "sublocation/user/access/list/"

    static SUBLOC_FROM_LOCATION_GET = 'sublocation/module-list/'
    static SUBLOC_REQUEST_DRUG_LIST_GET = 'sublocation/drug-list/'
    static SUBLOC_STOCK_TRANSFER = 'sublocation/stock-transfer/'
    static SUBLOC_STOCK_TFR_WANTED_LIST_GET = 'sublocation/wanted-list/'
    static SUBLOC_SAVED_BILLS_GET = 'sublocation/billing/list/'
    static SUBLOC_STOCK_LIST_REPORT_GET = 'sublocation/stock/list/view/'
    static SUBLOC_STOCK_EXPIRY_REPORT_GET = 'sublocation/stock/expiry/view/'
    static SUBLOC_STOCK_RETUEN_REQUEST_GET = 'sublocation/stock-return/'
    static SUBLOC_STOCK_LIST_HISTORY_GET = 'sublocation/stock/correction/history/'
    static SUBLOC_CONSUMPTION_REPORT_GET = 'sublocation/consumption/'
    static SUBLOC_OUTBOUND = 'sublocation/stock-outbound/'
    static SUBLOC_LINE_OF_ITEM = 'sublocation/inbound/stock-line/'
    static SUBLOC_CREDIT_REPORT_GET = 'sublocation/credit/report/'
    static SUBLOC_CREDIT_INDIVIDUAL_REPORT_GET = 'sublocation/credit/report/list/'
    static SUBLOC_STATUS_CHANGE = 'sublocation/request/status/'
    static SUBLOC_TRANSIT_DETAILS = 'sublocation/transit/details/'
    //Lab New Configuration --- API

    static NEW_LAB_AMOUNT_CONFIGURATION_API = 'lab/test-config/'
    static NEW_LAB_RESULT_CONFIGURATION = 'lab/result-config/'
    static NEW_LAB_PACKAGE_CONFIGURATION = 'lab/package/'
    static LAB_TEMPLATE_CONFIGURATION = 'lab/template/'
    static LAB_DEPT_LIST = 'lab/dept/'
    static LAB_ADD_NEW_TEST = 'lab/test/create/'
    static LAB_GRP_EDIT_DELETE = 'lab/group/edit/'

    //Counsellor & OT API
    static GET_COUNSELLOR_APPOINTMENTS = "counsellor/appointments/list/"
    static HOME_GET_CLINIC_DOCTORS = "doctor/clinic/doctors/"
    static COUNSELLOR_RETRANSMIT_POST = "counsellor/retransmit/"
    static GET_OT_APPOINTMENT_LIST = "doctor/ot/appointment/"
    static UPDATE_OT_APPOINTMENT_LIST = "ot/appointment/status/"
    static DOC_OT_REQUEST_GET = 'doctor/ot/appointment/request/'
    static OT_SURGERY_REPORT = 'ot/appointment/report/'
    static OT_BILLING_DRUG_SUGESSION = "ot/drug/bill-suggestion/"
    static OT_BILLING_IOL_DRUG_SUGESSION = "ot/iol/bill-suggestion/"
    static OT_BILLING_POST = "ot/billing/"
    static OT_ROOM_CONFIG = "ot/slot/config/"
    static GET_STOCK_USAGE_LIST = "ot/indent/drugmaster-namelist/"
    static IOL_DRUG_SUGGEST = "ot/iolstockmaster/name/"
    static GET_STOCK_USAGE_BRAND_DETAILS = "ot/indent/drugmaster-details"
    static IOL_MODEL_SUGGEST = "ot/iolstockmaster/details/"
    static GET_STOCK_USAGE_TRANSACTION = "ot/otstockusage/transaction/"
    static STOCK_IOL_USAGE_TRANSACTION = "ot/iol/stockusage/transaction/"
    static STOCK_DRUG_PUT = "ot/otstockusage/"
    static STOCK_DRUG_PUT_IOL = "ot/iol/product_details_search/quantity/"
    static OT_DRUG_LIST_GET = "ot/drug/listing/"
    static OT_IOL_STOCK_LIST = "ot/add-new/iol/"
    static OT_INVENTORY_IOL_TYPE_SUGGESTION = "ot/iol/type-suggestion/"
    static OT_INVENTORY_IOL_NEW_IOL = "ot/iol/product/"
    static GET_HISTORY_LIST = "ot/appointment/history/"
    static GET_PRINT_PREVIEW = "ot/billing/tranaction/list/"
    static GET_OT_CancelledReport = "ot/appointment/report/"
    static OT_IP_REQUESTLIST = "ot/ip-request/list/"
    //

    static FO_TELE_APPOINTMENT_REQUEST = "fo/appointment/request/"


    static IP_NEW_SERVICE_CATEGORY_GET = 'ip/service/category/'
    static FO_NEW_OP_SERVICE_CONFIG = 'fo/service/config/new/'
    static IP_NEW_SERVICE_CONFIGURATION = 'ip/service/configuration/'

    static FO_NEW_OP_SERVICE_CONFIG_CATEGORY_GET = 'fo/service/category/list/new/'
    static FO_NEW_OP_SERVICE_CONFIG = 'fo/service/config/new/'
    static IP_SURGERY_NAME_CONFIG = 'ip/surgery/name/'

    static IP_BED_AVAILABLE = "ip/bed/available/"

    static IP_DIS_SUM_CATAGORY = "ip/discharge/summary/category/config/"
    static PO_RETURN = 'pharmacy/po/return/'
    static PHRAMCY_GROUP_FILTER = 'pharmacy/group/name/'

    static CREDIT_TYPE_CHANGE = "fo/credit/type/update/"
    //Chart Dashboard URLs
    static CHART_OVERALL_REVENUE = 'fo/dash/overall/revenue/'
    static CHART_OVERALL_PAYMENTS = 'fo/dash/overall/payment/collection/'
    static CHART_OVERALL_CREDIT_AMOUNT = 'fo/dash/credit/amount/'
    static CHART_OVERALL_APPOINTMENT_TYPE_COUNT = 'fo/dash/appointment/type/count/'
    static CHART_OP_SPECIALZATION = 'fo/dash/op/top/specialization/'
    static CHART_OP_TOP_DOCTORS = 'fo/dash/op/top/doctors/'
    static CHART_OP_SERVICES = 'fo/dash/op/service/performance/'
    static CHART_OP_LOCATION_ANALYSIS = 'fo/dash/op/location/analysis/'
    static CHART_IP_SPECIALZATION = 'fo/dash/ip/top/specialization/'
    static CHART_IP_TOP_DOCTORS = 'fo/dash/ip/top/doctors/'
    static CHART_IP_SERVICES = 'fo/dash/ip/service/performance/'
    static CHART_IP_LOCATION_ANALYSIS = 'fo/dash/ip/location/analysis/'

    // Pharmacy Chart Dashboard URLs
    static CHART_PHARMA_VENDOR = 'fo/dash/pharmacy/purchase/value/'
    static CHART_PHARMA_DEMOGRAPHICS = 'fo/dash/pharmacy/demographic/'
    static CHART_PHARMA_TOP_SELLING = 'fo/dash/pharmacy/top/selling/drugs/'
    static CHART_PHARMA_SALE_PURCHASE = 'fo/dash/pharmacy/sales/purchase/report/'
    static CHART_PHARMA_NOMOVEMENT = 'fo/dash/pharmacy/nomovementdrug'
    static CHART_PHARMA_STOCKEXPIRY = 'fo/dash/pharmacy/expirydrug/'
    static CHART_PHARMA_LOWSTOCK = 'fo/dash/pharmacy/low/stock/drug'
    static CHART_PHARMA_TOTALDRUG = 'fo/dash/pharmacy/total/drug/'

    // Laboratory Chart Dashboard URLs
    static CHART_LAB_ORDEREDTEST = 'fo/dash/lab/ordered/test/'
    static CHART_LAB_COMPLETEDTEST = 'fo/dash/lab/total/test/'
    static CHART_LAB_DEMOGRAPHICS = 'fo/dash/lab/patient/demographic'
    static CHART_LAB_MOST_PERFORMING = 'fo/dash/lab/most/least/performing/test/'
    static CHART_LAB_SALE_PURCHASE = 'fo/dash/lab/sales/purchase/report/'
    static CHART_LAB_LOCATION = 'fo/dash/lab/location/analysis/'

    // Radiology Chart Dashboard URLs
    static CHART_RAD_DEMOGRAPHICS = 'fo/dash/radiology/patient/demographic/'
    static CHART_RAD_TESTS = 'fo/dash/radiology/most/least/performing/test/'
    static CHART_RAD_LOCATION = 'fo/dash/radiology/location/analysis/'

    // Forgot password
    static SEND_OTP = 'auth/sendotp/'
    static VERIFY_OTP = 'auth/otp/verify/'
    static RESEND_OTP = 'auth/otp/resend/'
    static FORGOT_PASSWORD = 'auth/forget/password/ '
    static PHARMA_NO_AND_SLOW_MOVEMENT_REPORT = "pharmacy/no-slow/movements/new/"
    //Payment Mode Update
    static PAYMENT_MODE_UPDATE = 'fo/receipt/payment/type/update/'
    static LAB_PATIENT_GET = 'lab/home/patient/detail/'
    static SPECIMEN_BARCODE_PRINT = 'lab/specimen/collection/barcode/'
    static RESET_PASSWORD = 'auth/reset/password/'


    //DOCTOR MODULE API STARTS =========>
    static DOC_PATIENT_DETAIL_GET = 'doctorv4/patient/detail/'
    static DOC_PATIENT_DOCUMENT_GET = 'doctorv4/patient/doc-upload/'
    static DOC_MEDICAL_HISTORY = 'doctorv4/patient/medical/history/'
    static DOC_HOSPITALIZATION_HISTORY = 'doctorv4/patient/hospital/history/'
    static DOC_HOSPITALIZATION_HISTORY_DELETE = 'doctorv4/delete/hospitalization/history/'
    static DOC_SOCIAL_HISTORY = 'doctorv4/patient/social/history/'
    static DOC_FAMILY_HISTORY = 'doctorv4/patient/family/history/'
    static DOC_FAMILY_HISTORY_DELETE = 'doctorv4/delete/family/history/'
    static DOC_APPOINTMENT_LIST = 'doctorv4/appointment/list/'
    static DOC_OPTHOMETRY_APPOINTMENT_LIST = 'doctor/opt/optometrist/appointments/list/'
    static DOC_CLINIC_LIST_GET = 'doctorv4/clinic/'
    static DOC_OPTHOMETRY_CLINIC_LIST_GET = 'doctor/opt/optometrist/doctors/list/'
    static DOC_PATIENT_SEARCH_GET = 'api/search/'
    static DOC_APPOINTMENT_STATUS = 'doctorv4/appointment/status/'
    static DOC_OPTHOMETRY_APPOINTMENT_STATUS = 'doctor/appointment/status/'
    static DOC_APPOINTMENT_STATUS_UPDATE = 'doctorv4/appointment/status/update/'
    static DOC_DILATION_TIMER_UPDATE = 'doctor/opt/appointment/dilation/'
    static DOC_PATIENT_CREATION = 'doctorv4/patient/create/'
    static DOC_REFERRAL_SOURCE = 'doctorv4/referral/source/'
    static DOC_REFERRAL_DOCTOR = 'fo/referral/doctor/'
    static DOC_PATIENT_APPOINTMENT = 'doctorv4/appointment/create/'
    static DOC_DIABETIC_HISTORY = 'doctorv4/diabetic/history/'
    static DOC_NUTRITION_HISTORY = 'doctorv4/diabetic/nutrition/history/'
    static DOC_PHYSICAL_HISTORY_GET = 'doctorv4/diabetic/physicalactivity/'
    static DOC_PHYSICAL_HISTORY_POST = 'doctorv4/diabetic/physicalactivity/create/update/'
    static DOC_BLOOD_GLUCOSE_HISTORY_POST = 'doctorv4/diabetic/bg/monitoring/create/update/'
    static DOC_BLOOD_GLUCOSE_HISTORY_GET = 'doctorv4/diabetic/bg/monitoring/'
    static DOC_BLOOD_MENSURAL_HISTORY_GET = 'doctorv4/diabetic/menstrual-history/'
    static DOC_DOCTOR_VISIT_REPORT = 'doctorv4/visit/report/'
    static DOC_DOCTOR_CLINIC_LIST_GET = 'doctorv4/clinic/doctors/'
    static DOC_DOCTOR_TEST_REPORT = 'doctorv4/test/report/' // same for collaborative
    static DOC_DOCTOR_TEST_REPORT_POST = 'doctorv4/patient/test-reports/upload/'
    static DOC_PRINT_ORDER_REPORT = 'doctorv4/test/order/'
    static DOC_NUTRITION_CHART = 'doctorv4/meal/plan/'
    static DOC_CHART_HBA1C = 'doctorv4/diabetic/hba1c/chart/'
    static DOC_GLUCOUSE_LOG = 'doctorv4/diabetic/glucose-log/'
    static DOC_BIOTHESOMETRY = 'doctorv4/diabetic/foot_clinic/biothesiometry/'
    static DOC_BRANCHICAL_INDEX_LOG = 'doctorv4/diabetic/foot_clinic/brachial_index/'
    static DOC_COLLAB_HISTORY_REPORT = 'doctorv4/history/report/'
    static DOC_COLLAB_DOCTOR_NOTES_REPORT = 'doctorv4/doctor-notes/report/'
    static DOC_COLLAB_DIS_SUMMARY_REPORT = 'doctorv4/discharge-summary/report/'
    static DOC_COLLAB_SURGERY_NOTES_REPORT = 'doctorv4/surgery-notes/report/'
    static DOC_SPECIALIZATION_LIST = 'doctorv4/business/specialisation-list/'
    static DOC_REGISTRY_DIAGONIS = 'doctorv4/registry/'
    static DOC_DOCTOR_NOTES_SYMPTOMS = 'doctorv4/symptoms/'
    static DOC_DOCTOR_NOTES_ALLERGIES = 'doctorv4/allergy/'
    static DOC_DOCTOR_NOTES_EXAMINATION_GET = 'doctorv4/patient/examination/' //
    static DOC_DOCTOR_NOTES_OLD_PRESCRIPTION = 'doctorv4/old/prescription/' //?patient_id=1271070&appointment_id=1066846
    static DOC_DOCTOR_NOTES_PRESCRIPTION = 'doctorv4/patient/prescription/'
    static DOC_DOCTOR_NOTES_PHARMACY_LIST_GET = 'doctorv4/pharmacy/list/' //?clinic_id=10&suggest=true
    static DOC_DOCTOR_NOTES_PREVIOUS_PRESCRIPTION_APPOINTMENT_DATE_GET = 'doctorv4/patient/prescription/appointment/date/' //'
    static DOC_DOCTOR_NOTES_TEST_GET = 'doctorv4/tests/'
    static DOC_DOCTOR_NOTES_LAB_LIST = 'lab/clinic/linked/model/' //?clinic_id=10'
    static DOC_DOCTOR_NOTES_PLAN_GET = 'doctorv4/procedure/'
    static DOC_DOCTOR_NOTES_ASSESSEMENT = 'doctorv4/assessment/' //DIagonis
    static DOC_DOCTOR_NOTES_SYMPTOMNS_FREQ = 'doctorv4/frequent/symptoms/'
    static DOC_DOCTOR_NOTES_FAV_PRESCRIPTION = 'doctorv4/favorite/prescription/'
    static DOC_DOCTOR_NOTES_PRESCRIPTION_TEMPLATE = 'doctorv4/prescription/doctor/template/'
    static DOC_DOCTOR_NOTES_PREVIOUS_PRESCRIPTION_APPOINTMENT = 'doctorv4/patient/prescription/appointment/' //
    static DOC_DOCTOR_NOTES_VITALS = 'doctorv4/patient/vitals/'
    static DOC_DOCTOR_NOTES_SURGERY_DETAILS = 'doctorv4/surgery/detail/' //?patient_id=1271070&appointment_id=1066846&clinic_id=10
    static DOC_DOCTOR_NOTES_PATIENT_CATEGORY_WISE = 'doctorv4/wise/category/'
    static DOC_DOCTOR_NOTES_PATIENT_CATEGORY = 'doctorv4/patient/category/'
    static DOC_DOCTOR_NOTES_ALLERGY_FEQ = 'doctorv4/frequent/allergy/'
    static DOC_DOCTOR_NOTES_SYMPTOM_DELETE = 'doctorv4/symptoms/delete/'
    static DOC_DOCTOR_NOTES_TEST_NAME_GET = 'lab/test/config/'
    static DOC_DOCTOR_NOTES_TEST_GROUP_GET = 'lab/test/labset/'
    static DOC_DOCTOR_NOTES_SPECIAL_TEST_SEARCH = 'doctorv4/tests/special/search/'
    static DOC_DOCTOR_NOTES_TEST_POST = 'doctorv4/create/tests/'
    static DOC_DOCTOR_NOTES_TEST_PRINT = 'lab/test/print/'
    static DOC_DOCTOR_NOTES_TEST_DELETE = 'doctorv4/tests/delete/'
    static DOC_DOCTOR_NOTES_TEST_TRANSMIT = 'doctorv4/lab/transmit/test/'
    static DOC_DOCTOR_NOTES_DIAGONIS_ICD_GET = 'doctorv4/frequent/assessment/'
    static DOC_DOCTOR_NOTES_GENERAL_ADVICE = 'doctorv4/advice/template/'
    static DOC_DOCTOR_NOTES_PLAN_FREQ = 'doctorv4/frequent/procedure/'
    static DOC_DOCTOR_NOTES_PLAN_POST = 'doctorv4/create/plan/'
    static DOC_DOCTOR_NOTES_PLAN_DELETE = 'doctorv4/plans/delete/'
    static DOC_DOCTOR_NOTES_COUNSELLING_SURGERY_SEARCH = 'doctorv4/surgery/search/'
    static DOC_DOCTOR_NOTES_LOCK = 'doctorv4/appointment/'
    static DOC_DOCTOR_NOTES_PLAN_DELETE = 'doctorv4/plans/delete/'
    static DOC_APPOINTMENT_REQUEST = 'doctorv4/appointment/request/'
    static DOC_APPOINTMENT_STATUS = 'doctorv4/appointment/status/'
    static DOC_REGISTRY_SEARCH = 'doctorv4/registry/search/'
    static DOC_REGISTRY_ICD_SEARCH = 'doctorv4/icd/search/'
    static DOC_REGISTRY_GET = 'doctorv4/registry/'
    static DOC_HISTORY_REPORT_PRINT = 'doctorv4/patient/history/print/'
    static DOC_IMMUNIZATION_PRINT = 'doctorv4/paediatric/immunization/'
    static DOC_DOCTOR_NOTES_PRINT = 'doctorv4/print/visit-report/'
    static DOC_DOCTOR_NOTES_PRINT_OPT = 'doctor/opt/visit/report/'
    static DOC_DOCTOR_NOTES_PRESCRIPTION_FEQ = 'doctorv4/frequent/prescription/'
    static DOC_DOCTOR_NOTES_DRUG_SEARCH = 'pharmacy/drugs/auto-complete/'
    static DOC_DOCTOR_NOTES_GENERIC_NAME_GET = 'fo/drugandgeneric-name/search/'
    static DOC_DOCTOR_NOTES_PRESCRIPTION_PRINT = 'doctorv4/print/prescription/'
    static DOC_DOCTOR_NOTES_PRESCRIPTION_FAV = 'doctorv4/favorite/prescription/'
    static DOC_DOCTOR_NOTES_PRESCRIPTION_TRANSMIT = 'doctorv4/prescription/transmit/'
    static DOC_SCHEDULE_GET = "doctorv4/schedule/time"
    static DOC_TRANSMIT_POST = "doctorv4/opt/transmit/"
    static DOC_NUTRITION_CHART_PRINT = 'doctorv4/meal/plan/'
    static DOC_DOCTOR_NOTES_EXAM_GROUP_GET = 'doctorv4/examination/group/'
    static DOC_DOCTOR_NOTES_DEFAULT_EXAM_SYMPTOM = 'doctorv4/default/examination/'
    static DOC_DOCTOR_NOTES_ADD_NEW_EXAM_SYMPTOM = 'doctorv4/new/examination/'
    static DOC_DOCTOR_NOTES_EXAM_TEMPLATE_LIST = 'doctorv4/favorite/examination/'
    static DOC_DOCTOR_NOTES_EXAM_POST = 'doctorv4/create/update/examination/'
    static DOC_DOCTOR_NOTES_EXAM_DELETE = 'doctorv4/delete/examination/'
    static DOC_DOCTOR_NOTES_LAYOUT = 'doctorv4/notes/layout/'
    static DOC_DOCTOR_NOTES_OPTHOMETRY_NOTES = 'doctor/optometrist-notes/'
    static DOC_PREVIOUS_GLASS_POWER = 'doctor/opt/pgp/'
    static DOC_SUBJECTIVEREFRACTION = 'doctor/opt/subjectiverefraction/'
    static DOC_RETINOSCOPICREFRACTION = 'doctor/opt/additional/subjectiverefraction/'
    static DOC_DOCTOR_NOTES_COMPLAINTS_GET = 'doctor/opt/list/complaints/'
    static DOC_COMPLAINTS_SUGGESTION_GET = 'doctor/opt/complaint/suggestions/'
    static DOC_DOCTOR_NOTES_COMPLAINTS_POST = 'doctor/opt/complaints/'
    static DOC_DOCTOR_NOTES_COMPLAINTS_DELETE = 'doctor/opt/delete/complaint/'
    static DOC_FREQUENT_EYE_TEST_GET = 'imglab/test/testconfig/'
    static DOC_SPECIAL_EYE_TEST_GET = 'doctor/opt/investigation/new/'
    static DOC_DOCTOR_NOTES_POSTERIOR_SEGMENT = "doctor/opt/posterior/segment/"
    static DOC_INTRAOCULARPRESSURE = "doctor/opt/iop/"
    static DOC_TREATMENT_ADVICE = "doctor/opt/treatmentadvice/"
    static DOC_TREAMENT_ADVICE_TEMPLATE = "doctor/opt/treatmentadvicetemplate/"
    static DOC_TREAMENT_ADVICE_DELETE = "doctor/opt/delete/treatment/advice/"
    static DOC_DOCTOR_NOTES_GONIOSCOPY = "doctor/opt/gonioscopy/list/"
    static DOC_AUTO_REFRACTOMETRY = "doctor/opt/autorefractometry/"
    static DOC_KERATOMETRY = "doctor/opt/keratomentry/"
    static DOC_KERATOMETRY_SUGGESTION = "doctor/keratometry/suggestion/"
    static DOC_OCULAR_RESPONSER = "doctor/opt/ocular-response/"
    static DOC_OTHER_INVESTIGATION = "doctor/opt/other-investigation/"
    static DOC_OTHER_INVESTIGATION_SUGGESTION = "doctor/opt/other-investigation/suggestion/"
    static DOC_PREVIOUS_INVESTIGATION = "doctor/opt/previous/other-investigation/"
    static DOC_CALL_PATIENT = "doctorv4/call-patient/"
    static DOC_WENT_FOR_EMERGENCY = "doctorv4/emergency/update/"
    static DOC_OCCUPATION_GET = "doctor/opt/subref/occupation-list/"
    static DOC_OPT_TAT_GET = "doctorv4/tat-report/"

    static DOC_LAB_HISTORY_TEST_REPORT_DB = "doctorv4/lab/test-history/"
    static DOC_DOCTOR_NOTES_RADSRV_TRANSMIT = 'doctorv4/radiology/transmit/test/'
    static DOC_MEDICAL_CERTFICATE_GET = 'doctor/opt/medicalform/notes/'
    static DOC_FITNESS_CERTFICATE_GET = 'doctor/opt/fitnessform/notes/'

    // IP DOCTOR MODULE API STARTS
    static IP_DOC_WRITE_NOTES = "ip/doctor/notes/"
    static IP_DOC_IPOP_CHART = "ip/input/output/chart/"
    static IP_DOC_NUTRITION_CHART_TEMPLATE = "ip/nutrition/chart/template/"
    static IP_DOC_NUTRITION_CHART = "ip/nutrition/chart/"
    static IP_DOCTOR_NOTES_PRINT = "doctorv4/print/doctornotes-report/"
    static IP_MAPPED_DOCTORS = "ip/mapped/doctors/"
    static IP_DRUG_CHART = "ip/drug/chart/"
    static IP_DRUG_CHART_HISTORY = "ip/drug/chart/history/"
    static DOC_DOCTOR_NOTES_RADIOLOGY_POST = 'doctorv4/radiology/test/create/'
    // IP DOCTOR MODULE API ENDS

    // LAB IP LIST
    static IP_LIST_LABHOME = 'pharmacy/ipadmission/list/'
    static PHARMA_PATIENT_ADMISSION_HISTORY = 'pharmacy/patient/appointment/history/'
    static DOC_OPTHALMOLOGY_FREQUENT_DRUG = "doctor/opt/frequent/drug/"
    static DOC_SURGERY_FREQUENT_DRUG = "doctor/opt/frequent/surgery/"
    static DOC_OCULAR_MEDICATION_GET = "doctor/opt/history/ocular/medication/"
    static DOC_OCULAR_SURGERY_GET = "doctor/opt/surgery/"
    static DOC_OCULAR_MEDICATION_DELETE = "doctor/opt/history/ocular/medication/delete/"
    static DOC_OCULAR_SURGERY_DELETE = "doctor/opt/delete/surgery/"

    static DOC_OPT_FREQUENT_ILLNESS = "doctor/opt/frequent/illness/";
    static DOC_OPT_ILLNESS = "doctor/opt/illness/";
    static DOC_OPT_LIST_ILLNESS = "doctor/opt/list/illness/";
    static DOC_OPT_DELETE_ILLNESS = "doctor/opt/delete/illness/";
    static DOC_OPT_DRUG_ALLERGY = "doctor/opt/drug/allergy/";
    static DOC_OPT_FREQUENT_DRUGALLERGY = "doctor/opt/frequent/drugallergy/";
    static DOC_OPT_DELETE_DRUGALLERGY = "doctor/opt/delete/drugallergy/";

    static DOC_OPT_VISIONASSESSMENT = "doctor/opt/visionassessment/";
    static DOC_OPT_REVIEW_PALN = "doctor/opt/reviewplan/";
    static DOC_REVIEW_PLAN_COMMENTS_TEMPLATE = "doctor/opt/reviewplan/comment/template/";
    static FO_CREDIT_BILLS_GET = 'fo/pending/bills/'

    static DOC_OPT_INVESTIGATION = "imglab/test/order/";
    static DOC_OPT_INVESTIGATION_PRINT = "lab/test/print/";

    static DOC_OPT_GLASS_PRESCRIPTION = "doctor/opt/gp/";

    static DOC_DOCTOR_VIST_REPORT_PRINT = 'doctorv4/print/visit-report/'
    static DOC_OVERALL_REVIEW_NOTES = "doctor/patient-notes/"
    static DOC_TRANSFER_REQUEST = "doctorv4/appointment/transfer-request/"
    static DOC_NOTES_AUTO_LOCK = "doctorv4/appointment/auto-lock/"
    static DOC_UNLOCK_DOCTOR_NOTES = "doctorv4/unlocked/notes/"
    static DOC_GP_PRINT = 'doctor/opt/gp/print/'
    static DOC_OPT_CONFIG = 'doctorv4/configurations/'
    static DOC_OPT_FUNDUS_IMG = 'doctor/opt/fundusimage/upload/'
    static OPTHALMOLOGY_ANTERIOR_SEGMENT_IMAGE = 'doctor/opt/anterior/segment/image/'
    static DOC_OPT_REFERRAL_GET = 'doctor/opt/referral/referral_notes_items/'
    static DOC_OPT_REFERRAL_POST = 'doctor/opt/referral/referral_doctor_notes/'
    static DOC_REFERRAL_DOCTOR_OPT = 'doctor/opt/referral/doctor/'
    static DOC_ANTERIOR_SEGMENT_API = 'doctor/opt/anterior/segment/'
    static DOC_FUNDUS_API = 'doctor/opt/fundus/'
    static DOC_MEDICATION_DLT = 'doctor/patient/prescription/'
    static DOC_FOLLOWUP_GET = 'doctor/opt/reviewplan/followup/'
    static DOC_FOLLOWUP_DLT = 'doctor/opt/delete/reviewplan/'
    static DOC_COLLABRATIVE_LOG = 'doctorv4/collaborative/history/'
    static DOC_MEDIC_TEMPLATE_GET_POST = 'doctor/opt/prescription/template/'
    static DOC_ANTERIOR_PREVIOUS_DIAGRAM = 'doctor/anterior/pre-cpy'
    static DOC_FUNDUS_PREVIOUS_DIAGRAM = 'doctor/fundus/pre-cpy/'
    static DOC_RECALL_NOTES_POST = 'doctorv4/patient/recall/notes/'

    static DOC_DISCHARGE_SUMMARY_GET = 'doctorv4/patient/discharge_summary/'

    //Medication
    static DOC_OPT_FREQUENT_MEDICATION = 'doctor/opt/frequent/medication/'
    static DOC_OPT_MEDICATION_DETAILS = 'doctor/opt/drug/details/search/'
    static DOC_OPT_TAPPER_DETAILS_GET_POST = 'doctor/opt/prescription/'
    static DOC_OPT_MEDICATION_FREQUENCY = 'doctor/opt/frequency/template'
    static DOC_OPT_MEDICATION_HISTORY = 'doctor/medication/history/'
    static DOC_OPT_EXTERNAL_DRUG = 'doctorv4/external/drug-data/'

    // LAB IP LIST
    static IP_LIST_LABHOME = 'pharmacy/ipadmission/list/'

    static IP_OVERALL_DISCOUNT = 'ip/overall/discount/'
    static LAB_PATIENT_TEST_HISTORY = 'lab/patient/test/history/'
    static LAB_SALES_HSN_WISE = 'pharmacy/hsn/wise/report/'
    static PHARMA_VENDOR_BILL = 'pharmacy/vendor-purchase/report'
    static PHARMA_HSN_REPORT = 'pharmacy/reports/purchase/hsn/'
    static PHARMA_TAX_WISE_SALES = 'pharmacy/tax/wise/report/'
    static PHARMA_PURCHASE_RETURN_HSN_REPORTS = 'pharmacy/reports/purchase/return/hsn/'
    static PHARMA_PURCHASE_RETURN_VENDOR_REPORTS = 'pharmacy/vendor/po-return/report/'
    static PHARMA_PRESCRIPTION_REG = 'pharmacy/prescription/register/'

    static GET_BILL_MODIFY_HISTORY = "ip/billsummary/history/log/"
    static PHARMA_PO_REQUEST_PRINT = 'pharmacy/purchase-order/'
    static PHARMA_NEW_VENDOR_PAYMENT = 'pharmacy/vendor/po/'
    static PHARMA_NEW_VENDOR_RECEIPT = 'pharmacy/vendor/receipt/'

    // OPT Previous History API
    static DOC_PGP_PREVIOUS_GLASS_POWER = 'doctor/pgp/history/'
    static DOC_SUBJECTIVEREFRACTION_PREVIOUS = 'doctor/opt/previous/subjectiverefraction/'
    static DOC_RETINO_REFRACTION_PREVIOUS = 'doctor/asr/history/'
    static DOC_AUTO_REFRACTO_PREVIOUS = 'doctor/auto-refractometry/history/'
    static DOC_PREVIOUS_POSTERIOR_SEGMENT = 'doctor/posterior/segment/history/'
    static DOC_PREVIOUS_KERATOMETRY = 'doctor/keratomentry/history/'
    static DOC_KERATOMETRY_CURVATURE = 'doctor/opt/keratomentry/curvature/'
    static DOC_PREVIOUS_GONIOSCOPY = 'doctor/gonioscopy/history/'
    static DOC_PREVIOUS_GLASS_PRESCRIPTION = 'doctor/glass-prescription/history/'
    static DOC_PREVIOUS_VISION = 'doctor/vision-assessment/history/'
    static DOC_PREVIOUS_ANTERIOR_SEGMENT = 'doctor/anterior/segment/history/'
    static DOC_SUGGESTION_ANTERIOR_SEGMENT = 'doctorv4/anterior-segment/suggestion/'

    //Optical CRM
    static OPTICAL_CRM_CUSTOMER_DETAIL = 'optical-crm/customer-detail/'
    static OPTICAL_CRM_PATIENT_DETAIL = 'optical-crm/patient-detail/'
    static OPTICAL_CRM_CUSTOMER_ADD = 'customer_add.php/'
    static OPTICAL_CRM_PRESCRIPTION_POST = 'prescription_add.php/'

    static FO_PRINT_ORDER = 'fo/test/order/'
    // Help Desk
    static HELPDESK = 'fo/help_desk/request/'
    static PHARMACY_DUPLICATE_BILL_DC_CHECK = 'v2/pharmacy/bill/duplicate_check/'
    //Peadiatric
    static PD_DEVELOP_ASSESS = 'doctorv4/new/developmentalassessment/'
    static PD_DEVELOP_ASSESS_POST = 'doctorv4/denverscreening/'
    static PD_IMMUNIZATION_GET = 'doctorv4/paediatric/immunization/'
    static PD_IMMUNIZATION_LOCK = 'doctorv4/paediatric/immunization/freeze/'
    static PD_HISTORY_GET = 'doctorv4/paediatric/birthhistory/'
    static PD_MEDICAL_HIS = 'doctorv4/paediatric/medicalnew/problems/'
    static PD_MEDICAL_HIS_DEL = 'doctorv4/paediatric/medical/problems/delete/'
    static PD_GROWTH_CHART = 'doctorv4/paediatric/growth/chart/'

    // Canteen
    static CANTEEN_GET = "canteen/canteen-item/"
    static CANTEEN_OVERALL = "canteen/overall/"
    static CANTEEN_CANCEL = "canteen/cancel/"
    static CANTEEN_CREDITBILL = "canteen/credit/"
    static CANTEEN_RECEIPTBILL = "canteen/receipt/"
    static CANTEEN_DAYENDBILL = "canteen/dayend/"
    static CANTEEN_BILLING = "canteen/billing/"
    //Asset Management
    static ASSET_LIST_GET_POST = 'asset/assetlist/'
    static ASSET_LIST_BAR_CODE = 'asset/assetbarcode/'
    static ASSET_TRANSFER_GET_POST = 'asset/asset-transfer/'
    static ASSET_AUDIT_GET = 'asset/audit/'
    static ASSET_CATEGORY_GET_POST = 'asset/category/'
    static ASSET_SUB_CATEGORY_GET_POST = 'asset/subcategory/'
    static ASSET_BLOCK_LIST_GET_POST = 'asset/block/'
    static ASSET_FLOOR_LIST_GET_POST = 'asset/floor/'
    static ASSET_SPOT_LIST_GET_POST = 'asset/spot/'
    static ASSET_DESIGN_LIST_GET_POST = 'asset/designation/'
    static ASSET_EMPLOYEE_LIST_GET_POST = 'asset/employee/'
    static ASSET_AUDIT_SEARCH_ASSETNO = 'asset/assetno/'
    static PURCHASE_WISE_ENQUIRY = "pharmacy/product/wise/report"

    static POSITIVE_CENSUS_REPORT = 'lab/census/pos_neg/'

    static INSURANCE_MASTER = 'fo/insurance-list/'

    static LEGER_REPORT_BED_AVAILABLE_GET = 'ip/bed/available/ledger/'
    static PRE_AUTHORIZATION_POST = 'ip/pre-auth/claim/'
    static POST_PATIENT_CREDIT_TYPE = 'ip/patient/credit-type/'
    static IP_OCCUPENCY_REPORT_GET = 'ip/occupancy/report/'
    static DAY_END_MODULE_BASED = 'fo/dayend/bill/report/module/'
    static PHARMACY_SUBLOC_STOCKLIST = 'pharmacy/sub_loc/stock/list/view/'
    static LAB_PACKAGE_REPORT = 'lab/package/report/'

    static FO_IP_OP_PATIENT_TAG_CHANGE_NORMAL = 'ip/patient/tag/update/'

    static PHARMA_PO_CREDIT_NOTE_VENDOR_GET = 'pharmacy/po-return/new/'
    static PHARMA_PO_CREDIT_NOTE_POST = 'pharmacy/credit-note/po-return/'
    static PHARMA_CREDIT_NOTE_REPORT = 'pharmacy/credit-note/po-return/'
    static FO_DAY_END_DETAILED_PRINT = 'fo/dayend/bill/print/'

    // Tally Report
    static TALLY_REPORT = 'reports/masonic/list/'
    
    static SUBLOC_EXPIRY_STOCK = 'sublocation/expiry/'
    static OPT_SUB_REFRACTION_SUGG = 'doctor/subjectiverefraction/suggestion/'
    static OPT_RETINOSCOPIC_ACCEPTANCE = 'doctor/retinoscopicacceptance/'
    static OPT_RETINOSCOPIC_ACCEPTANCE_PREVIOUS = 'doctor/retinoscopicacceptanceprevious/'
    static OPT_RETINOSCOPIC_ACCEPTANCE_SUG = 'doctor/retinoscopicacceptancesuggestion/'

    //branch Module
    static BRANCH_MOD_BRANCHES_GET = 'fo/dashboard/chart/new/'
    static BRANCH_MOD_PHARMACY_SLOWMOVE_GET = 'fo/dash/pharmacy/slow/moment/drug/'
    static BRANCH_MOD_PHARMACY_NEWARRIVAL_GET = 'fo/dash/pharmacy/new/arrival/drug/'
    static BRANCH_MOD_TOTAL_PATIENT_DOC_COUNT = 'fo/dashboard/patient/doctor/count/'
    static BRANCH_MOD_CUSTOM_CONFIG = 'core/org/custom-config/ '

    static CANTEEN_RECEIPT_PRINT = "canteen/receipt/print/"
    
    static ASSET_NEW_BLOCK_FLOOR_GET = 'asset/spot/area/'
    static ASSER_NEW_CAT_SUB_CAT_GET = 'asset/category/subcategory/'
    static ASSET_COMPLAINT_GET_POST = 'asset/assetcomplaints/'
    static ASSET_STATUS_LOG_POST_GET = 'asset/complaint/status/'
    static ASSET_GATE_PASS_PRINT = 'asset/gatepass/'
    static LAB_DAY_END_REPORT_GET = 'lab/dayend/report/'

    // DOCTOR - OBGYN urls
    static OVULATION_INDUCTION_DATA_GET = 'doctorv4/obgyn/ovulationinduction/' 
    static OVULATION_INDUCTION_LIST = 'doctorv4/obgyn/ovulationinduction/list/' 
    static OVULATION_INDUCTION_POST = 'doctorv4/obgyn/ovulation/induction/'
    static OVULATION_INDUCTION_TEMPLATE_LIST = 'doctorv4/obgyn/ovulation/induction/template/values/'
    static OVULATION_INDUCTION_DELETE = 'doctorv4/obgyn/ovulation/induction/delete/'
    static OVULATION_INDUCTION_PRINT = 'doctorv4/obgyn/ovulation/induction/print/'
    static ANTENATAL_OBS_DATA_POST = 'doctorv4/obgyn/antenatalchart/'
    static ANTENATAL_SCAN_LIST = 'doctorv4/obgyn/antenatal/scan/list/'
    static ANTENATAL_INVESTIGATION_CHART = 'doctorv4/obgyn/antenatal/investigation/chart/'
    static ANTENATAL_OBSTETRIC_HISTORY = 'doctorv4/obgyn/antenatal/obstetric/history/'
    static ANTENATAL_PHYSICAL_EXAMINATION = 'doctorv4/obgyn/antenatal/physical/examination/'
    static ANTENATAL_SCAN_OBSERVATION = 'doctorv4/obgyn/antenatal/scan/'
    static ANTENATAL_VACCINATION = 'doctorv4/obgyn/antenatal/vaccination/'
    static ANTENATAL_COMPLETE = 'doctorv4/obgyn/antenatal/complete/'
    static ANTENATAL_CHART_PRINT = 'doctorv4/obgyn/antenatal/print/'

    static MENSTRUAL_HISTORY = 'doctorv4/obgyn/menstrual/history/'
    static OBSTETRICAL_HISTORY_GET = 'doctorv4/obgyn/obstetricalhistory/'
    static OBSTETRICAL_HISTORY_POST_AND_UPDATE = 'doctorv4/obgyn/obstetricalhistory/create/update/'
    static OBSTETRICAL_HISTORY_DELETE = 'doctorv4/obgyn/obstetricalhistory/delete/'
    static BIRTH_CONTROL_GET = 'doctorv4/obgyn/birthcontrol/'
    static BIRTH_CONTROL_POST_AND_UPDATE = 'doctorv4/obgyn/birthcontrol/create/update/'
    static GYNAECOLOGICAL_HISTORY = 'doctorv4/obgyn/gynaecology/history/'
    static PAP_HISTORY = 'doctorv4/obgyn/pap/mammogram/history/'
    static OBGYN_SURGERY_GET_POST = 'doctorv4/obgyn/obstetrical/surgeries/history/'

    static PHARMA_PRESC_LIST_HISTORY_GET = 'pharmacy/prescriptions/list/'

    // RADIOLOGY API STARTS ===> 

    static RAD_SERVICE_CONFIG = 'radiology/service/config/'
    static RAD_TEST_TEMPLATE_CONFIG = 'radiology/service/template/config/'
    static RAD_BILL_POST = 'radiology/create/bill/'
    static RAD_SAVED_BILL_GET = 'radiology/saved/bill/view/'
    static RAD_SAVED_BILL_ITEM_GET = 'radiology/bill/'
    static RAD_BILL_RECEIVED_POST = 'radiology/bill/complete/'
    static RAD_OVER_ALL_BILL_TRANS_GET = 'radiology/overall-bill/'
    static RAD_CANCEL_BILL_TRANS_GET = 'radiology/cancel-bill/'
    static RAD_SAVED_BILL_TRANS_GET = 'radiology/saved-bill/'
    static RAD_RECEIPT_BILL_TRANS_GET = 'radiology/receipt-bill/'
    static RAD_PATIENT_BILLS_GET = 'radiology/bill/view/'
    static RAD_PATIENT_APPOINTMENT_LIST = 'radiology/home/view/'
    static RAD_DAY_END_BILL_TRANS_GET = 'radiology/dayend-bill/'
    static RAD_APPOINTMENT_GET = 'lab/appointments/list/'
    static RAD_CENCUS_REPORT = 'radiology/census/report/'
    static RAD_PATIENT_REPORT = 'radiology/patient/service/report/'
    static RAD_PATIENT_SAVED_BILL_LIST = 'radiology/saved/bill/list/'
    static RAD_RESULT_GET = 'radiology/billing/result/'
    static RAD_CANCEL_BILL = 'radiology/bill/cancel/'
    static RAD_CREDIT_BILL_TRANS_GET = 'radiology/credit-bill/'
    static RAD_RECEIPT_BILL_GET = 'radiology/receipt/bill/list/'
    static RAD_NOTES = 'radiology/patient/notes/'
    static RAD_RESULT_PRINT = 'radiology/bill/service/print/'
    static RAD_PATIENT_TRANSMIT_GET = "radiology/transmitted/test-list/"

    // Account Report 
    static TALLY_ADVANCE_RPT_GET = 'accounts/advance/report/'
    static ACC_REF_CANCEL = 'accounts/cancel/refund/'
    static ACC_BILL_WISE_RPT_GET = 'accounts/billview/task/'
    // Registration Form API's
    static FO_QR_ENTRY_LIST = 'fo/qr-entry/list/'
    static REGISTRATION_FORM_POST = 'fo/qr-appointment/registration/'
    static SMS_SEND = 'core/ebill/sms/'

    static LAB_DOCTOR_SHARE = 'lab/doctor/config/'
    static LAB_GRP_TST_PACK_GET = 'lab/test/grp/package/'

    static LAB_SHARE_REPORT_GET = 'lab/share/report/'
    static OP_SERVICE_CODE_LIST = 'fo/service/code/'
    static IP_SERVICE_CODE_LIST = 'ip/service/code/'
    static RAD_SERVICE_CODE_LIST = 'radiology/service_code_list/'
    static OT_TAT_REPORT_GET = 'ot/report/tat/'
    
    static FO_BADGE_COUNT_GET= 'fo/appointment/request/count/'

    static PHARMACY_STOCK_CONFIG = 'core/pharmacy/stock/configuration/'

    // branch to branch 
    static PHA_BRANCH_TOLOC = 'v2/pharmacy/org/pharmacy/'
    static PHA_BRANCH_DRUGSEARCHLIST = 'v2/pharmacy/global/drug/list/'
    static PHA_BRANCH_DRUGSEARCH = 'v2/pharmacy/global/drug/search/'
    static PHA_BRANCH_REQ_POST = 'v2/pharmacy/stock/transfer/'
    static PHA_BRANCH_LINE_OF_ITEM = 'v2/pharmacy/inbound/line/'
    static PHA_BRANCH_STATUS_CHANGE = 'v2/pharmacy/request/status/'
    static PHA_BRANCH_TRANSIT = 'v2/pharmacy/transit/details/'
    static PHA_BRANCH_STOCK_REPORT = 'v2/pharmacy/stock/transfer/list/'
    static PHA_BRANCH_STOCK_LIST = 'v2/pharmacy/stock/list/'
    static PHA_BRANCH_WANTED_LIST = 'v2/pharmacy/b2b/wanted/list/'
    //QMS
    static QMS_CONFIG = 'fo/qms/config/'
    static FO_DOC_QUEUE_GET = 'fo/drqueue/list/'

    static DOC_SESSION_CONFIG = 'doctorv4/session/config/'
    static DOC_ROOMNO_CONFIG = 'doctorv4/room/detail/'
    static DOC_DETAILS_PROFILE = 'doctorv4/profile/details/'

    static DOC_SCHEDULE_DATA_GET = 'doctorv4/schedule/list-view/'
    static DOC_SCHEDULE_OUTOFOFFICE = 'doctorv4/mark/out-of-office/'
    static DOC_RUNNING_LATE_POST = 'doctorv4/running/late/'
    static DOC_SESSION_LIST = 'doctorv4/session/list/'
    static FO_NOTIFICATION_GET = 'fo/home/notifications/'

    static DOC_EMERGENCY_WEBSOCKET = 'emergency-update/'
    static DOC_RUNNINGLATE_WEBSOCKET = 'running-late/'
    static DOC_OUTOFOFFICE_WEBSOCKET = 'doctor/out-of-office/'
    static DOC_PATIENTCALL_WEBSOCKET = 'patient-call/'
    static FO_CALLPATIENT_WEBSOCKET = 'fo/call-update/'
    static TOKENSCREEN_WEBSOCKET = 'token-screen/'
    
    static FO_INTERNAL_CMT = "fo/appointment/comments"
    static LAB_IP_REQUEST_TRANSMIT = 'ip/lab/transmit/'
    static DOC_NOTES_IOL_MASTER = 'doctor/opt/iol_master/'

    static LAB_TEST_CODE_LIST = 'lab/test_code_list/'
    static DOC_PRESCIPTION_USAGE_LIST = 'doctorv4/prescriptions/usage_list/'
    static PHARMACY_USER_DAYEND_SALES = 'pharmacy/day/end/denomination/'

    //celery concept
    static CELERY_DEMO_START = "reports/get_task/"

    static INDENT_REQ_COUNT = 'pharmacy/indent/batch/'
    
    static LOGIN_RESET_PASS = 'auth/password/change/'
}

