import { Autocomplete, Box, Button, Checkbox, Chip, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Paper, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../../FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/DoctorNotes.css'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../Styles/Colors';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { Days, FoodUsage, Months, TabletQty, Weeks, Year } from '../../../../Utility/Constants';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import DeleteIcon from '@mui/icons-material/Delete';

const Usage_Type = [
  { value: "ES", label: "Empty Stomach" },
  { value: "BF", label: "Before Food" },
  { value: "WF", label: "With Food" },
  { value: "AF", label: "After Food" },
  { value: "EU", label: "External Use" },
]
class DocPrecriptionRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clinicId: localGetItem('SelectedDoctorClinicId'),

      tabletQty: TabletQty,
      foodUsage: FoodUsage,
      appointmentId: this.props.PatientData?.appointment_id,
      patientId: this.props?.PatientData?.patient_id,
      appointmentDate: this.props?.PatientData?.appointment_date,
      isLocked: false,

      pharmacyList: [],
      selectedPharmacy: '',
      selectedDrug: '',
      searchedDrugList: [],
      searchedBrandName: '',
      searchedGenericName: null,
      genericList: [],
      dosageStrength: '',
      dosageType: '',
      morningQty: '0',
      afternoonQty: '0',
      eveningQty: '0',
      nightQty: '0',
      selectedUsage: '',
      selectedDays: '1',
      selectedDuration: '',
      durationDays: Days,
      durationWeeks: Weeks,
      durationMonts: Months,
      durationYears: Year,
      prescriptionList: [],
      comments: '',
      favourite: false,
      selectedDrugId: null,
      prescriptionId: null,
      removeData: false,
      selectedId: null,
      isEdit: false,
      selectedIndex: null,
      ExternalDrugAdd: false,

      EditIndex: null,
      AddClicked: false,
      FrequencyText: "",
      DrugSuggestionList: [],
      AllowEdit: false,
      ExternalDrugName: "",
      ExternalGenericName: "",
      ExternalDosageStrength: "",
      ExternalDosageType: "",
      FreqDrugList: [],
      checkMl: false,
      ExternalDrugList: [],
      ExternalBrandName: null,
      drugSearchFor: 1,
      prescUsage: null,
      prescriptionUsage: [],
      openAddNew: false,
      usagseId:null,
      prescripTblData: [],
      checkOthers:false
    }
  }

  componentDidMount() {
    let drugSearch = localGetItem('drug_search') !== 'undefined' ? JSON.parse(localGetItem('drug_search')) : 1
    this.setState({ drugSearchFor: drugSearch })
    if (this.props.EditPrescriptionData && this.props.EditPrescriptionData && Object.keys(this.props.EditPrescriptionData).length !== 0) {
      let item = this.props.EditPrescriptionData
      this.setState({
        afternoonQty: typeof (item?.afternoon) === 'string' ? item?.afternoon : (item?.afternoon).toString(),
        selectedDrug: item?.brand_name,
        comments: item?.comments,
        dosageStrength: item?.dosage_strength,
        dosageType: item?.dosage_type,
        selectedDuration: item?.duration,
        selectedDays: item?.duration_mode,
        eveningQty: typeof (item?.evening) === 'string' ? item?.evening : (item?.evening).toString(),
        nightQty: typeof (item?.night) === 'string' ? item?.night : (item?.night).toString(),
        morningQty: typeof (item?.morning) === 'string' ? item?.morning : (item?.morning).toString(),
        favourite: JSON.parse(JSON.stringify(item?.is_favorite)),
        searchedGenericName: item?.generic_name,
        selectedUsage: item?.instructions,
        selectedDrugId: item?.drug_id,
        selectedId: item?.id ? item?.id : null,
        isEdit: true,
        checkMl: item?.is_unit_ml ? item?.is_unit_ml : false,
        checkOthers: item?.is_unit_others ? item?.is_unit_others : false
      }, () => {
        if (!item?.drug_id) {
          this.setState({
            ExternalDrugAdd: true,
            ExternalDrugName: item?.brand_name,
            ExternalBrandName: item?.brand_name,
            ExternalGenericName: item?.generic_name,
            ExternalDosageType: item?.dosage_type,
            ExternalDosageStrength: item?.dosage_strength,
            selectedDrug: "",
            dosageStrength: "",
            dosageType: '',
            searchedGenericName: "",
          })
        }
        this.props.ClearPropsData()
      })
    }
    if (this.props.prescripTblData?.prescription_lines?.length > 0) {
      const dataList = this.props.prescripTblData?.prescription_lines?.length > 0
        ? [...this.props.prescripTblData.prescription_lines]
        : [...this.props.prescripTblData];
      this.setState({
        prescripTblData: dataList?.length > 0 ? dataList : [],
        selectedPharmacy: this.state.selectedPharmacy !== dataList[0]?.pharmacy_id ?
          dataList[0]?.pharmacy_id : this.state.selectedPharmacy
      })
    }
    this.getPharmacyList()
    this.getPresctiptionUsageList()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.EditPrescriptionData !== this.props.EditPrescriptionData && this.props.EditPrescriptionData && Object.keys(this.props.EditPrescriptionData).length !== 0) {
      let item = this.props.EditPrescriptionData
      this.setState({
        afternoonQty: typeof (item?.afternoon) === 'string' ? item?.afternoon : (item?.afternoon).toString(),
        selectedDrug: item?.brand_name,
        comments: item?.comments,
        dosageStrength: item?.dosage_strength,
        dosageType: item?.dosage_type,
        selectedDuration: item?.duration,
        selectedDays: item?.duration_mode,
        eveningQty: typeof (item?.evening) === 'string' ? item?.evening : (item?.evening).toString(),
        nightQty: typeof (item?.night) === 'string' ? item?.night : (item?.night).toString(),
        morningQty: typeof (item?.morning) === 'string' ? item?.morning : (item?.morning).toString(),
        favourite: JSON.parse(JSON.stringify(item?.is_favorite)),
        searchedGenericName: item?.generic_name,
        selectedUsage: item?.instructions,
        selectedDrugId: item?.drug_id,
        selectedId: item?.id ? item?.id : null,
        isEdit: true,
        checkMl: item?.is_unit_ml ? item?.is_unit_ml : false,
        checkOthers: item?.is_unit_others ? item?.is_unit_others : false
      }, () => {
        if (!item?.drug_id) {
          this.setState({
            ExternalDrugAdd: true,
            ExternalDrugName: item?.brand_name,
            ExternalBrandName: item?.brand_name,
            ExternalGenericName: item?.generic_name,
            ExternalDosageType: item?.dosage_type,
            ExternalDosageStrength: item?.dosage_strength,
            selectedDrug: "",
            dosageStrength: "",
            dosageType: '',
            searchedGenericName: "",
          })
        }
        this.props.ClearPropsData()
      })
    }

    const currentPrescripTblData = JSON.parse(JSON.stringify(this.props.prescripTblData));
    const previousPrescripTblData = JSON.parse(JSON.stringify(prevProps.prescripTblData));
    if (
      (previousPrescripTblData !== currentPrescripTblData) &&
      (currentPrescripTblData !== this.state.prescripTblData)
    ) {
      const dataList = Array.isArray(currentPrescripTblData) && currentPrescripTblData?.length > 0
        ? [...currentPrescripTblData]
        : [];

      if (JSON.stringify(this.state.prescripTblData) !== JSON.stringify(dataList)) {
        this.setState({
          prescripTblData: dataList,
          selectedPharmacy: dataList?.length > 0 ? ((this.state.selectedPharmacy !== dataList[0]?.pharmacy_id && dataList[0]?.pharmacy_id ) ?
            dataList[0]?.pharmacy_id : (this.state.selectedPharmacy ? this.state.selectedPharmacy : this.state.pharmacyList[0]?.pharmacy_id )) : this.state.pharmacyList[0]?.pharmacy_id
        }, () => {
          this.getFreqDrugList()
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.ClearClickedonRightPrecs(true)
  }

  getPharmacyList = () => {
    try {
      // RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PHARMACY_LIST_GET + `?clinic_id=${this.state.clinicId}&suggest=true`)
      //   .then((response) => {
      //     if (response?.data?.status === 'success') {
      //       this.setState({
      //         pharmacyList: response.data?.data,
      //         selectedPharmacy: response?.data?.data[0]?.pharmacy_id
      //       }, () => {
      //         this.getFreqDrugList()
      //       })
      //     }
      //   }).catch((error) => {
      //     if (error?.response?.data?.message) {
      //       this.errorMessage(error.response.data.message)
      //     } else {
      //       this.errorMessage(error.message)
      //     }
      //   })
      let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null;
      let pharmaList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)) : [];
      const dataList = this.state.prescripTblData?.prescription_lines?.length > 0
        ? [...this.state.prescripTblData.prescription_lines]
        : [...this.state.prescripTblData];
      if (pharmaList?.length) {
        this.setState((prevState) => ({
          pharmacyList: pharmaList,
          selectedPharmacy: (dataList?.length > 0 && prevState?.selectedPharmacy !== dataList[0]?.pharmacy_id && dataList[0]?.pharmacy_id) ?
            dataList[0]?.pharmacy_id : (pharmaList[0]?.pharmacy_id || '')
        }), () => {
          this.getFreqDrugList()
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getFreqDrugList = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_FEQ + `?pharmacy_id=${this.state.selectedPharmacy}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              FreqDrugList: response.data?.data,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPresctiptionUsageList = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PRESCIPTION_USAGE_LIST)
        .then((response) => {
          if (response.data.status === 'success') {
            let data = response.data.data; let usageData = [];
            Usage_Type?.forEach(element => usageData?.unshift(element))
            data?.forEach(element => {
              usageData?.push({
                label: element?.instructions,
                value: element?.instructions,
                is_added: true,
                id: element?.id
              })
            })
            this.setState({ prescriptionUsage: usageData },() => {
              this.setState({ usagseId: this.state.prescriptionUsage?.find(item => item.label === this.state.selectedUsage)?.id })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postPresctiptionUsageList = () => {
    try {
      let data = {
        "instructions": this.state.prescUsage
      }
      if (this.state.prescUsage === null || this.state.prescUsage === '') {
        this.setState({ postUsageLoad: false })
        this.errorMessage('Please Enter Usage')
      } else {
        RestAPIService.create(data, Serviceurls.DOC_PRESCIPTION_USAGE_LIST)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.setState({selectedUsage:this.state.prescUsage },() => {
                this.setState({ openAddNew: false, postUsageLoad: false, prescUsage: null})
                this.getPresctiptionUsageList()
              })
            }
          }).catch((error) => {
            this.setState({ postUsageLoad: false })
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.setState({ postUsageLoad: false })
      this.errorMessage(error.message)
    }
  }

  deletePresctiptionUsageList = (item) => {
    try {
      let data = {}
      RestAPIService.delete(Serviceurls.DOC_PRESCIPTION_USAGE_LIST + '?id=' + item?.id, data)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({ selectedUsage: item?.id === this.state.usagseId ? '' : this.state.selectedUsage })
            this.getPresctiptionUsageList()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ClearData(FromClearBtn = false) {
    this.setState({
      selectedDrug: '',
      searchedDrugList: [],
      searchedBrandName: '',
      searchedGenericName: "",
      genericList: [],
      dosageStrength: '',
      dosageType: '',
      morningQty: '0',
      afternoonQty: '0',
      eveningQty: '0',
      nightQty: '0',
      selectedUsage: '',
      selectedDays: '1',
      selectedDuration: 'Days',
      durationDays: Days,
      durationWeeks: Weeks,
      durationMonts: Months,
      durationYears: Year,
      prescriptionList: [],
      comments: '',
      favourite: false,
      selectedDrugId: null,
      removeData: false,
      selectedId: null,
      isEdit: false,
      selectedIndex: null,
      ExternalDrugAdd: false,
      EditIndex: null,
      AddClicked: false,
      FrequencyText: "",
      DrugSuggestionList: [],
      AllowEdit: false,
      ExternalDrugName: "",
      ExternalGenericName: "",
      ExternalDosageStrength: "",
      ExternalDosageType: "",
      checkMl: false,
      ExternalDrugList: [],
      ExternalBrandName: null,
      checkOthers:false
    }, () => {
      if (FromClearBtn) {
        this.props.ClearClickedonRightPrecs(true)
      }
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message,
      isDeleteClicked: false,
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  getGenericNameList = (fromFreq = false) => {
    try {
      let params = ''
      if(fromFreq){
          params = `?brand_name=${encodeURIComponent(this.state.selectedDrug)}&generic_name=&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
      }else{
        if(+this.state.drugSearchFor === 1){
          params = `?brand_name=${encodeURIComponent(this.state.selectedDrug)}&generic_name=&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
        }else{
          params = `?brand_name=${encodeURIComponent(this.state.selectedDrug)}&generic_name=${encodeURIComponent(this.state.searchedGenericName)}&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
        }
      }
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_GENERIC_NAME_GET + params)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              genericList: response.data.data,
              searchedGenericName: response?.data?.data?.length > 0 ? response?.data?.data[0] : '',
            }, () => {
              let val = this.state.searchedGenericName?.latest_prescription ? this.state.searchedGenericName?.latest_prescription : {}
              if (Object.keys(val)?.length > 0) {
                this.setState({
                  checkMl: val?.is_unit_ml,
                  morningQty: val?.morning,
                  afternoonQty: val?.afternoon,
                  eveningQty: val?.evening,
                  nightQty: val?.night,
                  selectedDays: val?.duration_mode,
                  selectedDuration: val?.duration,
                  selectedUsage: val?.instructions,
                  comments: val?.comments,
                  checkOthers: val?.is_unit_others
                })
              }
              this.setState({
                dosageStrength: this.state.searchedGenericName?.dosage_strength,
                dosageType: this.state.searchedGenericName?.dosage_type
              }, () => {
                let val = this.state.searchedGenericName?.latest_prescription ? this.state.searchedGenericName?.latest_prescription : {}
                if (Object.keys(val)?.length === 0) {
                  if ((this.state.searchedGenericName?.dosage_type === 'Syrup') || (this.state.searchedGenericName?.dosage_type === 'syrup')) {
                    this.setState({ checkMl: true })
                  } else {
                    this.setState({
                      checkMl: false,
                      checkOthers:false
                    })
                  }
                }
              })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPharmacyDrugSearchList = (searchValue) => {
    try {
      let params = '';
      if (this.state.drugSearchFor === 1) {
        params = `?brand_name=${searchValue}&generic_name=&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
      } else if (this.state.drugSearchFor === 2) {
        params = `?brand_name=&generic_name=${searchValue}&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
      } else {
        params = `?search_key=${searchValue}&brand_name=&generic_name=&clinic_id=${this.state.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
      }
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_DRUG_SEARCH + params)
        .then((response) => {
          if (response.data.status === 'success') {
            let data = response.data.data
            let uniqueArray = data?.filter((item, index, self) =>
              index === self.findIndex((t) => (
                  t.drug_name === item.drug_name
              ))
          );
            this.setState({
              searchedDrugList: this.state.drugSearchFor === 1 ? uniqueArray : response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  DeleteAllergy() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_ALLERGY_FEQ + "?allergy=" + states.SelectedAllergy).
        then((response) => {
          if (response.data.status === "success") {
            this.ClearData()
            this.getPharmacyList()
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  checkValidation = () => {
    try {
      let message = null;
      let states = this.state
      let isDrugValid = states?.ExternalDrugAdd ? true : (states.selectedDrug !== '') && (states.searchedGenericName === null) && (states.dosageStrength === '') && (states.dosageType === '')

      if (states?.ExternalDrugAdd) {
        if (states.ExternalDrugName === "") {
          message = 'Select Brand name'
        } else if (states.ExternalGenericName === "") {
          message = 'Select Generic Name'
        } else if (states.ExternalDosageType === "" || states.ExternalDosageStrength === "") {
          message = 'Dosage Strength and Dosage Type is Empty'
        } else if ((states.morningQty === '0' || states.morningQty === '') && (states.afternoonQty === '0' || states.afternoonQty === '') && (states.eveningQty === '0' || states.eveningQty === '') && (states.nightQty === '0' || states.nightQty === '')) {
          message = 'Please Select MAEN Dosage Quantity'
        } else if (states.selectedDays === '' || states.selectedDuration === '') {
          message = 'Select duration'
        } else if (states.selectedUsage === '') {
          message = 'Select Usage'
        }
      } else {
        if (states.selectedDrug === '') {
          message = 'Select Brand name'
        } else if (states.searchedGenericName === null || states.searchedGenericName === "") {
          message = 'Select Generic Name'
        } else if (states.dosageStrength === '' || states.dosageType === '') {
          message = 'Dosage Strength and Dosage Type is Empty'
        } else if ((states.morningQty === '0' || states.morningQty === '') && (states.afternoonQty === '0' || states.afternoonQty === '') && (states.eveningQty === '0' || states.eveningQty === '') && (states.nightQty === '0' || states.nightQty === '')) {
          message = 'Please Select MAEN Dosage Quantity'
        } else if (states.selectedDays === '' || states.selectedDuration === '') {
          message = 'Select duration'
        } else if (states.selectedUsage === '') {
          message = 'Select Usage'
        }
      }

      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  instructions = (item) => {
    let value = '';
    switch (item) {
      case 'Before Food':
        value = 'BF'
        break;
      case 'After Food':
        value = 'AF'
        break;
      case 'External Use':
        value = 'EU'
        break;
      case 'Empty Stomach':
        value = 'ES'
        break;
      case 'With Food':
        value = 'WF'
        break;
      default:
        value = item;
        break;
    }
    return value;
  }

  addPrescriptionData = () => {
    try {
      let states = this.state
      let list = {
        afternoon: states.afternoonQty ? states.afternoonQty : '0',
        brand_name: states?.ExternalDrugAdd ? states?.ExternalDrugName : states.selectedDrug,
        comments: states.comments,
        dosage_strength: states?.ExternalDrugAdd ? states?.ExternalDosageStrength : states.dosageStrength,
        dosage_type: states?.ExternalDrugAdd ? states?.ExternalDosageType : states.dosageType,
        drug_id: states?.ExternalDrugAdd ? null : states.searchedGenericName?.drug_id ? states.searchedGenericName?.drug_id : states.selectedDrugId,
        duration: states.selectedDuration,
        duration_mode: states.selectedDays,
        evening: states.eveningQty ? states.eveningQty : '0',
        generic_name: states?.ExternalDrugAdd ? states?.ExternalGenericName : states.searchedGenericName?.generic_name ? states.searchedGenericName?.generic_name : states.searchedGenericName,
        instructions: states.selectedUsage?.label ? this.instructions(states.selectedUsage?.label) : states.selectedUsage,
        is_favorite: JSON.parse(JSON.stringify(states.favourite)),
        morning: states.morningQty ? states.morningQty : '0',
        night: states.nightQty ? states.nightQty : '0',
        id: states.selectedId ? states.selectedId : null,
        is_unit_ml: states.checkMl ? states.checkMl : false,
        pharmacy_id: states.selectedPharmacy || '',
        is_unit_others: states.checkOthers ? states.checkOthers : false
      }
      console.log(list)

      let checkValidation = this.checkValidation()
      if (checkValidation === null) {
        let data = CommonValidation.updateOrInsertList(states.prescriptionList, list, states.selectedIndex)
        this.props.AddPrescritionItem(data)
        this.setState({ usagseId: null })
        this.ClearData()
      } else {
        this.errorMessage(checkValidation)
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  postTemplateData = () => {
    try {
      let data = {
        doctor_category: this.state.CategoryAddText
      }
      this.setState({ loading: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY_WISE)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.ClearData()
            this.getPatientCategoryList()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({ isDeleteClicked: false })
  }

  checkDuplicate = () => {
    try {
      let message = null
      let states = this.state
      states.drugList?.forEach(element => {
        if ((element?.name === states.searchedBrandName) || (element === states.searchedBrandName)) {
          message = 'Brand Name Already Exists'
        }
      })
      return message
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getExternalDrugSearchList = (brandName, genericName) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_EXTERNAL_DRUG + `?brand_name=${brandName}&generic_name=${genericName}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              ExternalDrugList: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderNewUsagePopup = () => {
    try {
      return (
        <Dialog open={this.state.openAddNew} >
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography fontSize={'0.9dvw'} fontWeight={600}>Add New Usage</Typography>
            <Button
              className='Common_Btn_Min_Width'
              onClick={() => this.setState({ openAddNew: false, prescUsage: null })}
            >
              <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1dvw'} width={'1dvw'} />
            </Button>
          </DialogTitle>
          <DialogContent>
            <TextField
              size='small'
              sx={{ width: '20dvw', mr: '1dvw' }}
              value={this.state.prescUsage}
              onChange={(e) => {
                if(e.target.value?.length <= 50){
                  this.setState({ prescUsage: e.target.value })
                }else{
                  this.errorMessage('Allowed Only 50 Characters')
                }
              }}
              placeholder='Enter Usage'
            />
            <Button variant='contained' sx={{ textTransform: 'capitalize' }}
              disabled={this.state.postUsageLoad}
              onClick={() => this.setState({ postUsageLoad: true }, () => this.postPresctiptionUsageList())}
            >Add</Button>
          </DialogContent>
        </Dialog>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getOptions = (option) => {
    try {
      let label = '';
      switch (option) {
        case 'ES':
          label = "Empty Stomach"
          break;
        case 'BF':
          label = "Before Food"
          break;
        case 'WF':
          label = "With Food"
          break;
        case 'AF':
          label = "After Food"
          break;
        case 'EU':
          label = "External Use"
          break;
        default:
          label = option
          break;
      }
      return label;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    const { t } = this.props
    
    let list = this.state.checkMl ? this.state.tabletQty?.with_ml : this.state.checkOthers ? this.state.tabletQty?.with_drops : this.state.tabletQty?.without_ml
    let states = this.state
    return (
      <Box>
        <Typography className='eMed_refdoc_title'>{t("Prescription")}</Typography>
        <Box height={"56.5vh"} overflow={'auto'}>
          <Box component={'div'} mt={'0.5vw'} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0.5dvw" }}>
            <FormControl size='small' sx={{ width: '15vw' }}>
              <InputLabel>Select Pharmacy *</InputLabel>
              <Select
                label='select Pharmacy'
                value={this.state.selectedPharmacy}
                disabled = {this.state.prescripTblData?.length > 0}
                // disabled = {this.state.prescripTblData?.length > 0 && this.state.prescripTblData[0]?.pharmacy_id === this.state.selectedPharmacy}
                onChange={(e) => this.setState({ selectedPharmacy: e.target.value },
                  () => { 
                    this.getFreqDrugList();
                    this.ClearData();
                   })}
              >
                {this.state.pharmacyList?.length > 0 && this.state.pharmacyList?.map((item, index) => (
                  <MenuItem value={item?.pharmacy_id} key={index}>{item?.pharmacy_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button variant='outlined' onClick={() => {
              this.setState({ ExternalDrugAdd: !this.state.ExternalDrugAdd })
            }}>{this.state.ExternalDrugAdd ? "Pharmacy Drugs" : "External Drugs"}</Button>
          </Box>
          {
            this.state.ExternalDrugAdd ? <Box>
              <Autocomplete
                size='small'
                sx={{ marginTop: '0.5vw', width: '19vw', mr: '0.5vw' }}
                clearIcon
                freeSolo
                autoSelect={false}
                options={this.state.ExternalDrugList?.map((item) => item?.drug_name)}
                value={this.state.ExternalBrandName ? this.state.ExternalBrandName : null}
                onChange={(e, value) => {
                  let selectedDrug = this.state.ExternalDrugList.filter((item) => item?.drug_name === value)
                  this.getExternalDrugSearchList(value, '')
                  this.setState({
                    ExternalBrandName: value
                  }, () => {
                    this.setState({
                      ExternalDrugName: selectedDrug[0]?.drug_name,
                      ExternalGenericName: selectedDrug[0]?.generic_name,
                      ExternalDosageStrength: selectedDrug[0]?.dosage_strength,
                      ExternalDosageType: selectedDrug[0]?.dosage_type

                    })
                  })
                }}
                renderInput={(params) => <TextField {...params}
                  onChange={(e) => {
                    if (e.target.value?.length > 0) {
                      states.ExternalDrugName = e.target.value
                      this.setState({
                        states
                      }, () => this.getExternalDrugSearchList(e.target.value, ''))
                    } else {
                      this.setState({
                        ExternalBrandName: null,
                        ExternalDrugName: '',
                        ExternalGenericName: '',
                        ExternalDosageStrength: '',
                        ExternalDosageType: '',
                      })
                    }
                  }}
                  label='Drug Name*' />}
              />
              <Box component={'div'} mt={'0.5vw'} sx={{ marginX: '0.5vw' }}>
                <Box component={'div'} mt={'0.5vw'} display={'flex'}>
                  <TextField
                    size='small'
                    sx={{ width: '15vw' }}
                    value={this.state.ExternalGenericName}
                    onChange={(e) => {
                      if ((e.target.value) || e.target.value === "") {
                        this.setState({ ExternalGenericName: e.target.value })
                      }
                    }}
                    label='generic Name *'
                  />
                  <TextField
                    size='small'
                    sx={{ width: '10vw', ml: '0.5vw' }}
                    value={this.state.ExternalDosageStrength}
                    onChange={(e) => {
                      if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                        this.setState({ ExternalDosageStrength: e.target.value })
                      }
                    }}
                    label='Dosage Strength *'
                  />
                  <TextField
                    size='small'
                    sx={{ width: '10vw', ml: '0.5vw' }}
                    value={this.state.ExternalDosageType}
                    onChange={(e) => {
                      if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                        this.setState({ ExternalDosageType: e.target.value }, () => {
                          if (this.state.ExternalDosageType === 'Syrup' || this.state.ExternalDosageType === 'syrup') {
                            this.setState({ checkMl: true })
                          } else {
                            this.setState({
                              checkMl: false,
                              checkOthers:false
                              // prescription auto fetch so cmt for this ******//
                              // morningQty: '0',
                              // afternoonQty: '0', eveningQty: '0',
                              // nightQty: '0'
                            })
                          }
                        })
                      }
                    }}
                    label='Dosage Type *'
                  />
                </Box>
              </Box>
            </Box> :
              <Box>
                {
                  this.state.FreqDrugList?.length > 0 ? this.state.FreqDrugList.map((item, index) => (
                    <Chip
                      className={`eMed_chip ${(item.name ? (item?.id === this.state.selectedDrugId && (item.name) === this.state.selectedDrug) : (item === this.state.selectedDrug)) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      label={item.name ? item?.name : item}
                      variant={(item.name ? (item?.id === this.state.selectedDrugId && (item.name) === this.state.selectedDrug) : (item === this.state.selectedDrug)) ? 'contained' : 'outlined'}
                      key={index}
                      onClick={() => {
                        this.setState({
                          selectedDrug: item?.name ? item?.name : item,
                          favourite: JSON.parse(JSON.stringify(item?.is_favorite)),
                          selectedDrugId: item?.id,
                          searchedGenericName: null,
                          dosageStrength: '', dosageType: ''
                        }, () => {
                          this.getGenericNameList(true)
                        })
                      }}
                      // color={'primary'}
                      clickable
                    />
                  )) : null
                }

                {/* <Autocomplete
                  size='small'
                  sx={{ margin: '0.5vw' }}
                  clearIcon
                  freeSolo
                  options={this.state.searchedDrugList?.map((item) => item)}
                  value={this.state.selectedDrug ? this.state.selectedDrug : this.state.searchedBrandName ? this.state.searchedBrandName : ""}
                  onChange={(e, value) => {
                    this.setState({
                      searchedBrandName: value
                    }, () => {
                      if (this.state.searchedBrandName?.length > 0) {
                        let check = this.checkDuplicate()
                        // if (check === null) {
                        //   this.state.FreqDrugList?.push(this.state.searchedBrandName)
                        // }
                        this.setState({
                          FreqDrugList: this.state.FreqDrugList,
                          selectedDrug: this.state.searchedBrandName
                        }, () => {
                          this.setState({ searchedBrandName: '', searchedDrugList: [] })
                          this.getGenericNameList(this.state.selectedDrug)
                        })
                        // } else {
                        //   this.errorMessage(check)
                        //   this.setState({ searchedBrandName: '', searchedDrugList: [] })
                        // }
                      }
                    })
                  }}
                  renderInput={(params) => <TextField {...params}
                    onChange={(e) => {
                      if (e.target.value?.length > 1) {
                        this.setState({
                          searchedBrandName: e.target.value
                        }, () => {
                          this.getPharmacyDrugSearchList(this.state.searchedBrandName)
                        })
                      } else {
                        this.setState({ genericList: [] })
                      }
                    }}
                    label='Search Drug *' />}
                /> */}
                <Autocomplete
                  size='small'
                  sx={{ margin: '0.5vw' }}
                  clearIcon
                  options={this.state.searchedDrugList}
                  getOptionLabel={(option) => typeof (option) === 'string' ? option : (this.state.drugSearchFor === 1 ? option.drug_name : `${option.drug_name} - ${option?.generic_name}`)}
                  value={this.state.selectedDrug ? this.state.selectedDrug : this.state.searchedBrandName ? this.state.searchedBrandName : ""}
                  onChange={(e, value) => {
                    this.setState({ searchedBrandName: value }, () => {
                      if (this.state.searchedBrandName) {
                        this.setState({ selectedDrug: this.state.searchedBrandName?.drug_name }, () => {
                          if (this.state.drugSearchFor === 1) {
                            this.getGenericNameList(this.state.searchedBrandName?.drug_name)
                          } else {
                            this.setState({ searchedGenericName: this.state.searchedBrandName?.generic_name },() => this.getGenericNameList())
                          }
                        })
                      } else {
                        this.setState({
                          searchedBrandName: '',
                          searchedDrugList: [],
                          searchedGenericName: null,
                          dosageStrength: '',
                          dosageType: ''
                        })
                      }
                    })
                  }}
                  renderInput={(params) => <TextField {...params} label={'Search Drug'}
                    onChange={(e) => {
                      if (e.target.value?.length > 1) {
                        this.setState({ searchedBrandName: e.target.value }, () => {
                          this.getPharmacyDrugSearchList(this.state.searchedBrandName)
                        })
                      }
                    }}
                  />}
                  renderOption={(props, option) => (
                    <li {...props} key={props['data-option-index']}>
                        <Typography fontSize={'0.9vw'}>{this.state.drugSearchFor === 1 ? option.drug_name : `${option.drug_name} - ${option?.generic_name}`}</Typography>
                    </li>
                )}
                />
                <Box component={'div'} mt={'0.5vw'} sx={{ marginX: '0.5vw' }}>
                  <Box component={'div'} mt={'0.5vw'} display={'flex'}>
                    <Autocomplete
                      size='small'
                      sx={{ width: '15vw' }}
                      clearIcon
                      freeSolo
                      readOnly={this.state.drugSearchFor === 1 ? false : true}
                      options={this.state.genericList}
                      getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.generic_name}
                      value={this.state.searchedGenericName ? this.state.searchedGenericName : ""}
                      onChange={(e, value) => {
                        this.setState({
                          searchedGenericName: value
                        }, () => {
                          this.setState({
                            dosageStrength: this.state.searchedGenericName?.dosage_strength,
                            dosageType: this.state.searchedGenericName?.dosage_type,
                            morningQty: this.state.searchedGenericName?.latest_prescription?.morning,
                            afternoonQty: this.state.searchedGenericName?.latest_prescription?.afternoon,
                            eveningQty: this.state.searchedGenericName?.latest_prescription?.evening,
                            nightQty: this.state.searchedGenericName?.latest_prescription?.night,
                            selectedDays: this.state.searchedGenericName?.latest_prescription?.duration_mode,
                            selectedDuration: this.state.searchedGenericName?.latest_prescription?.duration,
                            selectedUsage: this.state.searchedGenericName?.latest_prescription?.instructions,
                            comments: this.state.searchedGenericName?.latest_prescription?.comments,
                          }, () => {
                            if ((this.state.searchedGenericName?.dosage_type === 'Syrup') || (this.state.searchedGenericName?.dosage_type === 'syrup')) {
                              this.setState({ checkMl: true })
                            } else {
                              this.setState({
                                checkMl: false,
                                checkOthers: false
                                // prescription auto fetch so cmt for this ******//
                                // morningQty: '0',
                                // afternoonQty: '0', eveningQty: '0',
                                // nightQty: '0'
                              })
                            }
                          })
                        })
                      }}
                      renderInput={(params) => <TextField {...params}
                        label='Select Generic Name *' />}
                    />
                    <TextField
                      size='small'
                      sx={{ width: '10vw', ml: '0.5vw' }}
                      value={this.state.dosageStrength}
                      InputProps={{ readOnly: true }}
                      label='Dosage Strength'
                    />
                    <TextField
                      size='small'
                      sx={{ width: '10vw', ml: '0.5vw' }}
                      value={this.state.dosageType}
                      InputProps={{ readOnly: true }}
                      label='Dosage Type'
                    />
                  </Box>
                </Box>
              </Box>
          }
          <Box component={'div'} mt={'0.5vw'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box component={'div'}>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600}>MAEN Dosage Quantity *</Typography>
                <FormControlLabel checked={this.state.checkMl} control={<Checkbox size='small' />}
                  onChange={(e) => {
                    this.setState({
                      checkMl: e.target.checked
                    }, () => {
                      if (!this.state.checkMl) {
                        this.setState({
                          morningQty: '0',
                          eveningQty: '0',
                          afternoonQty: '0',
                          nightQty: '0'
                        })
                      } else if (this.state.checkMl) {
                        this.setState({ 
                          checkOthers: false,
                          morningQty: '0',
                          eveningQty: '0',
                          afternoonQty: '0',
                          nightQty: '0'
                         })
                      }
                    })
                  }} label='ML' />
                  <FormControlLabel checked={this.state.checkOthers} control={<Checkbox size='small' />}
                  onChange={(e) => {
                    this.setState({
                      checkOthers: e.target.checked
                    }, () => {
                      if (!this.state.checkOthers) {
                        this.setState({
                          morningQty: '0',
                          eveningQty: '0',
                          afternoonQty: '0',
                          nightQty: '0'
                        })
                      } else if (this.state.checkOthers) {
                        this.setState({
                          checkMl: false,
                          morningQty: '0',
                          eveningQty: '0',
                          afternoonQty: '0',
                          nightQty: '0'
                        })
                      }
                    })
                  }} label='Others' />
              </Box>
              <Box component={'div'} display={'flex'} alignItems={'center'} mt={'0.5vw'}>
                <Typography mr={'1.8vw'} fontSize={'0.9vw'} fontWeight={600}>Morning</Typography>
                {list?.length > 0 && list?.map((item, index) => (
                  <Chip
                    className={`eMed_chip ${(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.morningQty ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    label={this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label}
                    variant={(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.morningQty ? 'contained' : 'outlined'}
                    key={index}
                    onClick = {()=>{
                      const selectedLabel = this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label;
                      if ( this.state.morningQty  === selectedLabel) {
                        this.setState({ morningQty: "" });
                      } else {
                        this.setState({ morningQty: this.state.checkMl ? item.value2 : this.state.checkOthers ? item?.value3 : item.value });
                      }
                    }}
                    // color={'primary'}
                    clickable
                  />
                ))}
                {this.state.checkOthers ? 
                  <TextField size='small' sx={{ width: '4vw' }} InputProps={{ style: { height: '5vh' }, emed_tid: 'mrg_txt' }}
                    value={this.state.morningQty}
                    onChange={(e) => {
                      let value = e.target.value
                      if(CommonValidation.DecimalNumberTwo(value) || value === ''){
                        this.setState({ morningQty: value })
                      }
                    }}
                />
                :null}
              </Box>
              <Box component={'div'} display={'flex'} alignItems={'center'}>
                <Typography sx={{ mr: '1vw' }} fontSize={'0.9vw'} fontWeight={600}>Afternoon</Typography>
                {list?.length > 0 && list?.map((item, index) => (
                  <Chip
                    className={`eMed_chip ${(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.afternoonQty ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    label={this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label}
                    variant={(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.afternoonQty ? 'contained' : 'outlined'}
                    key={index}
                    onClick = {()=>{
                      const selectedLabel = this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label;
                      if ( this.state.afternoonQty  === selectedLabel) {
                        this.setState({ afternoonQty: "" });
                      } else {
                        this.setState({ afternoonQty: this.state.checkMl ? item.value2 : this.state.checkOthers ? item?.value3 : item.value });
                      }
                    }}
                    // color={'primary'}
                    clickable
                  />
                ))}
                {this.state.checkOthers ? 
                  <TextField size='small' sx={{ width: '4vw' }} InputProps={{ style: { height: '5vh' }, emed_tid: 'aft_txt' }}
                    value={this.state.afternoonQty}
                    onChange={(e) => {
                      let value = e.target.value
                      if(CommonValidation.DecimalNumberTwo(value) || value === ''){
                        this.setState({ afternoonQty: value })
                      }
                    }}
                />
                :null}
              </Box>
              <Box component={'div'} display={'flex'} alignItems={'center'}>
                <Typography mr={'2vw'} fontSize={'0.9vw'} fontWeight={600}>Evening</Typography>
                {list?.length > 0 && list?.map((item, index) => (
                  <Chip
                    className={`eMed_chip ${(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.eveningQty ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    label={this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label}
                    variant={(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.eveningQty ? 'contained' : 'outlined'}
                    key={index}
                    onClick = {()=>{
                      const selectedLabel = this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label;
                      if ( this.state.eveningQty  === selectedLabel) {
                        this.setState({ eveningQty: "" });
                      } else {
                        this.setState({ eveningQty: this.state.checkMl ? item.value2 : this.state.checkOthers ? item?.value3 : item.value });
                      }
                    }}
                    // color={'primary'}
                    clickable
                  />
                ))}
                {this.state.checkOthers ? 
                  <TextField size='small' sx={{ width: '4vw' }} InputProps={{ style: { height: '5vh' }, emed_tid: 'eve_txt' }}
                    value={this.state.eveningQty}
                    onChange={(e) => {
                      let value = e.target.value
                      if(CommonValidation.DecimalNumberTwo(value) || value === ''){
                        this.setState({ eveningQty: value })
                      }
                    }}
                />
                :null}
              </Box>
              <Box component={'div'} display={'flex'} alignItems={'center'}>
                <Typography mr={'3vw'} fontSize={'0.9vw'} fontWeight={600}>Night</Typography>
                {list?.length > 0 && list?.map((item, index) => (
                  <Chip
                    className={`eMed_chip ${(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.nightQty ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                    label={this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label}
                    variant={(this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label) === this.state.nightQty ? 'contained' : 'outlined'}
                    key={index}
                    onClick = {()=>{
                      const selectedLabel = this.state.checkMl ? item.label2 : this.state.checkOthers ? item?.label3 : item.label;
                      if ( this.state.nightQty  === selectedLabel) {
                        this.setState({ nightQty: "" });
                      } else {
                        this.setState({ nightQty: this.state.checkMl ? item.value2 : this.state.checkOthers ? item?.value3 : item.value });
                      }
                    }}
                    // color={'primary'}
                    clickable
                  />
                ))}
                {this.state.checkOthers ? 
                  <TextField size='small' sx={{ width: '4vw' }} InputProps={{ style: { height: '5vh' }, emed_tid: 'ngt_txt' }}
                    value={this.state.nightQty}
                    onChange={(e) => {
                      let value = e.target.value
                      if(CommonValidation.DecimalNumberTwo(value) || value === ''){
                        this.setState({ nightQty: value })
                      }
                    }}
                />
                :null}
              </Box>
            </Box>
            <Box component={'div'} pl={'0.5vw'}>
              <Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginY={'0.5vw'} pr={"1vw"} pl={'0.8vw'}>
                <Typography fontSize={'0.9vw'} fontWeight={600}>Duration *</Typography>
                <FormControl size='small'>
                  <InputLabel>Select Duration</InputLabel>
                  <Select
                    sx={{ width: '10vw' }}
                    label='Select Duration'
                    value={this.state.selectedDays}
                    onChange={(e) => this.setState({ selectedDays: e.target.value, selectedDuration: '' })}
                  >
                    <MenuItem value={'1'}>Days</MenuItem>
                    <MenuItem value={'2'}>Weeks</MenuItem>
                    <MenuItem value={'3'}>Months</MenuItem>
                    <MenuItem value={'4'}>Years</MenuItem>
                    <MenuItem value={'5'}>Alternative Days</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box component={'div'} mt={'0.5vw'}>
                {(this.state.selectedDays === '1' || this.state.selectedDays === '5') ?
                  this.state.durationDays?.map((item, index) => (
                    <Chip
                      className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      label={item.label}
                      variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                      key={index}
                      onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                      // color={'primary'}
                      clickable
                    />
                  )) : this.state.selectedDays === '2' ?
                    this.state.durationWeeks?.map((item, index) => (
                      <Chip
                        className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                        label={item.label}
                        variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                        key={index}
                        onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                        // color={'primary'}
                        clickable
                      />
                    )) : this.state.selectedDays === '3' ?
                      this.state.durationMonts?.map((item, index) => (
                        <Chip
                          className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                          // color={'primary'}
                          clickable
                        />
                      )) : this.state.durationYears?.map((item, index) => (
                        <Chip
                          className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                          // color={'primary'}
                          clickable
                        />
                      ))
                }
              </Box>
            </Box>
          </Box>
          <Box component={'div'} mt={'0.5vw'} display={'flex'} flexDirection={'column'}>
            {/* <FormControl size='small' sx={{ width: '28vw', marginLeft: '1.5vw' }}>
              <InputLabel>Select Usage *</InputLabel>
              <Select
                label='Select Usage'
                value={this.state.selectedUsage}
                onChange={(e) => this.setState({ selectedUsage: e.target.value })}
              >
                {this.state.prescriptionUsage?.length > 0 && this.state.prescriptionUsage?.map((item, index) => (
                    <MenuItem value={item?.value} key={index}>
                      <Box component={'span'}>{item?.label}</Box>
                      <DeleteIcon color='primary'/>
                    </MenuItem>
                ))}
                <Divider />
                <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => { this.setState({ openAddNew: true }) }}>
                      <ListItemText primary={'Add New Usage'} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Select>
            </FormControl> */}
            <Autocomplete
              clearIcon
              size='small'
              sx={{ width: '28vw', marginLeft: '1.5vw' }}
              options={this.state.prescriptionUsage}
              getOptionLabel={(option) => typeof (option) === 'string' ? this.getOptions(option) : option.label}
              value={this.state.selectedUsage}
              onChange={(e, value) => this.setState({ selectedUsage: value })}
              renderInput={(params) => <TextField {...params} label={'Select Usage'} />}
              renderOption={(props, item) => {
                return (
                  <Box component={'div'} display={'flex'} justifyContent={'space-between'} key={props["data-option-index"]}>
                    <Box component="li" {...props} width={'100%'}>
                      <Typography>{item?.label}</Typography>
                    </Box>
                    {item?.is_added ?
                      <Button className='Common_Btn_Min_Width'
                        onClick={() => this.deletePresctiptionUsageList(item)}
                      ><DeleteIcon color='error' /></Button> : null}
                  </Box>
                )
              }}
              PaperComponent={({ children }) => {
                return (
                  <Paper>
                    {children}
                    <Divider />
                    <Button
                      color="primary"
                      fullWidth
                      sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                      onMouseDown={(e) => {
                        this.setState({ openAddNew: true })
                      }}
                    >
                      Add Usage
                    </Button>
                  </Paper>
                )
              }}
            />
            <Box component={'div'}>
              <TextField
                size='small'
                className='eMed_DocNotes_TextFields'
                multiline={true}
                rows={3}
                label={t("Comments")}
                inputProps={{ maxLength: 250 }}
                value={this.state.comments}
                onChange={(e) => {
                  let value = e.target.value
                  if (value?.length < 250) {
                    this.setState({ comments: e.target.value })
                  } else {
                    this.errorMessage('Allowed only 250 characters')
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
        <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
          <Button variant='outlined' size="small" _id="eMed_Config_btn" onClick={() => { this.ClearData(true) }}>{t("Clear")}</Button>
          <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.addPrescriptionData() }}>{t("Add")}</Button>
        </Stack>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.isDeleteClicked ? <DeletePopup
          DeletTitle={`${t("deleteMsg")}  ${t("Allergy")} ${this.state.SelectedAllergy ? this.state.SelectedAllergy : "-"} From Frequent List ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.DeleteAllergy.bind(this)}
        /> : null}
        {this.renderNewUsagePopup()}
      </Box>
    )
  }
}
export default withTranslation()(DocPrecriptionRight)
