import React, { Component } from 'react';
import {
    Box, Button, MenuItem, Paper, Stack, Tooltip, Menu, IconButton, Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next'
import './Billings.css';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../Utility/ImagePaths';
import Drawer from '@mui/material/Drawer';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BillingsFilter from './BillingsFilter';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { DateTime } from 'luxon';
import PrintPreviewPop from '../../../Components/Common Components/PrintPreviewPop';
import Loader from '../../../Components/Loader';
import CommonGridHeader, { CommonGridToolBarWithFilterTextCustom } from '../../../Components/Common Components/CommonComponents';
import { AmountFormat, ContentInTooltip, formatDate, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { CurrencySymbol } from '../../../Utility/Constants';

class CancelledBills extends Component {
    constructor(props) {
        super(props)
        let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null
        let PharmacyList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)).map((list) => list?.pharmacy_id) : []
        let LabList = LoggedData?.laboratory_data ? JSON.parse(JSON.stringify(LoggedData?.laboratory_data)).map((list) => list?.laboratory_id) : []
        this.state = {
            page: 0,
            pageSize: 10,
            BillList: [],
            ref: props.location?.pathname,
            FilterOpen: false,
            FilterDatas: {
                "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": 1,
                "PatientName": "",
                "AccNo": "",
                "MobNo": "",
                "DoctorName": [],
                "BillNumber": "",
                "PaidAmount": "",
                "InPatientSelected": true,
                "OutPatientSelected": true,
                "PharmacySelected": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "LaboratorySelected": true,
                "ImageLabSelected": true,
                "OTSelected": true,
                "PaidSelected": true,
                "PartialPaidSelected": true,
                "CreditSelected": true,
                "CardSelected": true,
                "CashSelected": true,
                "UPISelected": true,
                "BankTransferSelected": true,
                "InsuranceSelected": true,
                "ChequeSelected": true,
                "BillType": ['op', 'ip', 'lab', localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? 'pharmacy' : "-", localGetItem("Show_Radiology_Bills_In_FO") === "true" ? 'radiology' : "-"],
                "PayStatus": [],
                "PayMethods": [],
                "startTime":null,
                "endTime":null,
                "BillModuleType": ['op', 'ip', 'lab', localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? 'pharmacy' : "-", localGetItem("Show_Radiology_Bills_In_FO") === "true" ? 'radiology' : "-"],
                "opCreditBills" : true,
                "ipCreditBills" : true,
                "pharmaCreditBills" : localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "labCreditBills" : true,
                "RadiologyBills":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "RadiologySelected":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "radiologyCreditBills":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                'MultiPharmaIds': localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? [] : PharmacyList,
                'MultiLabIds': LabList,
            },
            TotalPatient: 0,
            TotalAmount: 0,
            BillPreviewPop:false,
            SelectedBill:{},
        }
    }

    componentDidMount() {
        this.GetBillList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    GetBillList() {
        try {
            var states = this.state

            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

            let doctorId = [];
            states.FilterDatas?.DoctorName.forEach(element => doctorId.push(element?.doctor_id))
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.COMPLETE_BILLS_GET +
                "?bill_status=cancelled" +
                "&patient_name=" + states.FilterDatas.PatientName +
                "&account_number=" + states.FilterDatas.AccNo +
                "&mobile_number=" + states.FilterDatas.MobNo +
                "&doctor_id=" + doctorId +
                "&bill_number=" + states.FilterDatas.BillNumber +
                "&paid_amount=" + states.FilterDatas.PaidAmount +
                "&from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                "&moduel_type=" + states.FilterDatas.BillType +
                "&payment_method=" + states.FilterDatas.PayMethods + 
                "&start_time=" + setStartTime + 
                "&bills=" + states?.FilterDatas?.BillModuleType +
                "&end_time=" + setEndTime +
                "&pharmacy_id=" + states?.FilterDatas?.MultiPharmaIds +
                "&laboratory_id=" + states?.FilterDatas?.MultiLabIds
            ).
                then((response) => {
                    if (response?.data?.status === "success") {
                        this.setState({
                            BillList: response.data?.cancelld_bill,
                            TotalPatient: response.data?.total_count,
                            TotalAmount: response.data?.total_amount,
                        },()=>{this.LoaderFunction(false)})
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    if(error?.response?.data?.status === "fail"){
                        this.LoaderFunction(false)
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    } 
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    getCancelBillPrint = () => {
        try {
            let states = this.state
            
            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

            let doctorId = [];
            states.FilterDatas?.DoctorName.forEach(element => doctorId.push(element?.doctor_id))
            RestAPIService.getAllPrint(Serviceurls.COMPLETE_BILLS_GET +
                "?bill_status=cancelled" +
                "&patient_name=" + states.FilterDatas.PatientName +
                "&account_number=" + states.FilterDatas.AccNo +
                "&mobile_number=" + states.FilterDatas.MobNo +
                "&doctor_id=" + doctorId +
                "&bill_number=" + states.FilterDatas.BillNumber +
                "&paid_amount=" + states.FilterDatas.PaidAmount +
                "&from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                "&moduel_type=" + states.FilterDatas.BillType +
                "&payment_method=" + states.FilterDatas.PayMethods +
                "&start_time=" + setStartTime +
                "&bills=" + states?.FilterDatas?.BillModuleType +
                "&end_time=" + setEndTime +
                "&pharmacy_id=" + states?.FilterDatas?.MultiPharmaIds +
                "&laboratory_id=" + states?.FilterDatas?.MultiLabIds +
                "&export_type=pdf"
            ).then((response) => {
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    this.errorMessage(error.response.data.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    MenuItem = (data) => {
        const { t } = this.props
        const multi_option = [
            { value: "NextAppointment", label: t("NextAppointment") },
            { value: "PrintDoctorNotes", label: t("PrintDoctorNotes") },
            { value: "PrintPrescription", label: t("PrintPrescription") },
            { value: "ShareViaEmail", label: t("ShareViaEmail") },
            { value: "ShareViaWhatsApp", label: t("ShareViaWhatsApp") },
            { value: "ShareViaSMS", label: t("ShareViaSMS") },
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                    disabled
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (

                            <MenuItem key={option} onClick={() => { this.setState({ selectedOption: option.value, anchorEl: null }) }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }
    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
        }, () => { this.GetBillList() })
    }

    ClosePopUp() {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
        })
    }
    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
        })
      }

      gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

        let TextArray = [
            {label:"From Date", value: formatDate(this.state.FilterDatas.FromDate)},
            {label:"To Date", value:formatDate(this.state.FilterDatas.ToDate)},
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        var ExportData = []
        this.state.BillList?.length > 0 && this.state.BillList.map((item) => 
        ExportData.push({"Date And Time":item.invoice_date, "Bill Number":item.bill_number, "Bill Type":item.bill_types, "Patient Name": item.patient_name, "Doctor/Nurse Name": item.doctor_name, "Total BIll": item.amount_net, "Reason": item.cancel_reason.replace(",", '. ') })
        )
        return(
          <Box>
             <CommonGridToolBarWithFilterTextCustom data={ExportData} title={"FO Cancelled Bills"}  FilterTextArray={TextArray} filename={"CancelledBills"}/>
          </Box>
    
        )
      }

      clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    render() {
        const { t } = this.props
        this.state.BillList.forEach((element, index) => { element.sno = index + 1 })
        const columns = [
            {
                field: "invoice_date", flex: 0.111, headerName: t("DateAndTime"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? params?.row?.invoice_date : "-"}</Box>)
            },
            {
                field: "bill_number", flex: 0.131, headerName: t("BillNumber"), headerAlign: "center", align: "center",
                renderCell: (params) => {
                     let BillNumber =  "-" ;
                     if(params?.row?.temp_bill_number && params?.row?.bill_number){ BillNumber = params?.row?.bill_number}
                     else if(params?.row?.bill_number){ BillNumber = params?.row?.bill_number}
                     else if(params?.row?.temp_bill_number){ BillNumber = params?.row?.temp_bill_number}
                     const billArr = BillNumber ? BillNumber?.split("/") : [];
                    return (
                        <Tooltip placement='top' title={BillNumber} arrow>
                            <Box component={'div'}>{billArr?.length > 0 ? '...' + billArr?.slice(-2)?.join("/") : "-"}</Box>
                        </Tooltip>
                    )
                }
            },
            {
                field: "bill_types", flex: 0.111, headerName: t("BillType"),
                renderCell: (params) => (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.9vw"} fontWeight={600}>{params?.row?.bill_types ? params?.row?.bill_types : "-"}</Typography>
                        <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.7vw"} >{
                            (params?.row?.bill_types === "Pharmacy" && localGetItem("multiPharmacy") === "true") ? ContentInTooltip(params?.row?.pharmacy_name ? params?.row?.pharmacy_name : "-", 10, "0.8vw") :
                                (params?.row?.bill_types === "Laboratory" && localGetItem("multiLaboratory") === "true") ? ContentInTooltip(params?.row?.laboratory_name ? params?.row?.laboratory_name : "-", 10, "0.8vw") : ""}</Typography>
                    </Box>)
            },
            {
                field: "bill_type", flex: 0.111, headerName: t("BillStatus"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.bill_type ? params?.row?.bill_type === "cancelled" ? "Cancelled" : "-" : "-"}</Box>)
            },
            {
                field: "patient_name", flex: 0.121, headerName: t("PatientName"),
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.patient_name ? params?.row?.patient_name?.length > 15 ?
                        <Tooltip placement='top' title={params?.row?.patient_name} arrow>
                            <div>{params?.row?.patient_name.slice(0, 15) + "..."}</div></Tooltip> :
                        params?.row?.patient_name : "-"}</Box>)
            },
            {
                field: "docter_name", flex: 0.111, headerName: t("DoctorNurseName"),
                renderCell: (params) => (<Box component={'div'}>{(params?.row?.doctor_name) ? (params?.row?.doctor_name) : (params?.row?.nurse_name) ? (params?.row?.nurse_name) : "-"}</Box>)
            },
            {
                field: "amount_net", flex: 0.111, headerName: t('TotalBill')+`(${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.amount_net ? AmountFormat(params?.row?.amount_net)?.replace(`${CurrencySymbol}`, "") : params?.row?.amount_net === 0 ? "0" : "-"}</Box>)
            },
            {
                field: "cancel_reason", flex: 0.111, headerName: t("Reason"), headerAlign: "center", align: "center",
                renderCell: (params) => <Box>
                    {
                        params?.row?.cancel_reason ?
                            params.row.cancel_reason?.length > 15 ?
                                <Tooltip title={params.row.cancel_reason} placement="top" arrow>
                                    <Typography>{params.row.cancel_reason.slice(0, 15) + "..."}</Typography>
                                </Tooltip> : <Typography>{params.row.cancel_reason}</Typography>
                            : "-"
                    }

                </Box>
            },
            {
                field: "action", flex: 0.08, headerName: t("Action"), headerAlign: "center", align: "center",sortable: false,
                renderCell: (params) => <Box display={"flex"} flexDirection={"row"}  alignItems={"center"}>
                    <Tooltip title={t("PrintBill")} placement="top" arrow>
                        <Button className='eMed_usrconf_btn' onClick={()=>{this.setState({BillPreviewPop:true, SelectedBill:params.row})}}>
                            <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_action_img' />
                        </Button></Tooltip>
                    {/* <Tooltip title={t("CreateBill")} placement="top">
                        <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.Export.default} alt="Create Bill" className='eMed_action_img' />
                        </Button>
                    </Tooltip>
                    {this.MenuItem(params)}  */}
                </Box>
            }
        ]        
        return (
            <Box component={"div"} className='eMed_Main_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("Total Cancelled Bills"), this.state.TotalPatient, true)}
                        {AmountsCard(t("Total Amount"), this.state.TotalAmount)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Tooltip title="Filter" placement='top' arrow>
                                <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                    <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                        </Box>
                        <Tooltip title="Print" placement='top' arrow>
                            <Button className='eMed_usrconf_btn'
                            onClick={() => this.getCancelBillPrint()}
                            >
                                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                            </Button></Tooltip>
                        {/* <Tooltip title="Export" placement='top' arrow>
                            <Button className='eMed_usrconf_btn'>
                                <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                            </Button></Tooltip> */}
                    </Box>
                </Box>
                <Box component={"div"} className='eMed_Table_Container'>
                    <Stack component={"div"}>
                        <Paper className='eMed_Table_Wrapper' >
                            <DataGrid
                                rows={this.state.BillList}
                                columns={columns}
                                getRowId={(row) => row['sno']}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                hideFooterSelectedRowCount
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                components={{
                                    Toolbar: this.gridToolBar,
                                    NoRowsOverlay: () => (
                                        <Stack className='eMed_conf_nodata'>
                                            {t("NoRecordsFound")}
                                        </Stack>
                                    )
                                }}
                                rowsPerPageOptions={[10, 20, 30]}
                                disableSelectionOnClick
                                pagination
                                onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Stack>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <BillingsFilter PageName={"CancelledBills"} FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} callDocApi={true}/>
                </Drawer>
                {
                    this.state.BillPreviewPop ?
                        <PrintPreviewPop
                        URL={Serviceurls.INVOICE_PRINT}
                        Title={t("BillPreview")}
                        BillData={this.state.SelectedBill}
                        ClosePopUp={this.ClosePopUp.bind(this)}
                        ModifyBillButton={false}
                        cancelBillButton={false}
                        PrintButton={false} 
                        history={this.props.history}/> : null
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {/* <Loader loaderOpen={this.state.isLoader} /> */}
            </Box>
        )
    }
}

export default withTranslation()(CancelledBills)


