import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import CommonGridHeader, { CommonPatientCard, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import { Colors } from '../../../../Styles/Colors'
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import Loader from '../../../../Components/Loader';
import { DataGrid } from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker'

const Order_Type = [
  { label: 'All', value: 'All' },
  { label: 'Prescription', value: 'Prescription' },
  { label: 'Labtest', value: 'Labtest' },
  { label: 'Investigation', value: 'Investigation' },
  { label: 'Glass Prescription', value: 'Glass Prescription' },
]

export default class DocPrintOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientDetails: {},
      clinicId: '',
      patientId: '',
      appointmentId: '',
      printOrderData: [],
      page: 0,
      pageSize: 10,
      loading: false,
      startDate: '',
      endDate: '',
      dateIndex: 0,
      orderTypeList: Order_Type,
      selectedOrderType: Order_Type[0]
    }
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = this.props.fromOT ? JSON.parse(getCachevalue("OTpatientData")) : JSON.parse(getCachevalue('DoctorPatientData'))
    let UserData = JSON.parse(localGetItem('loggedInUserInfo'))
    this.setState({
      clinicId:this.props.history?.location?.state?.fromView ? UserData?.clinic_id : clinicId,
      appointmentId: appointmentId?.appointment_id ? appointmentId?.appointment_id : this.props.history?.location?.state?.patientDetails?.appointment_id,
      patientId: appointmentId?.patient_id ? appointmentId?.patient_id : this.props.history?.location?.state?.patientDetails?.patient_id
    }, () => {
      this.getPatientDetailData()
      this.getPrintOrderReportData()
    })
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPrintOrderReportData = () => {
    try {
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_PRINT_ORDER_REPORT + `?patient_id=${this.state.patientId}&clinic_id=${this.state.clinicId}&from_date=${this.state.startDate}&to_date=${this.state.endDate}&test=${this.state.selectedOrderType?.label}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              printOrderData: response.data.data,
              loading: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  printOrderDataPrint = (item) => {
    try {
      let params = ""
      if (item?.order === "Prescription") {
        params = Serviceurls.OPT_PRESCRIPTION_PRINT + `?prescription_id=${item?.prescription_id}&clinic_id=${this.state.clinicId}&language=${"english"}&appointment_id=${item?.appointment_id}`
      } else {
        params = Serviceurls.DOC_PRINT_ORDER_REPORT + `?patient_id=${this.state.patientId}&clinic_id=${this.state.clinicId}&appointment_id=${item?.appointment_id}&from_date=${this.state.startDate}&to_date=${this.state.endDate}&test=${item?.order}&export_type=pdf`
      }
      RestAPIService.getAllTwo(params)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  filterDate = (From, To, Index) => {
    this.setState({
      startDate: From,
      endDate: To,
      dateIndex: Index
    })
  }

  onClearPrintOrder = () => {
    try {
      this.setState({
        selectedOrderType: Order_Type[0],
        startDate: '',
        endDate: '',
        dateIndex: 0
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderLeftSide = () => {
    try {
      this.state.printOrderData?.forEach((element, index) => element.sno = index + 1)
      const columns = [
        { field: 'appointment_date', headerName: 'Appointment Date & Time', flex: 0.25 },
        { field: 'doctor_name', headerName: 'Doctor Name', flex: 0.25 },
        { field: 'order', headerName: 'Order Type', flex: 0.25 },
        {
          field: 'action', headerName: 'Action', flex: 0.25,
          renderCell: ({ row }) => (
            <Box>
              <Button sx={{ textTransform: 'capitalize' }} className='Common_Btn_Min_Width'
                onClick={() => this.printOrderDataPrint(row)}
              >Print</Button>
            </Box>), disableExport: true
        },
      ]
      return (
        <DataGrid
          rows={this.state.printOrderData}
          columns={columns}
          getRowId={(row) => row['sno']}
          page={this.state.page}
          pageSize={this.state.pageSize}
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          sx={{ backgroundColor: Colors.white }}
          components={{
            Toolbar: CommonGridHeader.CommonToolBar,
            NoRowsOverlay: () => (
              <Box className='eMed_conf_nodata'>
                {'No Records To Be Shown'}
              </Box>
            )
          }}
          onPageChange={(newPage) => { this.setState({ page: newPage }) }}
          onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          headerHeight={40}
          disableSelectionOnClick
          loading={this.state.loading}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRightSide = () => {
    try {
      return (
        <Box>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Print Order Reports</Typography>
          </Box>
          <Box component={'div'} height={'23vw'} sx={{ background: Colors.white }} p={'1vw'}>
            <Box component={'div'} width={'21vw'}>
              <CommonDatePicker
                FromDate={this.state.startDate}
                ToDate={this.state.endDate}
                DateIndex={this.state.dateIndex}
                SelectedDates={this.filterDate.bind(this)}
              // HideAllMenu={true}
              />
            </Box>
            <Box mt={'1vw'}>
              <Autocomplete
                clearIcon
                size='small'
                sx={{ width: '20vw' }}
                autoComplete='off'
                defaultValue={'All'}
                options={this.state.orderTypeList}
                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.label}
                value={this.state.selectedOrderType}
                onChange={(event, newValue) => this.setState({ selectedOrderType: newValue })}
                renderInput={(params) => <TextField {...params} label='Select Order Type' />}
              />
            </Box>
          </Box>
          <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
            <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
              variant='outlined'
              onClick={() => this.onClearPrintOrder()}
            >Clear</Button>
            <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
              variant='contained'
              onClick={() => this.getPrintOrderReportData()}
            >Search</Button>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'61.5vh'}>
          <Box component={'div'} flex={0.65} overflow={'scroll'}>
            {this.renderLeftSide()}
          </Box>
          <Box component={'div'} flex={0.35} border={'1px solid lightgray'}>
            {this.renderRightSide()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
