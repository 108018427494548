import { Box, FormControlLabel, MenuItem, Select, Switch, Typography, Checkbox, TextField, Button } from '@mui/material';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './PharmaSettings.css';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import Loader from '../../../Components/Loader'
import { localGetItem, localSetItem } from '../../../Utility/Services/CacheProviderService';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const DateFilterList = [
  { label: "Last 7 Days", value: "last7days" },
  { label: "Last 30 Days", value: "last30days" },
  // { label: "Last 90 Days", value: "last90days" },
  // {label:"Last 12 Months", value:"last12Months"},
  // {label:"All", value:"all"},
]
const InvoicePrintType = [
  { label: "A4 Portrait", value: "A4 portrait" },
  { label: "A5 Landscape", value: "A5 landscape" }
]

const MonthList = [
  { label: "1 Months", value: 1 },
  { label: "3 Months", value: 3 },
  { label: "6 Months", value: 6 },
]

const rtn_period_1 = ['Days', 'Weeks', 'Months', 'No Return']
const rtn_period_2 = ['Day', 'Week', 'Month', 'No Return']
class PharmaSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drug_individual_discount: false,
      patient_demographics: false,
      drug_brand_name_search: false,
      drug_generic_name_search: false,
      direct_po_available: false,
      saved_bill_restriction: false,
      create_new_drugs: false,
      report_date_config: "last7days",
      show_profit_margin_for_users: false,
      invoice_print_config: "A4 portrait",
      avoid_drugDiscount: { //drug sales discount
        show_discount_ip: false,
        show_discount_op: false,
      },
      return_bill_period: '3',
      show_gst_split_up: false,
      return_bills_condition: false,
      physical_box_no: false,
      hsncode_mandatory_for_billing: false,

      rtnPeriodValue: '',
      rtnPeriodType: 'Months',
      credit_type_selection_for_billing: false,
    }
  }

  componentDidMount() {
    this.GetConfigSettings()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }
  warningMessage = (message) => {
    this.setState({
      isWarningMsg: true,
      isWarningMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  GetConfigSettings() {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.PHARMA_CONFIG_SETTINGS).
        then((response) => {
          if (response.data.status === "succes") {
            var states = this.state
            states.avoid_drugDiscount.show_discount_ip = response?.data?.data?.show_discount_ip;
            states.avoid_drugDiscount.show_discount_op = response?.data?.data?.show_discount_op;
            this.LoaderFunction(false);
            this.setState({
              drug_individual_discount: response?.data?.data?.drug_individual_discount,
              patient_demographics: response?.data?.data?.patient_demographics,
              drug_brand_name_search: response?.data?.data?.drug_brand_name_search,
              drug_generic_name_search: response?.data?.data?.drug_generic_name_search,
              direct_po_available: response?.data?.data?.direct_po_available,
              saved_bill_restriction: response?.data?.data?.saved_bill_restriction,
              create_new_drugs: response?.data?.data?.create_new_drugs,
              report_date_config: response?.data?.data?.report_date_config,
              invoice_print_config: response?.data?.data?.invoice_print_config,
              show_profit_margin_for_users: response?.data?.data?.show_profit_margin_for_users,
              states,
              return_bill_period: response?.data?.data?.return_bill_period ? response?.data?.data?.return_bill_period : '3',
              show_gst_split_up: response?.data?.data?.show_gst_split_up,
              return_bills_condition: response?.data?.data?.return_bills_condition,
              physical_box_no: response?.data?.data?.physical_box_no,
              rtnPeriodType: response?.data?.data?.return_period_type,
              rtnPeriodValue: response?.data?.data?.return_bill_period,
              credit_type_selection_for_billing: response?.data?.data?.credit_type_selection_for_billing,
              hsncode_mandatory_for_billing: response?.data?.data?.hsncode_mandatory_for_billing,
            }, () => { 
              let data = {
                rtn_type:response.data.data?.return_period_type,
                rtn_value:response.data.data?.return_bill_period
              }
              localSetItem('pharmacy_rtn_period',JSON.stringify(data))
              this.LoaderFunction(false) })
          }
          else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.LoaderFunction(false);
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  updateSettings = (key, value, label) => {
    try {
      var data = {}
      if (key === 'avoid_drugDiscount') {
        if (label === "show_discount_ip") {
          data = { "show_discount_ip": value.show_discount_ip, }
        } else if (label === "show_discount_op") {
          data = { "show_discount_op": value.show_discount_op, }
        }
      } else {
        if (key) {
          data[key] = value
        } else {
          data['return_period_type'] = this.state.rtnPeriodType
          data['return_bill_period'] = +this.state.rtnPeriodValue
        }
      }
      let rtnPeriod = {
        rtn_value:+this.state.rtnPeriodValue,
        rtn_type:this.state.rtnPeriodType
      }
      RestAPIService.create(data, Serviceurls.PHARMA_CONFIG_SETTINGS).
        then((response) => {
          if (response.data.status === "success") {
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
          localSetItem('pharmacy_rtn_period',JSON.stringify(rtnPeriod))
        }).catch(error => {
          if (error?.response?.data?.status === "fail" && error?.response?.data?.message === "Drug already exists") {
            this.setState({
              allowForceUpdate: true
            }, () => {
              this.LoaderFunction(false)
              this.warningMessage(error.response.data.message)
            })
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  renderDropdown = (List, key) => {
    let states = this.state
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    let pharmacy_type = UserData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy";
    return (
      <Box component={'div'} display={'flex'} alignItems={'center'}>
        {key === "return_bill_period" ? <Typography mr={'.5vw'}>{'Up to '}</Typography> : null}
        <Select
          disabled={!CheckAccessFunc(pharmacy_type, "Settings", "Settings", null, "Tab")?.editAccess}
          className="eMed_pharma_settings_option_action"
          emed_tid={`sett_${key}`}
          sx={{ width: '10vw', marginRight: '1vw', height: '2vw', }}
          size='small'
          inputProps={{
            sx: {
              textAlign: 'center',
            }
          }}
          value={states[key]}
          onChange={(e) => {
            states[key] = e.target.value
            this.setState({ states }, () => { this.updateSettings(key, states[key]) })
          }}
        >
          {List?.length > 0 ? List.map((item, index) => (
            <MenuItem emed_tid={`sett_list_${item.value}`} key={index} value={item.value} >{item.label}</MenuItem>
          )) : null}
        </Select>
      </Box>
    )
  }

  handleCheckBox = (e, key, label) => {
    try {
      let states = this.state[key]
      states[label] = e.target.checked
      this.setState({ states }, () => {
        this.updateSettings(key, states, label)
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderCheckBox = (labels, key) => {
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    let pharmacy_type = UserData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy";
    let stateKey = this.state[key];
    return (
      <Box component={'div'}>
        {labels.length > 0 ? labels?.map((item, i) => {
          return (
            <FormControlLabel key={i}
              className='eMed_CrdPop_CheckBoxDiv'
              disabled={!CheckAccessFunc(pharmacy_type, "Settings", "Settings", null, "Tab")?.editAccess}
              control={<Checkbox emed_tid={`${item}_checkbox`}
                checked={stateKey[item]}
                onChange={(e) => { this.handleCheckBox(e, key, item) }}
                size='small' />}
              label={item === 'show_discount_ip' ? 'IP' : item === 'show_discount_op' ? 'OP' : ''} />
          )
        }) : null}
      </Box>
    )
  }

  onChangeHandler = (e) => {
    try {
      let value = e.target.value
      let states = this.state
      if (states.rtnPeriodType === 'Day' || states.rtnPeriodType === 'Days') {
        if (CommonValidation.numberOnly(value) && (value > 0 && value <= 31) || value === '') {
          this.setState({ rtnPeriodValue: value })
        }
      } else if (states.rtnPeriodType === 'Week' || states.rtnPeriodType === 'Weeks') {
        if (CommonValidation.numberOnly(value) && (value > 0 && value <= 26) || value === '') {
          this.setState({ rtnPeriodValue: value })
        }
      } else if (states.rtnPeriodType === 'Month' || states.rtnPeriodType === 'Months') {
        if (CommonValidation.numberOnly(value) && (value > 0 && value <= 6) || value === '') {
          this.setState({ rtnPeriodValue: value })
        }
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderNewPeriod = (key) => {
    try {
      let states = this.state
      var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
      var UserData = myUser
      let pharmacy_type = UserData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy";
      return (
        <Box component={'div'} display={'flex'} alignItems={'center'}>
          {key === "return_bill_period" ? <Typography mr={'.5vw'}>{'Up to '}</Typography> : null}
          <Box component={'div'}>
            <TextField
              size='small'
              sx={{ width: '5vw' }}
              disabled={!CheckAccessFunc(pharmacy_type, "Settings", "Settings", null, "Tab")?.editAccess || this.state.rtnPeriodType === 'No Return'}
              inputProps={{ style: { height: '2vh' } }}
              value={this.state.rtnPeriodValue}
              onChange={(e) => { this.onChangeHandler(e) }}
              autoComplete='off'
            />
            <Select
              disabled={!CheckAccessFunc(pharmacy_type, "Settings", "Settings", null, "Tab")?.editAccess}
              className="eMed_pharma_settings_option_action"
              emed_tid={`sett_${key}`}
              sx={{ width: '8vw', height: '2vw', }}
              size='small'
              inputProps={{
                sx: {
                  textAlign: 'center',
                }
              }}
              value={this.state.rtnPeriodType}
              onChange={(e) => this.setState({ rtnPeriodType: e.target.value }, () => {
                this.setState({ rtnPeriodValue: '' })
              })}
              autoComplete='off'
            >
              {rtn_period_1?.map((item, index) => (
                <MenuItem key={index} value={item}>{item}</MenuItem>
              ))}
            </Select>
            <Button size='small' className='Common_Btn_Min_Width'
              sx={{ mr: '1vw' }}
              onClick={() => this.updateSettings()}
            ><CheckCircleIcon color='primary' /></Button>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderSettingOption = (label, key) => {
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    let pharmacy_type = UserData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy";
    try {
      let states = this.state
      return (
        <Box className="eMed_pharma_settings_option_Main">
          <Typography>{label}</Typography>
          {
            key === "report_date_config" ? this.renderDropdown(DateFilterList, key) :
              key === "invoice_print_config" ? this.renderDropdown(InvoicePrintType, key) :
                key === "avoid_drugDiscount" ? this.renderCheckBox(["show_discount_ip", "show_discount_op"], key) :
                  //  key === "return_bill_period" ? this.renderDropdown(MonthList, key) : 
                  key === "return_bill_period" ? this.renderNewPeriod(key) :
                    <Box className="eMed_pharma_settings_option_action">
                      <label style={{ paddingRight: '0.8vw', paddingTop: '0.1vw' }}>No</label>
                      <FormControlLabel
                        value="end"
                        emed_tid={`set_${key}`}
                        control={
                          <Switch
                            disabled={!CheckAccessFunc(pharmacy_type, "Settings", "Settings", null, "Tab")?.editAccess}
                            size='small'
                            color="primary"
                            checked={states[key]}
                            onChange={(e) => {
                              states[key] = e.target.checked
                              this.setState({ states }, () => { this.updateSettings(key, states[key]) })
                            }} />}
                        label="Yes"
                        labelPlacement="end"
                      />
                    </Box>}
        </Box>
      )
    } catch (e) {

    }
  }
  render() {
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    return (
      <Box className="eMed_pharma_settings_Main">
        {/* {this.renderSettingOption("Discount % Editable access ?", "drug_individual_discount")} */}
        {this.renderSettingOption(" Billing Search Using Brand Name ?", "drug_brand_name_search")}
        {this.renderSettingOption(" Billing Search Using Generic Name ?", "drug_generic_name_search")}
        {/* {this.renderSettingOption(" Allow Only Credit Bills or Only Paid Bills to Return ?", "return_bills_condition")} */}
        {UserData?.pharmacy_type === 'Standalone Pharmacy' ? this.renderSettingOption("Can add patient Demographics  ?", "patient_demographics") : null}
        {/* {this.renderSettingOption(" Can Show Direct Po Button ?", "direct_po_available")} */}
        {/* {this.renderSettingOption(" User can View Reports and Invoices Upto ?", "report_date_config")} */}
        {/* {this.renderSettingOption(" User Can Edit & Delete the Saved Bills ?", "saved_bill_restriction")}
        {this.renderSettingOption(" Can User Create New Drugs  ?", "create_new_drugs")} */}
        {/* {this.renderSettingOption(" Invoice Print Orientation ?", "invoice_print_config")} */}
        {/* {this.renderSettingOption(" User can View Margin Profit ?", "show_profit_margin_for_users")} */}
        {UserData?.pharmacy_type === 'Standalone Pharmacy' ? null : this.renderSettingOption(" Avoid Drug Sales Discount ?", "avoid_drugDiscount")}
        {this.renderSettingOption(" Return Bill Period Allowed For ", "return_bill_period")}
        {this.renderSettingOption(" GST Splitup in Invoice Print ?", "show_gst_split_up")}
        {this.renderSettingOption(" Is Physical Box No required in Bill Print ?", "physical_box_no")}
        {this.renderSettingOption(" Credit Type Selection Required During the Billing Process ?", "credit_type_selection_for_billing")}
        {this.renderSettingOption(" Is HSN code mandatory for billing?", "hsncode_mandatory_for_billing")}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
export default withTranslation()(PharmaSettings)
