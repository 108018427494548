import {
    Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem,
    Modal, Select, TextField, Typography, Tabs, Tab, Stack, Switch, FormGroup, Dialog, DialogTitle, DialogContent, DialogActions, colors
} from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { ImagePaths } from '../../../Utility/ImagePaths'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { PhotoCamera } from '@material-ui/icons'
import CancelIcon from '@mui/icons-material/Cancel';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Webcam from 'react-webcam'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { DeletePopup, ReasonPopup } from '../../../Components/Common Components/CommonComponents'
import { MODULE_LIST_DATA } from '../../../Utility/Constants'
import { Colors } from '../../../Styles/Colors'
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'
import Loader from '../../../Components/Loader';
import { Permission_Access } from '../../../Utility/Constants'
import CloseIcon from '@mui/icons-material/Close';

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300,
            width: 100,
        },
    },
};

let ModuleLayout = JSON.stringify(MODULE_LIST_DATA)
var BranchName = null;
var BranchId = null;
let UserData = null;
class CommonUserAccess extends Component {

    constructor(props) {
        super(props)
        this.webcamRef = React.createRef();
        let userList = this.props.history?.location?.state?.userData
        let isEdit = this.props.history?.location?.state?.isEdit
        let showEdit = this.props.history?.location?.state?.showEdit
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            passwords: "",
            mobileNumber: "",
            userName: "",
            clinicName: "",
            role: "User",
            module: [],
            moduleAndRoleList: [],
            profile: "",
            onSelectModules: false,
            temp_patient_img: null,
            userRoleData: [],
            userModuleData: [],
            AfterPostUserModule: [],
            selectedModule: "",
            showPassword: false,
            deletePopUp: false,
            moduleList: JSON.parse(ModuleLayout),
            onClickEdit: showEdit,
            loginId: userList?.login_id,
            onClickEditUser: false,
            isEdit: isEdit,
            userDataList: userList,
            isUserLock: false,
            isUserInactive: false,
            tabDetail: "one",
            is_OPCredit: false,
            is_IPCredit: false,
            is_LabCredit: false,
            per_Access: Permission_Access,
            isFromPharmacy: props.fromPharmacy ? props.fromPharmacy : false,
            is_Reset_Pass: false,
            showResetPass: false,
            showResetNew: false,
            showResetConfirm: false,
            Module_List: [],
            Oldpasswords: "",
            Newpasswords: "",
            Confirmpasswords: "",
            full_Name: '',
            regNO: '',
            user_Notes: '',
            dupModuleList: []
        }
    }

    componentDidMount() {
        UserData = JSON.parse(localGetItem("loggedInUserInfo"))

        try {
            ModuleLayout = JSON.parse(JSON.stringify(MODULE_LIST_DATA))
            let LogModuleList = JSON.parse(localGetItem("ModulesList"))?.find((item) => item?.branch_id === JSON.parse(localGetItem("BranchId")))

            // Extract Pharmacy and Laboratory data
            let pharmaData = ModuleLayout?.find((item) => item?.title === "Pharmacy")
            let labData = ModuleLayout?.find((item) => item?.title === "Laboratory")

            // Filter out Pharmacy and Laboratory from the list
            let FilteredModule = ModuleLayout?.filter((list) => (list?.title !== "Pharmacy" && list?.title !== "Laboratory"))

            //Update the titles for front_office and Radiology
            LogModuleList?.data?.forEach((item) => {
                if (item?.moduels === "front_office") {
                    FilteredModule[0]["title"] = item?.label
                } else if (item?.moduels === "Radiology") {
                    FilteredModule[2]["title"] = item?.label
                }
            })

            if (this.state.isFromPharmacy) { // Add current pharmacy data
                let pharmacy_data = JSON.parse(JSON.stringify(pharmaData))
                pharmacy_data['title'] = UserData?.pharmacy_name
                FilteredModule.push(pharmacy_data)

            } else { // Add multi Pharmacy and multi Laboratory
                let LabList = JSON.parse(localGetItem("loggedInUserInfo"))?.laboratory_data
                let PharmacyList = JSON.parse(localGetItem("loggedInUserInfo"))?.pharmacy_data
                LabList?.forEach((item) => {
                    let laboratory_data = JSON.parse(JSON.stringify(labData))
                    laboratory_data['title'] = item?.module_name
                    FilteredModule.push(laboratory_data)
                })
                PharmacyList?.forEach((item) => {
                    let pharmacy_data = JSON.parse(JSON.stringify(pharmaData))
                    pharmacy_data['title'] = item?.module_name
                    FilteredModule.push(pharmacy_data)
                })
            }
            ModuleLayout = JSON.stringify(FilteredModule)
            this.setState({ moduleList: JSON.parse(ModuleLayout) })
        } catch (e) {
            this.errorMessgae(e?.message)
        }

        if (this.state.loginId) {
            this.LoaderFunction(true)
            this.getUserAccessData()
        }
        this.getUserAccessModuleData()
        BranchName = localGetItem("BranchName")
        BranchId = localGetItem("BranchId")
    }

    errorMessgae = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessgae = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        let alpha = CommonValidation.alphabetOnly(value)
        let num = CommonValidation.numberOnly(value)
        let PasswordInput = CommonValidation.PasswordInput(value)
        let OnlyAlphaNum = CommonValidation.gstnumberValidation(value)
        switch (name) {
            case 'firstName':
                if ((alpha && value.length < 30) || value === "") {
                    this.setState({
                        firstName: value
                    })
                }
                break;
            case 'lastName':
                if ((alpha && value.length < 30) || value === "") {
                    this.setState({
                        lastName: value
                    })
                }
                break;
            case 'mobileNumber':
                if ((num && value.length <= 10) || value === "") {
                    this.setState({
                        mobileNumber: value
                    })
                }
                break;
            case 'userName':
                if ((OnlyAlphaNum && value.length <= 30) || value === "") {
                    this.setState({
                        userName: value
                    })
                }
                break;
            case 'passwords':
                if ((PasswordInput && value.length <= 14) || value === "") {
                    this.setState({
                        passwords: value
                    })
                }
                break;
            case 'selectedModule':
                this.setState({
                    selectedModule: value
                }, () => { this.setState({ onSelectModules: true }) })
                break;
            default:
                this.setState({
                    [name]: value
                })
                break;
        }
    }

    inputTextBox(label, value, name) {
        const { t } = this.props
        return (
            <TextField
                size='small'
                label={`${label} *`}
                variant='standard'
                name={name}
                value={value}
                className='eMed_input_text_box_UI'
                onChange={(e) => this.onChangeHandler(e)}
                autoComplete='off'
            />
        )
    }

    UploadImage = (e, key) => {
        let states = this.state
        var files = e.target.files[0]
        var url = ""
        this.getBase64(files, (result) => {
            states[key] = result
            this.setState({ states }, () => {
                this.setState({
                    open: false
                })
            })
        });
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    onClearHandler = () => {
        this.setState({
            module: [],
        })
    }

    returnLabel(ModuleName) {
        switch (ModuleName) {
            case "front_office": return "Front office"
            case "Laboratory": return "Laboratory"
            case "Pharmacy": return "Pharmacy"
            case "Radiology": return "Radiology"
            default: return ""
        }
    }


    OpenWebCamPopUP = () => {
        try {
            return (
                <Box>
                    <Modal open={true}>
                        <Box className="eMed_WebCamPop_Main">
                            <Box component={'div'} className="eMed_WebCam_Header">
                                <Typography variant='h6'>{"Patient Profile "}</Typography>
                                <Button onClick={() => { this.setState({ temp_patient_img: this.state.patient_img, webCamOpen: false }) }} >
                                    <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                                </Button>
                            </Box>
                            <Box component={'div'}>
                                {this.state.temp_patient_img === null ?
                                    <Webcam
                                        className='eMed_WebCam_Div'
                                        audio={false}
                                        mirrored={true}
                                        ref={this.webcamRef}
                                        screenshotFormat="image/jpeg"
                                    /> :
                                    <img className='eMed_WebCam_Img_Div' src={this.state.temp_patient_img} />}
                            </Box>
                            <Box component={'div'} className="eMed_WebCam_Btn_div">
                                <Button disabled={this.state.temp_patient_img !== null} variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ temp_patient_img: this.webcamRef.current.getScreenshot() }) }}>Capture</Button>
                                <Button variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ temp_patient_img: null }) }}>Retake</Button>
                                <Button disabled={this.state.temp_patient_img === null} variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ profile: this.state.temp_patient_img, webCamOpen: false }) }}>Set Profile</Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            );
        } catch (error) {
            this.errorMessgae(error.message)
        }
    };

    getUserAccessData = () => {
        try {
            RestAPIService.getAll(Serviceurls.USER_ACCESS_CONFIGURATION + "?login_id=" + this.state.loginId).
                then((response) => {
                    if (response.data.status === "success") {
                        this.AssignUserData(response.data.data[0])
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessgae(error.response.data.message)
                    } else {
                        this.errorMessgae(error.message)
                    }
                })
        } catch (error) {
            this.errorMessgae(error.message)
        }
    }

    AssignPermissionAccess(data) {
        return {
            op: {
                is_credit: data?.op?.is_credit ? data?.op?.is_credit : false,
                is_pov_editable: data?.op?.is_pov_editable ? data?.op?.is_pov_editable : false,
            },
            ip: {
                is_credit: data?.ip?.is_credit ? data?.ip?.is_credit : false,
            },
            lab: {
                is_credit: data?.lab?.is_credit ? data?.lab?.is_credit : false,
            },
            pharma: {
                is_credit: data?.pharma?.is_credit ? data?.pharma?.is_credit : false,
                isShowPo: data?.pharma?.isShowPo ? data?.pharma?.isShowPo : false,
                isDiscountEdit: data?.pharma?.isDiscountEdit ? data?.pharma?.isDiscountEdit : false,
                isViewRpt: data?.pharma?.isViewRpt ? data?.pharma?.isViewRpt : false,
                isEditSave: data?.pharma?.isEditSave ? data?.pharma?.isEditSave : false,
                isCrtDrug: data?.pharma?.isCrtDrug ? data?.pharma?.isCrtDrug : false,
                isApprover: data?.pharma?.isApprover ? data?.pharma?.isApprover : false,
                isViewMargin: data?.pharma?.isViewMargin ? data?.pharma?.isViewMargin : false,
                isReturnBill: data?.pharma?.isReturnBill,
                isGRNEntry: data?.pharma?.isGRNEntry ? data?.pharma?.isGRNEntry : false ,
            },
            common: {
                change_payment_mode: data?.common?.change_payment_mode ? data?.common?.change_payment_mode : false,
                modify_credit_bill: data?.common?.modify_credit_bill ? data?.common?.modify_credit_bill : false,
                cancel_bill_approval: data?.common?.cancel_bill_approval ? data?.common?.cancel_bill_approval : false,
            }
        }
    }

    AssignUserData(Data) {
        let filteredList = Data?.userlinked_modules?.filter(item => (item.moduels === "Pharmacy" || item.moduels === "Standalone Pharmacy"));
        this.setState({
            firstName: Data?.login_fname,
            lastName: Data?.login_lname,
            email: Data?.login_email_address,
            passwords: "",
            mobileNumber: Data?.login_phone_number,
            userName: Data?.login_name,
            clinicName: Data?.clinic_name,
            role: "User",
            moduleAndRoleList: this.state.isFromPharmacy ? filteredList : Data?.userlinked_modules,
            profile: Data?.user_image,
            moduleList: Data?.clinic_access,
            isUserInactive: Data?.is_deactivated,
            isUserLock: Data?.login_count >= 6 ? true : false,
            per_Access: Data?.permission_access != "" ? this.AssignPermissionAccess(Data?.permission_access) : Permission_Access,
            selectedModule: this.state.isFromPharmacy ? UserData?.pharmacy_name : this.state.selectedModule,
            onSelectModules: this.state.isFromPharmacy && filteredList?.length > 0 ? true : false,
            Module_List: Data?.userlinked_modules,
            full_Name: Data?.full_name,
            regNO: Data?.register_no,
            user_Notes: Data?.notes,
            dupModuleList: Data?.clinic_access
        }, () => {
            this.LoaderFunction(false)
            try {
                let moduleLayout = JSON.parse(ModuleLayout)
                let moduleHash = {};
                this.state.moduleList?.forEach(element => {
                    moduleHash[element.title] = {}
                    element?.data?.forEach(list => {
                        moduleHash[element.title][list.title] = {}
                        list?.main_tabs?.forEach(mainTab => {
                            moduleHash[element.title][list.title][mainTab.title] = {}
                            mainTab?.sub_tabs?.forEach(subTabs => {
                                moduleHash[element.title][list.title][mainTab.title][subTabs.title] = subTabs
                            })
                        })
                    })
                })
                moduleLayout?.forEach((element, eleIndex) => {
                    if (!moduleHash[element.title]) {
                        this.state.dupModuleList?.push(element)
                    } else {
                        element?.data?.forEach((newList, listIndex) => {
                            if (!moduleHash[element.title][newList?.title]) {
                                this.state.dupModuleList[eleIndex]['data']?.push(newList)
                            } else {
                                newList?.main_tabs?.forEach((newMainTab, mainIndex) => {
                                    if (!moduleHash[element.title][newList?.title][newMainTab?.title] && this.state.dupModuleList[eleIndex]['data'][listIndex]) {
                                        this.state.dupModuleList[eleIndex]['data'][listIndex]['main_tabs']?.push(newMainTab)
                                    } else {
                                        newMainTab?.sub_tabs?.forEach((newSubTab, subIndex) => {
                                            if (!moduleHash[element.title][newList?.title][newMainTab?.title][newSubTab?.title] && this.state.dupModuleList[eleIndex]['data'][listIndex]) {
                                                this.state.dupModuleList[eleIndex]['data'][listIndex]['main_tabs'][mainIndex]['sub_tabs']?.push(newSubTab)
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
                this.setState({ moduleList: this.state.dupModuleList })
            } catch (error) {
                this.errorMessgae(error.message)
            }
        })
    }

    getUserAccessModuleData = () => {
        try {
            RestAPIService.getAll(Serviceurls.USER_ACCESS_GET_MODULES).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            userModuleData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessgae(error.response.data.message)
                    } else {
                        this.errorMessgae(error.message)
                    }
                })
        } catch (error) {
            this.errorMessgae(error.message)
        }
    }

    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(",")[1]);
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    sendDocuments(key) {
        let states = this.state;
        if (states[key] === "" || states[key] === null) {
            return ""
        }
        else {
            if (states[key]?.length > 500) {
                return this.dataURItoBlob(states[key])
            }
            else {
                return ""
            }
        }
    }

    onClearLeftHandler = () => {
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: "",
            password: "",
            role: "",
            module: [],
            userName: "",
            moduleAndRoleList: [],
            clinicName: "",
            profile: null
        })
    }

    popupClose = () => {
        this.setState({ deletePopUp: false })
    }

    PostUserStatus = () => {
        const { t } = this.props
        try {
            this.LoaderFunction(true)
            this.setState({disableBtn: true})
            let data = {
                "login_id": this.state.loginId,
                "active_status": !this.state.isUserLock
            }
            RestAPIService.create(data, Serviceurls.FO_CONFIG_USER_CONFIGURATION).
                then((response) => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false)
                        this.successMessgae(response.data.message)
                        this.getUserAccessData()
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.setState({disableBtn: false})
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessgae(error.response.data.message)
                    } else {
                        this.errorMessgae(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessgae(error.message)
            this.setState({disableBtn: false})
        }
    }

    UserDeleteFunc = () => {
        try {
            let data = {
                "login_id": this.state.loginId,
                "active_status": this.state.isUserInactive
            }
            RestAPIService.create(data, Serviceurls.USER_ACCESS_GET_LIST)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({ deletePopUp: false })
                        this.getUserAccessData()
                        this.successMessgae(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessgae(error.response.data.message)
                    } else {
                        this.errorMessgae(error.message)
                    }
                })
        } catch (error) {
            this.errorMessgae(error.message)
        }

    }

    postNewUserAccessData = () => {
        try {
            let moduleJson = JSON.stringify(this.state.moduleList)
            let permission = JSON.stringify(this.state.per_Access)
            let List_module = (UserData?.pharmacy_type === 'Inhouse Pharmacy' && this.state.isEdit) ? JSON.stringify(this.state.Module_List) : JSON.stringify(this.state.moduleAndRoleList)
            const dataForm = new FormData();
            dataForm.append("fname", CommonValidation.removeSpace(this.state.firstName))
            dataForm.append("lname", CommonValidation.removeSpace(this.state.lastName))
            dataForm.append("mail_id", this.state.email)
            dataForm.append("mobile_number", this.state.mobileNumber)
            dataForm.append("user_name", this.state.userName)
            dataForm.append("password", this.state.passwords === "" ? "None" : this.state.passwords)
            dataForm.append("user_image", (this.state.profile === null || this.state.profile === "" || this.state.profile.includes("https://s3-ap-south-1.amazonaws.com")) ? "None" : this.sendDocuments("profile"))
            dataForm.append("userlinked_module", List_module)
            dataForm.append("clinic_access", moduleJson)
            dataForm.append("branch_id", BranchId)
            dataForm.append("permission_access", permission)
            dataForm.append("register_no", this.state.regNO ? this.state.regNO : '')
            dataForm.append("notes", this.state.user_Notes ? this.state.user_Notes : '')
            if (this.state.loginId) {
                dataForm.append("login_id", this.state.loginId)
            }

            if (this.state.firstName === "" || this.state.lastName === "" || this.state.email === ""
                || this.state.mobileNumber === "" || this.state.userName === "") {
                this.errorMessgae("Enter All Mandatory Fields")
            } else if (this.state.moduleAndRoleList.length === 0) {
                this.errorMessgae("Select Modules for the User")
            } else if (!CommonValidation.emailValidation(this.state.email)) {
                this.errorMessgae("Invalid E-Mail")
            } else if (this.state.isEdit === true) {
                if (this.state.passwords === "") {
                    this.postAPICall(dataForm)
                }
                else if (!CommonValidation.checkPassword(this.state.passwords)) {
                    this.errorMessgae("Password should be 6 characters, one UpperCase,Special Characters,LowerCase")
                } else if (CommonValidation.checkPassword(this.state.passwords)) {
                    this.postAPICall(dataForm)
                }
            } else if (this.state.isEdit === undefined) {
                if (this.state.passwords !== "" && CommonValidation.checkPassword(this.state.passwords)) {
                    this.postAPICall(dataForm)
                }
                else {
                    this.errorMessgae("Password should be 6 characters, one UpperCase,Special Characters,LowerCase")
                }
            }
        } catch (error) {
            this.errorMessgae(error.message)
        }
    }

    postAPICall(dataForm) {
        try {
            this.LoaderFunction(true)
            this.setState({disableBtn: true})
            RestAPIService.create(dataForm, Serviceurls.USER_ACCESS_CONFIGURATION).
                then((response) => {
                    if (response.data.status === "success") {
                        if (this.state.loginId === undefined) {
                            this.setState({
                                isEdit: true,
                                onClickEdit: false,
                                loginId: response.data?.user_id
                            }, () => { this.getUserAccessData() })
                        }
                        this.LoaderFunction(false)
                        this.successMessgae(response.data.message)
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessgae(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessgae(error.message)
                    }
                    this.setState({disableBtn: false})
                })
        }
        catch (e) {
            this.errorMessgae(e.message)
            this.setState({disableBtn: false})
        }
    }


    onChangeHandleCheck = (e) => {
        const { name, checked } = e.target;
        this.state.moduleList.map((user => (
            (user.title === this.state.selectedModule) ?
                user.data.map((main) => {
                    if (main.title === name) {
                        main.viewAccess = checked
                        if (main.viewAccess === false) { main.editAccess = false }
                        // if (main.main_tabs.every( item => item['viewAccess'] === true  ) === false ) { main.viewAccess = false }

                    }
                    main.main_tabs.map((mainTab) => {
                        if (main.title === name && (main.title !== 'Settings' || (mainTab.title !== 'User Access' && mainTab.title !== 'Sub-Location' && mainTab.title !== 'Sub-Location Access')) && (main.title !== 'Settings' || (mainTab.title !== 'User Access Configuration' && mainTab.title !== 'User Configuration'))) {
                            mainTab.viewAccess = checked
                            if (mainTab.viewAccess === false) { mainTab.editAccess = false }
                            if (mainTab.sub_tabs?.length > 0) {
                                mainTab.sub_tabs.map((subTabs) => {
                                    if (mainTab.viewAccess) {
                                        subTabs.viewAccess = checked
                                    }
                                    else {
                                        subTabs.viewAccess = false
                                        subTabs.editAccess = false
                                    }
                                })
                            }
                        }
                    })
                }) : ""
        )))
        this.setState({ moduleList: this.state.moduleList })
    }

    onChangeHandleCheckMain = (e) => {
        const { name, checked } = e.target;
        this.state.moduleList.map((user => (
            (user.title === this.state.selectedModule) ?
                user.data.map((main) => {
                    main.main_tabs.map((mainTab) => {
                        if (mainTab.title === name && main.viewAccess) {
                            mainTab.viewAccess = checked
                            if (checked === false) { mainTab.editAccess = false }
                            if (mainTab.sub_tabs?.length > 0) {
                                mainTab.sub_tabs.map((subTabs) => {
                                    if (mainTab.viewAccess) {
                                        subTabs.viewAccess = checked
                                    }
                                    else {
                                        subTabs.viewAccess = false
                                        subTabs.editAccess = false
                                    }
                                })
                            }
                            if (main.main_tabs.every(item => item['viewAccess'] === false)) {
                                main.viewAccess = false
                                main.editAccess = false
                            }
                        }
                    })

                }) : ""
        )))
        this.setState({ moduleList: this.state.moduleList })
    }

    onChangeHandleCheckSub = (e) => {
        const { name, checked } = e.target;
        this.state.moduleList.map((user => (
            (user.title === this.state.selectedModule) ?
                user.data.map((main) => (
                    main.main_tabs.map((mainTab) => {
                        if (mainTab.sub_tabs) {
                            mainTab.sub_tabs.map((subTab) => {
                                if (subTab.title === name && main.viewAccess) {
                                    subTab.viewAccess = checked
                                    if (checked === false) { subTab.editAccess = false }
                                }
                                if (mainTab.sub_tabs.every(item => item['viewAccess'] === false)) {
                                    mainTab.viewAccess = false
                                    mainTab.editAccess = false
                                }
                            })
                        }
                    })
                )) : ""
        )))
        this.setState({ moduleList: this.state.moduleList })
    }

    renderModuleList = () => {
        return (
            <><Box component={'div'} className='eMed_mod_card'>
                {this.state.moduleList.map((item) => (
                    item.title === this.state.selectedModule ?
                        <Box>
                            {item.data?.length > 0 ? item.data.map((i, indMain) => (
                                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }} key={indMain}>
                                    <Box display={'flex'} className="eMed_userAccessRow">
                                        <Box flex={0.7} display={'flex'} alignItems={'center'}>
                                            <Box mr={1} position={'relative'} top='0.2vw'>
                                                <img src={i.image ? i.image : ""} className='eMed_list_image' alt='' />
                                            </Box>
                                            <FormControlLabel
                                                label={<Typography fontWeight={600} fontSize={"1vw"}>{i?.title}</Typography>}
                                                value={i?.viewAccess}
                                                name={i?.title}
                                                onChange={(e) => this.onChangeHandleCheck(e)}
                                                control={<Checkbox disabled={i?.title === "Home" || ((item?.module_type === "Pharmacy" || item?.module_type === "Standalone Pharmacy") && i?.title === "Billing")} checked={i?.viewAccess} size='small' />}
                                            />
                                        </Box>
                                        <Box flex={0.3} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} pr={'3vw'}>
                                            {((item?.module_type === "front_office" && i?.title !== "Reports") || (item?.module_type === "Laboratory" && i?.title !== "Reports") || ((item?.module_type === "Pharmacy" || item?.module_type === "Standalone Pharmacy") && i?.title !== "Billing" && i?.title !== "Reports") || (item?.module_type === "Radiology" && i?.title !== "Reports")) ?
                                                <IconButton disabled={!(i?.viewAccess)} onClick={() => {
                                                    i.editAccess = !i.editAccess
                                                    i.main_tabs.map((mainTab) => {
                                                        // if (i.title === name) {
                                                        if (mainTab.viewAccess === false) { mainTab.editAccess = false }
                                                        if (mainTab.viewAccess === true) { mainTab.editAccess = i.editAccess }

                                                        if (mainTab.sub_tabs?.length > 0) {
                                                            mainTab.sub_tabs.map((subTabs) => {
                                                                if (subTabs.viewAccess === true) { subTabs.editAccess = i.editAccess }
                                                            })
                                                        }
                                                        // }
                                                    })
                                                    this.setState({ moduleList: this.state.moduleList })
                                                }}>
                                                    <img src={i?.editAccess ? ImagePaths.UserEditOn.default : ImagePaths.UserEditOff.default} className='eMed_list_image' />
                                                </IconButton>
                                                : null
                                            }
                                        </Box>
                                    </Box>
                                    {i?.main_tabs?.length > 0 ? i.main_tabs.map((tabs, ind) => (
                                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 5 }} key={ind}>
                                            <Box display={'flex'} className="eMed_userAccessRow">
                                                <Box flex={0.7}>
                                                    <FormControlLabel
                                                        label={tabs?.title}
                                                        value={tabs?.viewAccess}
                                                        name={tabs?.title}
                                                        onChange={(e) => this.onChangeHandleCheckMain(e)}
                                                        control={<Checkbox disabled={tabs?.title === "OP Appointments" || (item?.module_type === "Laboratory" && tabs?.title === "Create Bill") || (item?.module_type === 'front_office' && i?.title === 'Settings' && (tabs.title === 'User Access Configuration' || tabs.title === 'User Configuration')) || ((item?.module_type === "Pharmacy" || item?.module_type === "Standalone Pharmacy") && i?.title === "Settings" && (tabs.title === 'User Access' || tabs.title === 'Sub-Location' || tabs.title === 'Sub-Location Access')) || ((item?.module_type === "Pharmacy" || item?.module_type === "Standalone Pharmacy") && (i.title === "Billing" && tabs.title === 'Billing'))} checked={tabs?.viewAccess} size='small' />}
                                                    />
                                                </Box>
                                                <Box flex={0.3} display={'flex'} alignItems={'center'} pr={'3vw'} justifyContent={'flex-end'}>
                                                    {((item?.module_type === "front_office" && i?.title !== "Reports" && tabs?.title !== "OP Appointments" && (tabs?.title !== "User Access Configuration" && tabs?.title !== "User Configuration")) || (item?.module_type === "Laboratory" && tabs?.title !== "Create Bill" && i?.title !== "Reports") || ((item?.module_type === "Pharmacy" || item?.module_type === "Standalone Pharmacy") && i?.title !== "Reports" && ((tabs.title !== 'User Access' && tabs.title !== 'Sub-Location' && tabs.title !== 'Sub-Location Access'))) || (item?.module_type === "Radiology" && tabs?.title !== "Create Bill" && i?.title !== "Reports")) ?
                                                        <IconButton disabled={!(tabs?.viewAccess)} onClick={() => {
                                                            tabs.editAccess = !tabs.editAccess
                                                            if (tabs.sub_tabs?.length > 0) {
                                                                tabs.sub_tabs.map((subTabs) => {
                                                                    if (subTabs.viewAccess === true) { subTabs.editAccess = tabs.editAccess }
                                                                })
                                                            }
                                                            this.setState({ moduleList: this.state.moduleList })
                                                        }}>
                                                            <img src={tabs?.editAccess ? ImagePaths.UserEditOn.default : ImagePaths.UserEditOff.default} className='eMed_list_image' />
                                                        </IconButton>
                                                        : null}
                                                </Box>
                                            </Box>
                                            {tabs?.sub_tabs?.length > 0 ? tabs?.sub_tabs?.map((subTabs, index) => (
                                                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 5 }} key={index}>
                                                    <Box display={'flex'} className="eMed_userAccessRow">
                                                        <Box flex={0.7}>
                                                            <FormControlLabel
                                                                label={subTabs?.title}
                                                                value={subTabs?.viewAccess}
                                                                name={subTabs?.title}
                                                                onChange={(e) => this.onChangeHandleCheckSub(e)}
                                                                control={<Checkbox disabled={item?.module_type === 'front_office' && i?.title === 'Settings' && (tabs.title === 'User Access Configuration' && subTabs.title === 'User Access') || (tabs.title === 'User Configuration' && subTabs.title === 'User Configuration')} checked={subTabs?.viewAccess} size='small' />}
                                                            />
                                                        </Box>
                                                        <Box flex={0.3} display={'flex'} alignItems={'center'} pr={'3vw'} justifyContent={'flex-end'}>
                                                            {(i?.title !== "Reports" && ((tabs?.title !== "User Access Configuration" && tabs?.title !== "User Configuration"))) ?
                                                                <IconButton disabled={!(subTabs?.viewAccess)} onClick={() => {
                                                                    subTabs.editAccess = !subTabs.editAccess
                                                                    this.setState({ moduleList: this.state.moduleList })
                                                                }}>
                                                                    <img src={subTabs?.editAccess ? ImagePaths.UserEditOn.default : ImagePaths.UserEditOff.default} className='eMed_list_image' />
                                                                </IconButton> : null}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )) : null
                                            }
                                        </Box>
                                    )) : null}
                                </Box>
                            )) : null}
                        </Box>
                        : ""
                ))}

            </Box >
                <Box display={'flex'}
                    justifyContent={'center'} alignItems={'center'}
                    height={"3.1vw"} sx={{ backgroundColor: Colors.Background }}>
                    <Box>
                        <Button
                            size='small'
                            variant='outlined'
                            sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                            onClick={() => { this.setState({ moduleList: JSON.parse(ModuleLayout) }) }}
                        >Reset</Button>
                        <Button
                            size='small'
                            variant='contained'
                            emed_tid='UserAccess_Edit_RightSave_Btn'
                            disabled={this.state.disableBtn}
                            sx={{ textTransform: "capitalize" }}
                            onClick={() => this.postNewUserAccessData()}
                        >Save</Button>
                    </Box>
                </Box></>
        )
    }

    handleCheckBox = (e, type, billtype) => {
        let states = this.state
        states.per_Access[type][billtype] = e.target.checked
        this.setState({ states })
    }

    handlechange = (e) => {
        let name = e.target.name;
        let value = e.target.value.trimStart();
        let isValid = CommonValidation.numberOnly(value);
        let states = this.state;
        switch (name) {
            case "opEditDay":
                if (this.state.opEditDur && (isValid && this.state.opEditDur === 1 ? value <= 45 : value <= 24 || value === "")) {
                    states["opEditDay"] = +value;
                }
                break;
            case "ipEditDay":
                if (this.state.ipEditDur && (isValid && this.state.ipEditDur === 1 ? value <= 45 : value <= 24 || value === "")) {
                    states["ipEditDay"] = +value;
                }
                break;
            case "labEditDay":
                if (this.state.labEditDur && (isValid && this.state.labEditDur === 1 ? value <= 45 : value <= 24 || value === "")) {
                    states["labEditDay"] = +value;
                }
                break;
            case "pharmaEditDay":
                if (this.state.pharmaEditDur && (isValid && this.state.pharmaEditDur === 1 ? value <= 45 : value <= 24 || value === "")) {
                    states["pharmaEditDay"] = +value;
                }
                break;
            default:
                break;
        }
        this.setState({
            states
        })
    }
    renderPermissionList = (billtype, type, isday, label, value, statekey1, value1, statekey2, value2, subTxt, isViewRpt = false) => {
        const { t } = this.props;
        let states = this.state;
        const duration = [
            { label: "Day(s)", value: 1 },
            { label: "Hour(s)", value: 2 }
        ];

        const DateFilterList = [
            { label: "Last 7 Days", value: "last7days" },
            { label: "Last 30 Days", value: "last30days" },
        ]
        return (
            <Stack direction="row" justifyContent="space-Between" alignItems="center" sx={{ height: "4.5vw" }}>
                <Stack direction="column" justifyContent={"center"}>
                    <FormControlLabel control={<Checkbox size="small" checked={value} onChange={(e) => { this.handleCheckBox(e, type, billtype) }} />}
                        label={<Typography id="eMed_permission_checkTxt"> {t(label)} </Typography>} />
                    <Typography id="eMed_permission_Txt">{isday ? `${t("modifyAccessTxt")}` : subTxt ? `${t(subTxt)}` : `${t('modAccTxt1')}`}</Typography>
                </Stack>
                {isday ?
                    <Stack direction="column" justifyContent={"center"} mt={1.5}>
                        <Typography id="eMed_permission_TimeTxt" >{`${t("timeLimit")}`}</Typography>
                        <Stack direction={"row"}>
                            <TextField
                                sx={{ width: "6vw", fontSize: "0.9vw" }}
                                size='small'
                                autoComplete="off"
                                className='eMed_temp_txt'
                                name={statekey1}
                                value={value1 ? value1 : ""}
                                onChange={(e) => this.handlechange(e)}
                                inputProps={{ style: { textAlign: 'center' } }}
                            />
                            <FormControl sx={{ width: "6.3vw", backgroundColor: Colors.Background }} size="small">
                                <Select
                                    value={value2 ? value2 : ""}
                                    name={statekey2}
                                    onChange={(event) => {
                                        let states = this.state;
                                        states[statekey2] = event.target.value
                                        this.setState({ states })
                                    }}
                                    sx={{ fontSize: "0.9vw" }}
                                >
                                    {
                                        duration.map((list, index) => (
                                            <MenuItem key={index} value={list.value} sx={{ fontSize: "0.8vw" }} >{list.label}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Stack>
                    </Stack>
                    : isViewRpt ?
                        <>
                            <Select
                                className="eMed_pharma_settings_option_action"
                                emed_tid={`usr_${billtype}`}
                                sx={{ width: '9vw', marginRight: '1vw', fontSize: "0.95vw" }}
                                size='small'
                                value={value}
                                onChange={(e) => {
                                    states.per_Access["pharma"]["isViewRpt"] = e.target.value
                                    this.setState({ states })
                                }}
                            >
                                {DateFilterList?.length > 0 ? DateFilterList.map((item, index) => (
                                    <MenuItem sx={{ fontSize: "0.95vw" }} emed_tid={`usr_${item.value}`} key={index} value={item.value} >{item.label}</MenuItem>
                                )) : null}
                            </Select>
                        </>
                        : null}
            </Stack>
        )
    }

    moduleAccessTab = () => {
        const { t } = this.props;
        return (
            <Box component={'div'}>
                <Box component={'div'} className='eMed_left_header'>
                    <FormControl size='small' className='eMed_select_text_box_ui'>
                        <InputLabel>{t('Module')}</InputLabel>
                        <Select
                            disabled={this.state.moduleAndRoleList.length === 0 || this.state.isUserInactive === true || (this.state.selectedModule && this.state.isFromPharmacy === true)}
                            label={t('Module')}
                            value={this.state.selectedModule}
                            name='selectedModule'
                            onChange={(e) => { this.onChangeHandler(e) }}
                        >
                            {this.state.moduleAndRoleList?.length > 0 ? this.state.moduleAndRoleList?.map((item) => {
                                return <MenuItem value={item?.module_name}>{item?.module_name}</MenuItem>
                            }) : null}
                        </Select>
                    </FormControl>
                </Box>
                {this.state.onSelectModules ?
                    <Box component={'div'}>
                        {this.renderModuleList()}
                    </Box> :
                    <Box component={'div'} className='eMed_left_content'>
                        <Box component={'div'} className='eMed_new_user_img'>
                            <img src={ImagePaths.NewUserImg.default} alt='new User' />
                        </Box>
                        <Box component={'div'} className='eMed_new_user'>
                            <Typography component={'div'} fontWeight={600}>Select any module to continue</Typography>
                            <Typography component={'div'}
                                fontWeight={400}
                                fontSize={'0.8vw'}
                                color={"#616161"}>Kindly Add any module to edit user access</Typography>
                        </Box>
                    </Box>}
            </Box>
        )
    }

    permissionTab = () => {
        const { t } = this.props;
        let states = this.state;
        return (
            <Box>
                <Box component={'div'} className='eMed_permission_box'>
                    <Box component={'div'} className='eMed_permission_hdrBox'>
                        <Typography id='eMed_permission_header' mb={1}>{t("Permission")}</Typography>
                        <Typography id='eMed_permission_subheader'>{t("selectgrant")}</Typography>
                    </Box>
                    <Box component={'div'} className='eMed_permission_card'>
                        {/* {this.renderPermissionList('op', true, `${t("Modify")} ${t("Op")} ${t("Bill")}s`, "isOpBills", states.isOpBills, "opEditDay", states.opEditDay, "opEditDur", states.opEditDur)}
                    {this.renderPermissionList('ip', true, `${t("Modify")} ${t("Ip")} ${t("Bill")}s`, "isIpBills", states.isIpBills, "ipEditDay", states.ipEditDay, "ipEditDur", states.ipEditDur)}
                    {this.renderPermissionList('lab', true, `${t("Modify")} ${t("Laboratory")} ${t("Bill")}s`, "isLabBills", states.isLabBills, "labEditDay", states.labEditDay, "labEditDur", states.labEditDur)}
                    {this.renderPermissionList('pharma', true, `${t("Modify")} ${t("Pharmacy")} ${t("Bill")}s`, "isPharmaBills", states.isPharmaBills, "pharmaEditDay", states.pharmaEditDay, "pharmaEditDur", states.pharmaEditDur)} */}
                        {this.state.isFromPharmacy ? null
                            : <>{this.renderPermissionList('is_credit', 'op', false, "OPCB", states?.per_Access?.op?.is_credit,)}
                                {this.renderPermissionList('is_credit', 'ip', false, "IPCB", states?.per_Access?.ip?.is_credit)}
                                {this.renderPermissionList('is_credit', 'lab', false, "LabCB", states?.per_Access?.lab?.is_credit)}
                                {this.renderPermissionList('modify_credit_bill', 'common', false, "User can modify the Credit bills ?", states?.per_Access?.common?.modify_credit_bill, "", "", "", "", "User can modify the OP, IP, Lab Credit bills ?")}
                                {this.renderPermissionList('cancel_bill_approval', 'common', false, "Cancel access permission", states?.per_Access?.common?.cancel_bill_approval, "", "", "", "", "User can able to cancel bills")}
                                {this.renderPermissionList('is_pov_editable', 'op', false, "Can user edit purpose of visit after appointment", states?.per_Access?.op?.is_pov_editable,"", "", "", "", "User can edit purpose of visit")}
                            </>}
                        {this.renderPermissionList('is_credit', 'pharma', false, "PharmaCB", states?.per_Access?.pharma?.is_credit)}
                        {this.renderPermissionList('isShowPo', 'pharma', false, "showPoBtn", states?.per_Access?.pharma?.isShowPo, "", "", "", "", "userPotxt")}
                        {this.renderPermissionList('isDiscountEdit', 'pharma', false, "discountEditable", states?.per_Access?.pharma?.isDiscountEdit, "", "", "", "", "userDiscountTxt")}
                        {this.renderPermissionList('isViewRpt', 'pharma', false, "viewRptAndInvoice", states?.per_Access?.pharma?.isViewRpt, "", "", "", "", "userViewRptTxt", true)}
                        {this.renderPermissionList('isEditSave', 'pharma', false, "editDltSaveBill", states?.per_Access?.pharma?.isEditSave, "", "", "", "", "modifyAccessTxt")}
                        {this.renderPermissionList('isCrtDrug', 'pharma', false, "createNewDrg", states?.per_Access?.pharma?.isCrtDrug, "", "", "", "", "userNewDrgTxt")}
                        {this.state.isFromPharmacy && UserData?.pharmacy_type === 'Inhouse Pharmacy' ? this.renderPermissionList('isApprover', 'pharma', false, "IndentApprover", states?.per_Access?.pharma?.isApprover, "", "", "", "", "userIntentTxt") : null}
                        {this.renderPermissionList('isViewMargin', 'pharma', false, "viewMarginProfit", states?.per_Access?.pharma?.isViewMargin, "", "", "", "", "userviewMarginTxt")}
                        {this.renderPermissionList('change_payment_mode', 'common', false, "Can User Change the Payment Mode", states?.per_Access?.common?.change_payment_mode, "", "", "", "", "User can change the Payment Mode of any receipts")}
                        {this.renderPermissionList('isReturnBill', 'pharma', false, "Can User allow Retrun Bill", states?.per_Access?.pharma?.isReturnBill, "", "", "", "", "User can Return the Bill")}
                        {this.state.isFromPharmacy && UserData?.pharmacy_type === 'Inhouse Pharmacy' ? this.renderPermissionList('isGRNEntry', 'pharma', false, "Can User allow  GRN Edit Access", states?.per_Access?.pharma?.isGRNEntry, "", "", "", "", "User can allow  GRN Edit access"): null}
                    </Box>
                </Box>
                <Box display={'flex'}
                    justifyContent={'center'} alignItems={'center'}
                    height={"3.1vw"} sx={{ backgroundColor: Colors.Background }}>
                    <Box>
                        <Button
                            size='small'
                            variant='outlined'
                            sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                            onClick={() => {
                                this.setState({
                                    is_OPCredit: !this.state.is_OPCredit,
                                    is_IPCredit: !this.state.is_IPCredit,
                                    is_LabCredit: !this.state.is_LabCredit,
                                    per_Access: Permission_Access
                                })
                            }}
                        >{t("Reset")}</Button>
                        <Button
                            size='small'
                            variant='contained'
                            sx={{ textTransform: "capitalize" }}
                            onClick={() => this.postNewUserAccessData()}
                        >{t("Save")}</Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    resetClose = () => {
        this.setState({
            is_Reset_Pass: false,
            Oldpasswords: '',
            Newpasswords: '',
            Confirmpasswords: '',
        })
    }

    resetPassword = () => {
        try {
            var states = this.state
            let passWord = CommonValidation.checkPassword(this.state.Newpasswords)
            if (states.Oldpasswords) {
                if (passWord && states.Newpasswords === states.Confirmpasswords) {
                    let data = {
                        "username": states.userName ? states.userName : '',
                        "login_id": states.loginId ? states.loginId : null,
                        "current_password": states.Oldpasswords ? states.Oldpasswords : null,
                        "new_password": states.Newpasswords ? states.Newpasswords : null,
                        "confirm_password": states.Confirmpasswords ? states.Confirmpasswords : null,
                    }
                    RestAPIService.create(data, Serviceurls.RESET_PASSWORD).
                        then((response) => {
                            if (response.data.status === "success") {
                                this.successMessgae(response.data.message)
                                this.setState({
                                    is_Reset_Pass: false,
                                    Oldpasswords: '',
                                    Newpasswords: '',
                                    Confirmpasswords: '',
                                })
                                this.getUserAccessData()
                            }
                        }).catch((error) => {
                            if (error?.response?.data?.status === "fail") {
                                this.errorMessgae(error.response.data.message)
                            } else {
                                this.errorMessgae(error.message)
                            }
                        })
                } else {
                    if (passWord === false) {
                        this.errorMessgae('Password should be 6 characters, one UpperCase,Special Characters,LowerCase')
                    } else {
                        this.errorMessgae('New password and Confirm password not matched')
                    }
                }
            } else {
                this.errorMessgae('Please Enter the Old Password')
            }

        } catch (e) {
            this.errorMessgae(e.message)
        }
    }

    render() {
        const { t } = this.props
        var states = this.state
        return (
            <Box component={'div'} className='eMed_new_container'>
                <Box component={'div'} className='eMed_new_left'>
                    <Box component={'div'} className='eMed_new_right_card'>
                        <Box component={'div'} className='eMed_new_middle'>
                            <Box component={'div'} className='eMed_top_edit_btn_ui'>
                                <Typography sx={{ fontSize: '1vw', fontWeight: 'bold' }}>{"User Details:"}</Typography>
                                {this.state.isEdit ?
                                    <FormControlLabel sx={{ color: '#BB1E1E', fontWeight: 'bold' }}
                                        control={<Switch defaultChecked size='small' color='error'
                                            checked={this.state.isUserInactive}
                                            onChange={(e) => {
                                                states['isUserInactive'] = e.target.checked
                                                this.setState({ states }, () => {
                                                    if (states.isUserInactive) {
                                                        this.setState({
                                                            deletePopUp: true
                                                        })
                                                    } else { this.UserDeleteFunc() }
                                                })
                                            }} />}
                                        label="Inactive User"
                                    /> : null}
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box>
                                    <Box sx={{ width: '10vw', display: 'flex', justifyContent: 'center', marginTop: '1vw' }}>
                                        <img src={this.state.profile ? this.state.profile : ImagePaths.Male.default} alt='profile'
                                            className={this.state.profile ? 'eMed_new_img_res_ui' : 'eMed_new_profile_ui'} />
                                        {this.state.open ? null :
                                            <img
                                                src={ImagePaths.AddUserButton.default}
                                                alt='add'
                                                className='eMed_Useradd_btn_ui'
                                                onClick={() => this.setState({ open: true })}
                                            />}

                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        {this.state.open ?
                                            <Box component={'div'}>
                                                <IconButton color="primary" onClick={() => this.setState({ webCamOpen: true })}>
                                                    <PhotoCamera />
                                                </IconButton>
                                                <IconButton color="primary" aria-label="upload picture" component="label">
                                                    <input hidden accept="image/*" type="file" onChange={(e) => { this.UploadImage(e, "profile") }} />
                                                    <UploadFileIcon />
                                                </IconButton>
                                                <IconButton color="primary"
                                                    onClick={() => this.setState({ open: false })}>
                                                    <CancelIcon />
                                                </IconButton>
                                            </Box> : null}
                                    </Box>
                                    <Box margin={'1.2vw 0.5vw 0.5vw 0.5vw'}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            size='small'
                                            // name={'passwords'}
                                            variant='standard'
                                            value={this.state.full_Name}
                                            placeholder='Name'
                                            sx={{ width: '10vw', fontSize: '0.8vw' }}
                                        // onChange={(e) => this.onChangeHandler(e)}
                                        />
                                    </Box>
                                    <Box margin={'1.2vw 0.5vw 0.5vw 0.5vw'}>
                                        <TextField
                                            size='small'
                                            name={'regNO'}
                                            variant='standard'
                                            placeholder='Reg.No'
                                            value={this.state.regNO}
                                            sx={{ width: '10vw', fontSize: '0.8vw' }}
                                            onChange={(e) => {
                                                const { name, value } = e.target
                                                if ((value.length <= 30) || value === "") {
                                                    this.setState({
                                                        [name]: value
                                                    })
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box margin={'1.2vw 0.5vw 0.5vw 0.5vw'}>
                                        <TextField
                                            size='small'
                                            name={'user_Notes'}
                                            variant='standard'
                                            multiline
                                            rows={5}
                                            placeholder='Enter Notes'
                                            value={this.state.user_Notes}
                                            sx={{ width: '10vw', fontSize: '0.8vw' }}
                                            onChange={(e) => {
                                                const { name, value } = e.target
                                                if ((value.length <= 240) || value === "") {
                                                    this.setState({
                                                        [name]: value
                                                    })
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box component={'div'} sx={{ borderLeft: '1px solid lightgray', height: '63vh' }}>
                                    <Box sx={{ borderBottom: '1px solid lightgray' }}>
                                        <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold', color: '#625F5F', margin: '0.5vw 0.5vw' }}>{"Personal Details:"}</Typography>
                                        <Box sx={{ width: '25vw', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '1vw 0vw 0vw 0.5vw' }}>
                                            {this.inputTextBox(t("FirstName"), this.state.firstName, 'firstName')}
                                            {this.inputTextBox(t("LastName"), this.state.lastName, 'lastName')}
                                        </Box>
                                        <Box sx={{ width: '25vw', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '1vw 0vw 0vw 0.5vw' }}>
                                            {this.inputTextBox(t("MobileNo"), this.state.mobileNumber, 'mobileNumber')}
                                            {this.inputTextBox(t("MailID"), this.state.email, 'email')}
                                        </Box>
                                        <Box sx={{ width: '25vw', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '1vw 0vw 1vw 0.5vw' }}>
                                            <FormControl size='small' sx={{ width: '12vw' }}>
                                                <InputLabel>{t('Role')}</InputLabel>
                                                <Select
                                                    label={t('Role')}
                                                    value={0}
                                                    name='role'
                                                    variant='standard'
                                                    onChange={(e) => { this.onChangeHandler(e) }}
                                                    disabled
                                                >
                                                    <MenuItem value={0}>{"User"}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold', color: '#625F5F', margin: '0.5vw 0.5vw' }}>{"Account Details:"}</Typography>
                                        <Box sx={{ width: '25vw', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '1vw 0vw 0vw 0.5vw' }}>
                                            {this.inputTextBox(t("Username"), this.state.userName, 'userName')}
                                            <form style={{ display: 'inline' }}>
                                                <TextField
                                                    size='small'
                                                    label={`${"Password"} *`}
                                                    name={'passwords'}
                                                    variant='standard'
                                                    type={this.state.showPassword ? 'text' : 'password'}
                                                    InputProps={{
                                                        endAdornment:
                                                            <InputAdornment>
                                                                <IconButton onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                                                                    {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                    }}
                                                    value={this.state.passwords}
                                                    className='eMed_input_text_box_UI'
                                                    onChange={(e) => this.onChangeHandler(e)}
                                                    autoComplete='no'
                                                />
                                            </form>
                                        </Box>
                                        <Box sx={{ width: '25vw', display: 'flex', flexDirection: 'row', justifyContent: UserData?.pharmacy_type ? 'flex-end' : 'space-between', margin: '1vw 0vw 0vw 0.5vw' }}>
                                            {this.state.isFromPharmacy ? null
                                                : <Box>
                                                    <FormControl size='small' sx={{ width: '12vw' }}>
                                                        <InputLabel>{t('clinicName')}</InputLabel>
                                                        <Select
                                                            disabled
                                                            variant='standard'
                                                            label={t('clinicName')}
                                                            value={0}
                                                            name='clinicName'
                                                            onChange={(e) => { this.onChangeHandler(e) }}
                                                        >
                                                            <MenuItem value={0}>{BranchName}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>}
                                            <Typography sx={{ marginTop: '-0.8vw', cursor: 'pointer', fontSize: "0.7vw", color: Colors.themeDark }}
                                                onClick={() => {
                                                    this.setState({
                                                        is_Reset_Pass: true
                                                    })
                                                }}>
                                                {"Password Reset"}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: '25vw', display: 'flex', justifyContent: 'space-between', margin: '1vw 0vw 0vw 0.5vw' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold', color: '#625F5F' }}>{"Module Access:"}</Typography>
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', }}>
                                                {this.state.userModuleData.map((item, index) => {
                                                    let isChecked = this.state.moduleAndRoleList.find((list) => {
                                                        if (item?.module === "Pharmacy" && item.pharmacy_name === list?.module_name) {
                                                            return true
                                                        } else if (item?.module === "Laboratory" && item.laboratory_name === list?.module_name) {
                                                            return true
                                                        } else if (item?.module === "front_office" && item.clinic_name === list?.module_name) {
                                                            return true
                                                        } else if (item?.module === "Radiology" && item.radiology_name === list?.module_name) {
                                                            return true
                                                        }
                                                    })
                                                    let label = item?.module
                                                    if(item?.module === "front_office"){
                                                        label = item?.clinic_name
                                                    }
                                                    if(item?.module === "Radiology"){
                                                        label = item?.radiology_name
                                                    }
                                                    if (item?.module === "Laboratory") {
                                                        label = item?.laboratory_name
                                                    }
                                                    if (item?.module === "Pharmacy") {
                                                        label = item?.pharmacy_name
                                                    }
                                                    return (
                                                        <FormGroup key={index}>
                                                            <FormControlLabel control={<Checkbox checked={isChecked !== undefined}
                                                                onChange={() => {
                                                                    let line_index = null
                                                                    const isItemInArray = this.state.moduleAndRoleList.find((selectedItem, index) => {
                                                                        if (
                                                                            (selectedItem.module_name === item.laboratory_name && selectedItem.moduels === item.module) ||
                                                                            (selectedItem.module_name === item.pharmacy_name && selectedItem.moduels === item.module) ||
                                                                            (selectedItem.module_name === item.clinic_name && selectedItem.moduels === item.module) || 
                                                                            (selectedItem.module_name === item.radiology_name && selectedItem.moduels === item.module)
                                                                        ) {
                                                                            line_index = index;
                                                                            return true;
                                                                        }
                                                                        return false;

                                                                    });

                                                                    // If the item is already selected, remove it; otherwise, add it to the array
                                                                    if (isItemInArray) {
                                                                        this.setState({
                                                                            moduleAndRoleList: this.state.moduleAndRoleList.filter((_, index) => index !== line_index),
                                                                            selectedModule: "",
                                                                            onSelectModules: false
                                                                        });
                                                                    } else {
                                                                        let module = {
                                                                            module_name: '',
                                                                            moduels: '',
                                                                            id: null,
                                                                            label: ''
                                                                        };
                                                                        let moduleName = '';
                                                                        let module_id = null;

                                                                        if (item?.clinic_id) {
                                                                            moduleName = item?.clinic_name;
                                                                            module_id = item?.clinic_id;
                                                                        } else if (item?.laboratory_id) {
                                                                            moduleName = item?.laboratory_name;
                                                                            module_id = item?.laboratory_id;
                                                                        } else if (item?.pharmacy_id) {
                                                                            moduleName = item?.pharmacy_name;
                                                                            module_id = item?.pharmacy_id;
                                                                        } else if (item?.id) {
                                                                            moduleName = item?.radiology_name;
                                                                            module_id = item?.id;
                                                                            module_id = item?.id;
                                                                        }

                                                                        module = {
                                                                            module_name: moduleName,
                                                                            moduels: (this.state.isFromPharmacy && UserData?.pharmacy_type !== 'Inhouse Pharmacy') ? "Standalone Pharmacy" : item?.module,
                                                                            id: module_id,
                                                                            label: moduleName
                                                                        };

                                                                        this.setState({
                                                                            moduleAndRoleList: [...this.state.moduleAndRoleList, module],
                                                                            selectedModule: this.state.isFromPharmacy ? UserData?.pharmacy_type !== 'Inhouse Pharmacy' ? "Standalone Pharmacy" : module?.module_name : "",
                                                                            onSelectModules: this.state.isFromPharmacy ? true : false
                                                                        });
                                                                    }
                                                                }} />} label={label} />
                                                        </FormGroup>
                                                    )
                                                })}
                                            </Box>

                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box component={'div'} className='eMed_new_btn'>
                            <Box>
                                {this.state.isEdit ?
                                    <Button
                                        size='small'
                                        variant='outlined'
                                        emed_tid='UserAccess_Edit_Lock_Btn'
                                        disabled={this.state.disableBtn}
                                        onClick={() => { this.setState({ isUserLock: !this.state.isUserLock }, () => { this.PostUserStatus() }) }}
                                        sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                                    >{this.state.isUserLock ? "UnLock" : "Lock"}</Button> : null}
                                {/* <Button
                                    size='small'
                                    variant='outlined'
                                    sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                                >Reset</Button> */}
                                <Button
                                    size='small'
                                    variant='contained'
                                    emed_tid='UserAccess_Edit_LeftSave_Btn'
                                    disabled={this.state.disableBtn}
                                    sx={{ textTransform: "capitalize" }}
                                    onClick={() => this.postNewUserAccessData()}
                                >Save</Button>
                            </Box>
                        </Box>
                        {/* : null} */}

                    </Box>
                </Box>
                <Box component={'div'} className='eMed_new_right'>
                    <Box component={'div'} className='eMed_new_left_content'>
                        <Tabs
                            value={this.state.tabDetail}
                            onChange={(e, newValue) => this.setState({
                                tabDetail: newValue
                            })}
                        >
                            <Tab value="one" label={t("ModuleAccess")} className='eMed_tab_header' />
                            <Tab value="two" label={`${t("Permission")}s`} className='eMed_tab_header' />
                        </Tabs>
                        {this.state.tabDetail === "one" ? this.moduleAccessTab() : this.state.tabDetail === "two" ? this.permissionTab() : ""}
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletePopUp ? <DeletePopup
                    DeletTitle={"Are you sure ? Do you want to Inactive the User ?"}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.UserDeleteFunc.bind(this)}
                    ButtonText={'Yes'}
                /> : null}
                {this.state.webCamOpen ? this.OpenWebCamPopUP() : null}
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.is_Reset_Pass ?
                    <Dialog
                        open={this.state.is_Reset_Pass}
                    >
                        <DialogTitle sx={{ m: 0, p: 2, fontWeight: 'bold' }} >
                            Reset Password
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={() => { this.resetClose() }}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogContent dividers>
                            <Box sx={{ width: '30vw', height: '20vh', border: '1px solid lightgray', borderRadius: '0.2vw' }}>
                                <Box sx={{ width: '28vw', display: 'flex', justifyContent: 'space-between', margin: '1vw' }}>
                                    <TextField
                                        size='small'
                                        label={"Old Password"}
                                        name={'Oldpasswords'}
                                        variant='standard'
                                        type={this.state.showResetPass ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment>
                                                    <IconButton onClick={() => this.setState({ showResetPass: !this.state.showResetPass })}>
                                                        {this.state.showResetPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        value={this.state.Oldpasswords}
                                        sx={{ width: '13vw' }}
                                        onChange={(e) => {
                                            const { name, value } = e.target
                                            let PasswordInput = CommonValidation.PasswordInput(value)
                                            if ((PasswordInput && value.length <= 14) || value === "") {
                                                this.setState({
                                                    [name]: value
                                                })
                                            }
                                        }}
                                        autoComplete='no'
                                    />
                                </Box>
                                <Box sx={{ width: '28vw', display: 'flex', justifyContent: 'space-between', margin: '1vw' }}>
                                    <TextField
                                        size='small'
                                        name={'Newpasswords'}
                                        variant='standard'
                                        label='New Password'
                                        type={this.state.showResetNew ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment>
                                                    <IconButton onClick={() => this.setState({ showResetNew: !this.state.showResetNew })}>
                                                        {this.state.showResetNew ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        value={this.state.Newpasswords}
                                        sx={{ width: '13vw', fontSize: '0.8vw' }}
                                        onChange={(e) => {
                                            const { name, value } = e.target
                                            let PasswordInput = CommonValidation.PasswordInput(value)
                                            if ((PasswordInput && value.length <= 14) || value === "") {
                                                this.setState({
                                                    [name]: value
                                                })
                                            }
                                        }}
                                    />
                                    <TextField
                                        size='small'
                                        name={'Confirmpasswords'}
                                        variant='standard'
                                        type={this.state.showResetConfirm ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment>
                                                    <IconButton onClick={() => this.setState({ showResetConfirm: !this.state.showResetConfirm })}>
                                                        {this.state.showResetConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        label='Confirm Password'
                                        value={this.state.Confirmpasswords}
                                        sx={{ width: '13vw', fontSize: '0.8vw' }}
                                        onChange={(e) => {
                                            const { name, value } = e.target
                                            let PasswordInput = CommonValidation.PasswordInput(value)
                                            if ((PasswordInput && value.length <= 14) || value === "") {
                                                this.setState({
                                                    [name]: value
                                                })
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' sx={{ width: '12vw', textTransform: 'capitalize' }} onClick={() => { this.resetPassword() }}>
                                {"Change Password"}
                            </Button>
                        </DialogActions>
                    </Dialog> : null}
            </Box>
        )
    }
}


export default withTranslation()(CommonUserAccess)