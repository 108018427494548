import React, { Component } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, IconButton, Stack, InputLabel, FormHelperText, Tooltip, Box, Collapse, InputAdornment, Typography } from '@mui/material'
import './home.css'
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Autocomplete from '@mui/material/Autocomplete';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import { Trans, withTranslation } from 'react-i18next';
import { t } from 'i18next';
import CommonGridHeader, { BillSuccessPoPUp } from "../../../Components/Common Components/CommonComponents";
import CreditBillPopUp from '../../FrontOffice/BillingTransaction/CreditBillPopUp';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../Styles/Colors';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import SearchIcon from '@mui/icons-material/Search';
import EditHistoryPopup from '../../FrontOffice/BillingTransaction/EditHistoryPopup';
import { getCachevalue, localGetItem, setCachevalue } from '../../../Utility/Services/CacheProviderService';
import CreditBillCompletePoP from '../../FrontOffice/BillingTransaction/CreditBillCompletePoP';

const ITEM_HEIGHT = 48;
const specimanList = [
    { "service_name": "calcium", "lab_type": "Self" },
    { "service_name": "glucose", "lab_type": "Self" },
    { "service_name": "sodium", "lab_type": "Self" },
    { "service_name": "pottassium", "lab_type": "Self" },
    { "service_name": "chloride", "lab_type": "Self" },
    { "service_name": "blood urea nitrogen(BUN)", "lab_type": "Self" },
    { "service_name": "creatinine", "lab_type": "Self" }
]
const multi_option = [
    { "label": "ViewPreviousReport", "value": "View Previous Report" },
    { "label": "ReceivePayment", "value": "Receive Payment" },
    { "label": "BarCode", "value": "Common Barcode" },
    { "label": "Cancel Bill", "value": "Cancel Bill" },
    { "label": "Delete Bill", "value": "Delete Bill" }

]
const Previous_List = [
    {
        invoice_number: "1232"
    },
    {
        invoice_number: "12223"
    }
]

const status = [
    {
        id: 1, patient_name: 'Anand', patient_tag_name: 'test', patient_age: '28 Y', patient_gender: 'm', patient_account_number: 'EMED5001', patient_mobile: 1234567890, total_test: '25', completed_test: '06',
        data: [
            {
                "id": 2,
                "lab_bill_summary_id": 57221,
                "status": "Specimen Collected",
                "patient_id": 843402,
                "patient__first_name": "Deepak",
                "patient__last_name": null,
                "patient__initial": null,
                "patient__patient_account_number": null,
                "barcode": 'SC1588',
                "patient__mobile_number": null,
                "patient__gender": "m",
                "patient__dob": null,
                "patient__approx_dob": null,
                "lab_bill_summary__status": "Result Inprogress",
                "patient__title": "Mr",
                "lab_bill_summary__appointment__patient_tags__patient_tag_name": null,
                "created_date__date": "2023-11-17",
                "result_completed_date__date": null,
                "bill_number": "1",
                "bill_type": 'regular',
                "ip_admission_id": 10878,
                "temp_bill_number": null,
                "ip_number": 'RACQ/2023-2024/IP/632',
                "appointment_id": null,
                "amount_net": 103.0,
                "total_test": 10,
                "completed_test": 1,
                "patient_age": "17Y",
                "patient_name": "Mr.Deepak "
            }
        ]
    }
]
class Status extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            statusSummaryList: [],
            isSpecimanClick: false,
            specimanList: specimanList,
            consumablesName: "",
            consumableValue: "",
            consumableUnit: "",
            consumableList: [],
            sampleCollectedIn: [],
            isFilterOption: false,
            selectedIndex: "",
            isMultiOPtion: false,
            selectedOption: "",
            anchorEl: null,
            billSummaryID: "",
            reason: "",
            isErrorMsg: false,
            isErrorMsgText: "",
            pageSize: 10,
            selectedTableRow: {},
            outSourceOptionList: [],
            isViewReport: false,
            previousReportList: Previous_List,
            specimanSuggessionList: [],
            selectConsumables: null,
            outSourceList: [],
            isErrorMsg: false,
            isErrorMsgText: "",
            outSourceList: [],
            preReportList: [],
            search_key: "",
            patientName: "",
            mobileNumber: "",
            QueryFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            QueryToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateFilter: 1,
            isSpecimanComplete: false,
            isSelected: false,
            isCancelBill: false,
            CreditBillPopUp: false,
            amountData: {},
            disableBtn: false,

            //New Table
            tableSelectedIndex: null,
            tblPage: 0,
            tblPageSize: 10,
            TableSearchText : "",
            resetSourceOptionList: [],
            BillModifyHistoryItem:{}
        }
    }
    componentDidMount() {
        this.getStatusSpecimanList()
        if (getCachevalue('LabStatusDateFilter')) {
            this.setState({
                dateFilter: +getCachevalue('LabStatusDateFilter')
            }, ()=>{
                this.handleDate()
            })
        } else {
            this.getStatusSummaryList()
            setCachevalue(this.state.dateFilter, 'LabStatusDateFilter')
        }
    }
    componentDidUpdate(PrevProps) {
        if (PrevProps.search_key != this.props.search_key) {
            var { search_key } = this.props
            this.setState({
                search_key: search_key ? search_key : ""
            }, () => {
                var data = parseInt(this.state.search_key)
                let type = typeof (data ? data : "")

                if (type == "number") {
                    this.setState({
                        mobileNumber: this.state.search_key ? this.state.search_key : "",
                        patientName: ""
                    }, () => {
                        this.getStatusSummaryList()
                    })
                }
                else {
                    this.setState({
                        patientName: this.state.search_key ? this.state.search_key : "",
                        mobileNumber: ""
                    }, () => {
                        this.getStatusSummaryList()
                    })
                }
            })
        }
    }

    getReceivePaymentAmountData = (cellvalues) => {
        try {
            let params = ""
            let ipAdmissionId = cellvalues?.ip_admission_id
            let op_appointment_id = cellvalues?.appointment_id
            let lab_bill_summary_id = cellvalues?.lab_bill_summary_id

            if ((ipAdmissionId !== null && lab_bill_summary_id !== null)) {
                params = "?ip_admission_id=" + ipAdmissionId
            } else if ((ipAdmissionId === null && op_appointment_id !== null && lab_bill_summary_id !== null)) {
                params = "?appointment_id=" + op_appointment_id
            } else if ((ipAdmissionId === null && op_appointment_id === null && lab_bill_summary_id !== null)) {
                params = "?lab_bill_summary=" + lab_bill_summary_id
            }

            RestAPIService.getAll(Serviceurls.LAB_STATUS_AND_TEST_REPORT_AMOUNT_GET + params).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            amountData: response.data.data
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error?.response?.data?.message
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }

    getCommonBarCodePrint = (params) => {
        try {
            let admissionId = this.state.SelectedBill?.ip_admission_id ? this.state.SelectedBill?.ip_admission_id : ''
            let appointmentId = this.state.SelectedBill?.appointment_id ? this.state.SelectedBill?.appointment_id : ''
            RestAPIService.getAll(Serviceurls.COMMON_BARCODE_PRINT + "?patient_id=" + this.state.SelectedBill?.patient_id + "&appointment_id=" + appointmentId + "&admission_id=" + admissionId)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data.message
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    PrintBarcode = () => {
        try {
            var details = this.state.selectedTableRow
            this.setState({disableBtn: true})
            RestAPIService.getAllPrint(Serviceurls.SPECIMEN_BARCODE_PRINT + `?patient_id=${details.patient_id}&result_summary_id=${details.id}`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({disableBtn: false})
                }).catch((error) => {
                    if(error?.response?.data?.message){
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: error.response.data.message,
                            disableBtn: false,
                        })
                    }else{
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: error.message,
                            disableBtn: false,
                        })
                    }
                    
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("PrintError"),
                disableBtn: false,
            })
        }
    }

    msgClose(){
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            isSuccessMsg: false,
            isSuccessMsgText: "",
        })
    }
    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            CreditBillPopUp: false,
            anchorEl: false
        })
    }

    CustomDates = (FromDate, ToDate) => {
        this.setState({
            QueryFromDate: FromDate,
            QueryToDate: ToDate,
            datePickerPop: false
        }, () => {
            let localData = {
                "QueryFromDate": FromDate,
            "QueryToDate": ToDate,
            }
            setCachevalue(JSON.stringify(localData), 'LabStatusDateCustumFilter')
            this.getStatusSummaryList()
        })
    }
    PopClose = () => {
        this.setState({
            datePickerPop: false,
            QueryFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            QueryToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateFilter: 1,
        }, () => this.getStatusSummaryList())
    }
    DateHelperText = () => {
        let fromDate = this.dateConvertion(this.state.QueryFromDate)
        let toDate = this.dateConvertion(this.state.QueryToDate)
        if (this.state.dateFilter === 0) {
            return ""
        }
        else if (this.state.dateFilter === 1) {
            return DateTime.now().toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 2) {
            return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 3) {
            // return `${fromDate}  to  ${toDate}`
            const startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week');
            const endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week');
            return `${startOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')} to ${endOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')}`

        }
        else if (this.state.dateFilter === 4) {
            // return `${fromDate}  to  ${toDate}`
            const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month');
            const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month');
            return `${startOfPreviousMonth.toFormat('dd-MM-yyyy')} to ${endOfPreviousMonth.toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.dateFilter === 5) {
            return `${this.state.QueryFromDate.split("-").reverse().join("-")}  to  ${this.state.QueryToDate.split("-").reverse().join("-")}`
        }
    }

    handleDate = () => {
        var today = DateTime.now().toFormat('yyyy-MM-dd')
        var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
        // var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
        // var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')
        var startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
        var endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
        const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month').toFormat('yyyy-MM-dd');
        const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month').toFormat('yyyy-MM-dd');
        setCachevalue(this.state.dateFilter, 'LabStatusDateFilter')
        if (this.state.dateFilter === 0) {
            this.setState({
                QueryFromDate: "",
                QueryToDate: "",
            }, () => this.getStatusSummaryList())
        }
        else if (this.state.dateFilter === 1) {
            this.setState({
                QueryFromDate: today,
                QueryToDate: today,
            }, () => this.getStatusSummaryList()
            )

        }
        else if (this.state.dateFilter === 2) {
            this.setState({
                QueryFromDate: yesterday,
                QueryToDate: yesterday,
            }, () => this.getStatusSummaryList())
        }
        else if (this.state.dateFilter === 3) {
            this.setState({
                QueryFromDate: startOfPreviousWeek,
                QueryToDate:  endOfPreviousWeek,
            }, () => this.getStatusSummaryList())
        }
        else if (this.state.dateFilter === 4) {
            this.setState({
                QueryFromDate: startOfPreviousMonth,
                QueryToDate:  endOfPreviousMonth,
            }, () => this.getStatusSummaryList())
        }
        else if (this.state.dateFilter === 5) {
            if(getCachevalue("LabStatusDateCustumFilter") !== null){
                let CustumData = JSON.parse(getCachevalue("LabStatusDateCustumFilter"))
                this.setState({
                    QueryFromDate: CustumData?.QueryFromDate,
                    QueryToDate:  CustumData?.QueryToDate,
                }, () => this.getStatusSummaryList())
            }else{
                this.setState({ datePickerPop: true })
            }
        }
    }

    getSpecimanCollectedValue = (id) => {
        RestAPIService.getAll(Serviceurls.LAB_PATENT_SPECIMAN_COLLECTED + "?result_summary_id=" + id)
            .then(response => {
                if (response.data.status == "success") {
                    var data = response.data.data
                    this.setState({
                        outSourceOptionList: data.out_source,
                        sampleCollectedIn: data.extra_consumables ? data.extra_consumables : [],
                        consumableList: data.consumables
                    }, () => {
                        this.state.outSourceOptionList && this.state.outSourceOptionList.forEach(data => { data.out_source_id = "" })
                        this.setState({
                            resetSourceOptionList: JSON.stringify(this.state.outSourceOptionList)
                        })
                    })
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response.data.message
                    })
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
    }
    getPreviousReport = () => {
        RestAPIService.getAll(Serviceurls.LAB_PREVIOUS_REPORT + "?patient_id=" + this.state.selectedTableRow?.patient_id)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        preReportList: response.data.data
                    })
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response.data.message
                    })
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
    }
    getStatusSpecimanList = () => {
        RestAPIService.getAll(Serviceurls.LAB_CONSUMABLES_LIST_GET)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        specimanSuggessionList: response.data.data
                    })
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response.data.message
                    })
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
    }
    getStatusSummaryList = () => {
        this.setState({
            statusSummaryList: []
        })
        RestAPIService.getAll(Serviceurls.LAB_PATINET_STATUS + "?result_status=Result Inprogress" + "&search_key1=" + this.state.patientName + "&search_key2=" + this.state.mobileNumber + "&from_date=" + this.state.QueryFromDate + "&to_date=" + this.state.QueryToDate)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        statusSummaryList: response.data.data
                    }, () => {
                        var { statusSummaryList } = this.state
                        statusSummaryList.forEach(data => { data.is_multi_option = false; });

                    })
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response?.data?.message
                    })
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error?.response?.data?.message
                })
            })
    }
    onClickSavedChanges = () => {
        var { selectedTableRow } = this.state
        try {
            var data = {
                "result_summary_id": selectedTableRow.id,
                "out_source": this.state.outSourceOptionList,
                "extra_consumables": this.state.sampleCollectedIn,
                "consumables": this.state.consumableList
            }
            RestAPIService.create(data, Serviceurls.LAB_PATENT_SPECIMAN_COLLECTED)
                .then(response => {
                    if (response.data.status == "success") {
                        var data = response.data.data
                        this.getStatusSummaryList()
                        this.setState({
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.message,
                            isSpecimanClick: false
                        })
                    }

                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                })
        }
        catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    onCancelPost = () => {
        try {
            var data = {
                "bill_summary_id": this.state.billSummaryID,
                "cancel_reason": this.state.reason,
                "appointment_id": this.state.selectedTableRow.appointment_id ? this.state.selectedTableRow.appointment_id : null,
                "ip_admission_id": this.state.selectedTableRow.ip_admission_id ? this.state.selectedTableRow.ip_admission_id : null,
                "ip_number": this.state.selectedTableRow.ip_number ? this.state.selectedTableRow.ip_number : null
            }
            if (this.state.reason == "") {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: "Please enter the bill cancel reason"
                })
            } else {
                RestAPIService.create(data, Serviceurls.LAB_CANCEL_BILL)
                    .then(response => {
                        if (response.data.status == "success") {
                            var datas = response.data
                            this.setState({
                                isSuccessMsgText: datas.message,
                                isSuccessMsg: true,
                                isCancelBill: false,
                                anchorEl: false,
                                reason: ""
                            }, () => {
                                this.getStatusSummaryList()
                            })
                        }
                    }).catch(e => {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: e.message
                        })
                    })
            }
        }
        catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    onDeleteBill = () => {
        try {
            let serviceUrl = Serviceurls.LAB_CANCEL_BILL + "?bill_summary_id=" + parseInt(this.state.billSummaryID)
            RestAPIService.delete(serviceUrl, {})
                .then(response => {
                    if (response.data.status == "success") {
                        var datas = response.data
                        this.setState({
                            isSuccessMsgText: datas.message,
                            isSuccessMsg: true,

                        }, () => {
                            this.props.getSavedBillStatus(true)
                            this.props.getTransmitBillCount(true)
                            this.getStatusSummaryList()
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })
        }
        catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    renderSpecimanBottomView = (header, value) => {
        return (
            <div className='Lab_status_speciman_bottom'>
                <p className='Lab_Status_Header1'>{header}</p>
                <p className='Lab_Status_split'>{" : "}</p>
                <p className='Lab_Status_Value'>{value}</p>
            </div>
        )
    }
    renderTextField = (title, value, key, flex) => {
        const { t } = this.props
        return (
            <div className='consumable_container'>
                {key == "consumablesName" ?
                    <Autocomplete
                        disabled={this.state.selectedTableRow.status == "Specimen Collected" ? true : false}
                        options={this.state.specimanSuggessionList}
                        getOptionLabel={(options) => options.product_name}
                        onChange={(e, value) => {

                            this.setState({
                                consumableUnit: value ? value.unit : value,
                                selectConsumables: value,
                                consumableValue: ""
                            })
                        }}
                        value={this.state.selectConsumables}
                        id="combo-box-demo"
                        sx={{ width: "10vw" }}
                        renderInput={(params) => <TextField {...params} label={t("Consumables")} size='small' />}
                    /> :
                    < TextField
                        disabled={(this.state.selectedTableRow.status == "Specimen Collected") || key === "consumableUnit" || this.state.selectConsumables == null ? true : false}
                        name={title}
                        className="consumablesField"
                        sx={{}}
                        size="small"
                        inputProps={{
                            style: {
                                height: "1vh",
                                width: '4vw',
                                marginLeft: "0.4vw"
                            },
                        }}
                        placeholder={t(title)}
                        value={value}
                        onChange={(e) => {
                            var states = this.state
                            if (key == "consumableValue") {
                                var { selectConsumables } = this.state
                                let data = e.target.value
                                if (selectConsumables.stock_in_qty && (+data <= selectConsumables?.stock_in_qty) || data == "") {
                                    states[key] = e.target.value
                                    this.setState({
                                        states
                                    })
                                }
                            } else {
                                states[key] = e.target.value
                                this.setState({ states })
                            }
                        }}
                        type={"text"}
                    />
                }
            </div>

        )
    }
    clearData = () => {
        this.setState({
            outSourceOptionList: JSON.parse(this.state.resetSourceOptionList),
            sampleCollectedIn: [],
            consumablesName: "",
            consumableValue: "",
            consumableUnit: "",
            selectConsumables: null
        })
    }
    validCheck = (data) => {
        var { sampleCollectedIn } = this.state
        var duplicate = false
        if (sampleCollectedIn?.length > 0) {
            for (let i = 0; i < sampleCollectedIn.length; i++) {
                if (sampleCollectedIn[i].product_ms_id == data.product_ms_id) {
                    duplicate = true
                }
                if (sampleCollectedIn[i].product_ms_id == data.product_ms_id) {
                    duplicate = true
                }
            }
        }
        return duplicate
    }
    dateConvertion = (d) => {
        var dateForm = new Date(d)
        let data = DateTime.fromJSDate(dateForm).toFormat("dd-MM-yyyy")
        return data
    }
    testReportPrint = () => {
        RestAPIService.getAll(Serviceurls.LAB_TEST_REPORT_PRINT + "?export_type=pdf").
            then((response) => {
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.reponse.data.message
                })
            })
    }
    individualReportPrint = (id) => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT + "?result_summary_id=" + id)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data.message
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    CrebillPosted(message = "", receiptNO = null, phrReceipt = null, error = false) {
        this.setState({
            CreditBillPopUp: false,
            receiptID: receiptNO,
            pharmareceipt: phrReceipt

        }, () => {
            if(error){
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: message,
                    anchorEl: false
                })
            }else{
                this.setState({
                    isSuccessMsg: true,
                    isSuccessMsgText: message,
                    anchorEl: false
                })
            }
            this.getStatusSummaryList()
            this.getStatusSpecimanList()
        })
    }

    ClosesuccessPopUp = () => {
        this.setState({
            receiptID: null,
            pharmareceipt: null
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    renderStatusTable = () => {
        try {
            let filtereddata = this.state.statusSummaryList.filter((data) => {
                return (
                    (data?.patient_name === null ? null : data?.patient_name.toLowerCase().includes(this.state.TableSearchText.toLowerCase()))
                );
            });
            filtereddata?.slice(
                this.state.tblPage * this.state.tblPageSize,
                this.state.tblPage * this.state.tblPageSize +
                this.state.tblPageSize)?.forEach((element, index) => {
                    let page = this.state.tblPage
                    let pageSize = this.state.tblPageSize
                    let serial = (page * pageSize) + (index + 1)
                    element.serial = serial
                })
            return (
                <Box component={'div'} border={'1px solid lightgray'} height={'67vh'} overflow={'auto'}>
                    <Box component={'div'} height={'57vh'} overflow={'auto'}>
                        <Table stickyHeader size='small'>
                            <TableHead>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>S.No</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Patient Details</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Mobile Number</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Module Type</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Total Test</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }} />
                            </TableHead>
                            <TableBody>
                                {filtereddata?.length > 0 && filtereddata?.slice(
                                    this.state.tblPage * this.state.tblPageSize,
                                    this.state.tblPage * this.state.tblPageSize +
                                    this.state.tblPageSize)?.map((item, index) => (
                                        <><TableRow key={index}
                                            onClick={() => {
                                                this.setState({
                                                    tableSelectedIndex: this.state.tableSelectedIndex === index ? null : index
                                                })
                                            }}
                                        >
                                            <TableCell>{item?.serial}</TableCell>
                                            <TableCell>
                                                <div style={{fontSize:'0.9vw',fontWeight:'600'}}>{`${item?.patient_name} ${item?.patient_age}`}</div>
                                                <div style={{fontSize:'0.8vw',color:Colors.grayShade}}>{`${item?.patient_account_number ? item?.patient_account_number : '-'} | ${item?.patient_tag_name ? item?.patient_tag_name : '-'}`}</div>
                                            </TableCell>
                                            <TableCell>{item?.patient_mobile}</TableCell>
                                            <TableCell>{item?.bill_type}</TableCell>
                                            <TableCell>{`${item?.completed_test} / ${item?.total_test}`}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    size='small'
                                                    onClick={() => {
                                                        this.setState({
                                                            tableSelectedIndex: this.state.tableSelectedIndex === index ? null : index
                                                        })
                                                    }}
                                                >
                                                    {this.state.tableSelectedIndex === index ?
                                                        <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                            <TableRow>
                                                {this.renderStatusSecondTable(item, index)}
                                            </TableRow></>
                                    ))}
                            </TableBody>
                        </Table>
                    </Box>

                    <TablePagination
                        className='eMed_tbl_pagination'
                        sx={{ borderTop: '1px solid lightgray' }}
                        component='div'
                        rowsPerPageOptions={[10, 20, 30]}
                        count={filtereddata?.length}
                        rowsPerPage={this.state.tblPageSize}
                        page={this.state.tblPage}
                        onPageChange={(event, newPage) => { this.setState({ tblPage: newPage }) }}
                        onRowsPerPageChange={(event) => { this.setState({ tblPageSize: event.target.value, tblPage: 0 }) }}
                    />
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    disableMoreOptions(item) {
        let { selectedTableRow } = this.state
        switch (item?.value) {
            case "ReceivePayment":
                if (selectedTableRow?.payment_status === 'paid') {
                    return true
                } else {
                    return false
                }
            case "ModifyBill":
                if (selectedTableRow?.bill_type === "credit" && selectedTableRow?.payment_status === "not paid" && selectedTableRow?.status !== "Specimen Collected") {
                    return false
                } else {
                    return true
                }
            default: return false
        }
    }

    renderMenu = (secTblItem) => {
        try {
            let { selectedTableRow } = this.state
            let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
            let ModifyBillAccess = RoleData?.is_user ? (RoleData?.permission_access?.common?.modify_credit_bill ? RoleData?.permission_access?.common?.modify_credit_bill : false) : true
            let cancelBillApproval = RoleData?.is_user ? (RoleData?.permission_access?.common?.cancel_bill_approval ? RoleData?.permission_access?.common?.cancel_bill_approval : false) : true
            let menuList = [
                { "value": "ViewPreviousReport", "label": "View Previous Report" },
                { "value": "ReceivePayment", "label": "Receive Payment" },
                { "value": "BarCode", "label": "Common Barcode" },
                { "value": "ModifyBill", "label": "Modify Bill" },  // will be sliced when no access
                { "value": "ModifyHistory", "label": "Modify History" },
                { "value": "CancelBill", "label": "Cancel Bill" },
                // { "value": "DeleteBill", "label": "Delete Bill" }
            ]
            if(!ModifyBillAccess){
                menuList.splice(3, 1);
            }
            if(!cancelBillApproval){
                menuList.pop();
            }
            return (
                <Menu
                    id='long-menu'
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={(e) => {
                        this.setState({
                            anchorEl: null
                        })
                    }}
                    elevation={2}
                >
                    {menuList?.map((item, index) => (
                        <MenuItem key={index}
                            disabled={this.disableMoreOptions(item)}
                            onClick={() => {
                                this.setState({
                                    selectedOption: item.value
                                }, () => {
                                    if (this.state.selectedOption == "ReceivePayment") {
                                        // this.props.selectedTab(0, this.state.selectedTableRow, "isReceivePayment", false)
                                        this.setState({ SelectedBill: this.state.selectedTableRow, CreditBillPopUp: true })
                                    }
                                    if (this.state.selectedOption == "ViewPreviousReport") {
                                        this.setState({
                                            isViewReport: true
                                        })
                                    }
                                    if (this.state.selectedOption == "BarCode") {
                                        this.setState({
                                            SelectedBill: this.state.selectedTableRow
                                        }, () => this.getCommonBarCodePrint(secTblItem))
                                    }
                                    if (this.state.selectedOption == "CancelBill") {
                                        let Data = {
                                            id: this.state.selectedTableRow?.lab_bill_summary_id,
                                            patient_id: this.state.selectedTableRow?.patient_id,
                                            laboratory_id: null,
                                            amount_net: this.state.selectedTableRow?.amount_net,
                                            appointment_id: this.state.selectedTableRow?.appointment_id,
                                            ip_admission_id: this.state.selectedTableRow?.ip_admission_id
                                        }

                                        this.props.cancelBillClick(Data)
                                    }
                                    if (this.state.selectedOption == "DeleteBill") {
                                        this.onDeleteBill()
                                    }
                                    if (this.state.selectedOption == "ModifyBill") {
                                        let Data = {
                                            id: this.state.selectedTableRow?.lab_bill_summary_id,
                                            patient_id: this.state.selectedTableRow?.patient_id,
                                            laboratory_id: null,
                                            amount_net: this.state.selectedTableRow?.amount_net,
                                            appointment_id: this.state.selectedTableRow?.appointment_id,
                                            ip_admission_id: this.state.selectedTableRow?.ip_admission_id,
                                            isForEditBill : true
                                        }
                                        this.props.cancelBillClick(Data)
                                    }
                                    if (this.state.selectedOption == "ModifyHistory") {
                                        this.setState({ BillModifyHistoryItem: this.state.selectedTableRow })
                                    }
                                })
                            }}
                        >{item?.label}</MenuItem>
                    ))}
                </Menu>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderStatusSecondTable = (item, index) => {
        try {
            return (
                <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={this.state.tableSelectedIndex === index} timeout={'auto'}>
                        <Box component={'div'} margin={1} className='eMed_Lab_Sts_Tbl_Two'>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Date</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill No</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill Type</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Barcode Number</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Result Status</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Total Test</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item?.data?.map((secTblItem, secTblIndex) => (
                                        <TableRow key={secTblIndex} sx={{ backgroundColor : secTblItem?.bill_type === "cancelled" ? Colors.disableComponentColor : "white"}}>
                                            <TableCell>{secTblItem?.created_date__date}</TableCell>
                                            <TableCell>{secTblItem?.bill_number}</TableCell>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignContent: 'center', flexDirection: 'column' }}>
                                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", textTransform: 'capitalize' }}>{secTblItem?.bill_type ? secTblItem?.bill_type : "-"}</Typography>
                                                    <Typography sx={{ fontSize: "0.875rem", textTransform: 'capitalize' }}>{secTblItem?.payment_status ? secTblItem?.payment_status : "-"}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{secTblItem?.barcode}</TableCell>
                                            <TableCell sx={{ color: secTblItem?.bill_type === "cancelled" ? Colors.red : "black" }}>{secTblItem?.bill_type === "cancelled" ? "Cancelled" : secTblItem?.status}</TableCell>
                                            <TableCell>{` ${secTblItem?.completed_test} / ${secTblItem?.total_test}`}</TableCell>
                                            <TableCell>
                                                <Box component={'div'} display={'flex'} alignItems={'center'}>
                                                    <Button className='Common_Btn_Min_Width'
                                                        disabled={!CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess || secTblItem?.bill_type === "cancelled"}
                                                        onClick={() => {
                                                            if (secTblItem?.bill_type === "cancelled") {

                                                            } else {
                                                                this.setState({
                                                                    isSpecimanClick: true,
                                                                    selectedTableRow: secTblItem
                                                                }, () => {
                                                                    this.getSpecimanCollectedValue(secTblItem?.id)
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <Tooltip placement='top' title={'Specimen'}>
                                                            <Box component={'img'} src={secTblItem?.status !== "Specimen Collected" ? ImagePaths.Speciman.default : ImagePaths.ResultDisable.default} height={'1.8vw'} width={'1.8vw'} />
                                                        </Tooltip>
                                                    </Button>
                                                    <Button className='Common_Btn_Min_Width'
                                                        disabled={!CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess || secTblItem?.bill_type === "cancelled"}
                                                        onClick={() => {
                                                            if (secTblItem?.status !== "Specimen Collected") {
                                                                if (secTblItem?.bill_type !== "cancelled") {
                                                                    this.errorMessage('Kindly collect the specimen before entering the result')
                                                                }
                                                            } else {
                                                                this.props.selectedTab()
                                                            }
                                                        }}
                                                    >
                                                        {secTblItem?.status === "Specimen Collected" ?
                                                            <Link to={{ pathname: "/Results", state: { resultList: secTblItem } }}>
                                                                <Box component={'img'} src={ImagePaths.Result.default} height={'1.8vw'} width={'1.8vw'} position={'relative'} top={'0.2vw'} />
                                                            </Link> :
                                                            <Box component={'div'}>
                                                                <Box component={'img'} src={ImagePaths.Result.default} height={'1.8vw'} width={'1.8vw'} position={'relative'} top={'0.2vw'} />
                                                            </Box>
                                                        }
                                                    </Button>
                                                    <Button className='Common_Btn_Min_Width'
                                                        aria-label="more"
                                                        id="long-button"
                                                        aria-controls={this.state.isSelected ? 'long-menu' : undefined}
                                                        aria-expanded={this.state.isSelected ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        disabled={CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess ? secTblItem?.bill_type == "cancelled" ? true : false : true}
                                                        onClick={(e) => {
                                                            this.setState({
                                                                anchorEl: e.currentTarget,
                                                                billSummaryID: secTblItem?.lab_bill_summary,
                                                                selectedTableRow: secTblItem,
                                                                isSelected: true
                                                            }, () => {
                                                                this.getPreviousReport()
                                                                // this.getReceivePaymentAmountData(secTblItem)
                                                            })

                                                        }}
                                                    >
                                                        <MoreVertIcon sx={{ color: 'black' }} />
                                                    </Button>
                                                </Box>
                                                {this.renderMenu(secTblItem)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell></>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    HistoryPopClose(){this.setState({BillModifyHistoryItem : {}})}

    render() {
        const { t } = this.props
        var { history } = this.props
        var { selectedTableRow, selectConsumables, outSourceOptionList } = this.state
        // const columns = [
        //     {
        //       field: 'S.No', headerName: 'S.No', sortable: false, flex: 0.05,
        //       renderCell: (index) => index.api.getRowIndex(index.row.id) + 1
        //     },
        //     { field: 'patient_name', headerName: t('PatientName'), sortable: false, flex: 0.2, 
        //     renderCell: (cellvalues) => ( 
        //         <Box>
        //             {cellvalues?.row?.patient_name ? 
        //             cellvalues?.row?.patient_name?.length > 10 ? 
        //             <Tooltip placement='top' title={cellvalues?.row?.patient_name}>
        //                 <div>{`${cellvalues?.row?.patient_name.slice(0,10)}... (${cellvalues.row.patient__gender == "m" ? "M" : cellvalues.row.patient__gender == "f" ? "F" : cellvalues.row.patient__gender == "t" ? "T" : null }) (${cellvalues.row.patient_age ? `${cellvalues.row.patient_age}` : "-"})`}</div>
        //             </Tooltip> :
        //             `${cellvalues?.row?.patient_name} (${cellvalues.row.patient__gender == "m" ? "M" : cellvalues.row.patient__gender == "f" ? "F" : cellvalues.row.patient__gender == "t" ? "T" : null }) (${cellvalues.row.patient_age ? `${cellvalues.row.patient_age}` : "-"})` : "-"
        //         }
        //         <div style={{display: 'flex', flexDirection: 'row'}}>
        //         <Typography id='eMed_Com_Patient_UhidText'>{(cellvalues?.row?.patient__patient_account_number) + ' | ' }</Typography>
        //             {cellvalues?.row?.ip_number?.length > 15 ?
        //                 <Tooltip title={cellvalues?.row?.ip_number} placement={'top'} arrow>
        //                     <Typography id='eMed_Com_Patient_UhidText'>{cellvalues?.row?.ip_number ? '...' + cellvalues?.row?.ip_number.slice(14) : '-'}</Typography>
        //                 </Tooltip> : <Typography id='eMed_Com_Patient_UhidText'>{cellvalues?.row?.ip_number ? cellvalues?.row?.ip_number : cellvalues?.row?.appointment_id ? cellvalues?.row?.appointment_id : '-'}</Typography>}
        //         </div>
        //         </Box>
        //      )},
        //     { field: 'patient__mobile_number', headerName: t('MobileNumber'), sortable: false, flex: 0.15,
        //     renderCell:(params)=>(<div>{params?.row?.patient__mobile_number ? params?.row?.patient__mobile_number : "-"}</div>) },
        //     { field: 'invoice_number', headerName: t('BillNo'), sortable: false, flex: 0.15, headerAlign: "center", align: "center",
        //     renderCell:(params)=>(<div>{params?.row?.invoice_number ? params?.row?.invoice_number : params?.row?.temp_bill_number ? params?.row?.temp_bill_number : "-"}</div>) },
        //     { field: 'created_date__date', headerName: t('Date'), sortable: false, flex: 0.15, headerAlign: "center", align: "center",
        //       renderCell: (cellvalues) => {
        //          var date = this.dateConvertion(cellvalues.row.created_date__date)
        //          return(
        //             <p>{date ? date : "-"}</p>
        //          )
        //       } 

        //     },
        //     { field: 'barcode', headerName: "Barcode Number", sortable: false, flex: 0.2 ,headerAlign: "center", align: "center",
        //     renderCell:(params)=>(<div >{params?.row?.barcode ? params?.row?.barcode : "-"}</div>)},
        //     { field: 'status', headerName: t('ResultStatus'), sortable: false, flex: 0.2 ,headerAlign: "center", align: "center",
        //     renderCell:(params)=>(<div className={params.row.bill_type == "cancelled" ? "eMed_status_pending" : ""} >{params?.row?.status ? params?.row?.status : "-"}</div>)},
        //     { field: '', headerName: 'Action', sortable: false, flex: 0.15, headerAlign: "center", align: "center",
        //     renderCell: (cellvalues) => {
        //         return (
        //             <Stack direction="row" alignItems={'center'}>
        //                     {cellvalues.row.status != "Specimen Collected" ?
        //                       <Tooltip placement="top" title={"Specimen"} arrow>
        //                         <IconButton className='eMed_sts_spc'
        //                         disabled={!CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess}
        //                             onClick={() => {
        //                               if(cellvalues.row.bill_type == "cancelled") {

        //                               } else { 
        //                                 this.setState({
        //                                     isSpecimanClick: true,
        //                                     selectedTableRow: cellvalues.row
        //                                 }, () => {
        //                                     var data = cellvalues.row
        //                                     this.getSpecimanCollectedValue(data.id)
        //                                 })
        //                               }
        //                             }}

        //                         ><img src={ImagePaths.Speciman.default} className="labImageStyle" alt='specimen'/></IconButton>
        //                        </Tooltip> 
        //                      :
        //                      <Tooltip placement="top" title={"Specimen"} arrow>
        //                         <IconButton className='eMed_sts_spc'
        //                          disabled={!CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess}
        //                          onClick={() => {
        //                             if(cellvalues.row.bill_type == "cancelled") {

        //                             } else {  
        //                              this.setState({
        //                                 isSpecimanClick: true,
        //                                 selectedTableRow: cellvalues.row,
        //                                 isSpecimanComplete: true
        //                              }, () => {
        //                                 var data = cellvalues.row
        //                                 this.getSpecimanCollectedValue(data.id)
        //                              })
        //                             } 
        //                          }}
        //                         ><img src={ImagePaths.ResultDisable.default} className="labImageStyle" alt='disable'/></IconButton>
        //                       </Tooltip>  
        //                     }
        //                     <Tooltip placement="top" title={"Result"} arrow>
        //                     <IconButton className='eMed_cursor'
        //                      sx={{marginTop:"0.3vw"}}
        //                      disabled={!CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess}
        //                     onClick={() => { 
        //                             if(cellvalues.row.status != "Specimen Collected") {
        //                               if(cellvalues.row.bill_type != "cancelled") {   
        //                                this.setState({
        //                                    isErrorMsg: true,
        //                                    isErrorMsgText: "Kindly collect the specimen before entering the result"
        //                                })
        //                               }
        //                             } else {
        //                                 this.props.selectedTab() 
        //                             }
        //                         }}>
        //                             {cellvalues.row.status == "Specimen Collected" ?
        //                             <Link to={{ pathname: "/Results",  state: { resultList: cellvalues.row } }}>
        //                                 <div><img src={ImagePaths.Result.default} className="labImageStyle" alt='result'></img></div>
        //                             </Link> : <div><img src={ImagePaths.Result.default} className="labImageStyle" alt='result'></img></div>
        //                             }
        //                     </IconButton> 
        //                     </Tooltip>    
        //                    <div>
        //                     <IconButton
        //                         disabled = { CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess ? cellvalues.row.bill_type == "cancelled" ? true : false : true}
        //                         aria-label="more"
        //                         id="long-button"
        //                         aria-controls={cellvalues.is_multi_option}
        //                         aria-expanded={cellvalues.is_multi_option}
        //                         aria-haspopup="true"
        //                         onClick={(e) => {
        //                             this.setState({
        //                                 anchorEl: e.currentTarget,
        //                                 billSummaryID: cellvalues.row.lab_bill_summary,
        //                                 selectedTableRow: cellvalues.row,
        //                                 isSelected: true
        //                             }, () => {
        //                                 this.getPreviousReport()
        //                                 this.getReceivePaymentAmountData(cellvalues)
        //                             })

        //                         }}
        //                     >
        //                         <MoreVertIcon />
        //                     </IconButton>
        //                     {(this.state.isSelected && this.state.selectedTableRow.id ===  cellvalues.row.id) ?
        //                     <Menu
        //                         id="long-menu"
        //                         MenuListProps={{
        //                             'aria-labelledby': 'long-button',
        //                         }}

        //                         anchorEl={this.state.anchorEl}
        //                         open={Boolean(this.state.anchorEl)}
        //                         onClose={(e) => {
        //                             this.setState({
        //                                 anchorEl: null
        //                             })
        //                         }}
        //                         PaperProps={{
        //                             style: {
        //                                 maxHeight: ITEM_HEIGHT * 4.5,
        //                                 width: '20ch',
        //                             },
        //                         }}
        //                     >
        //                         {multi_option.map((option) => (
        //                             <MenuItem disabled={((this.state.amountData?.total_balance_amount === 0 && option.value == "Receive Payment") || (selectedTableRow.bill_type == "saved" && option.value == "Cancel Bill") || selectedTableRow.status == "Specimen Collected" ) || ((selectedTableRow.bill_type == "regular" && option.value == "Delete Bill") || selectedTableRow.status == "Specimen Collected") || (selectedTableRow.bill_type == "cancelled") || (selectedTableRow.bill_type == "credit" && option.value == "Delete Bill") ? true : false} key={option} selected={option === 'Pyxis'} onClick={() => {
        //                                 this.setState({
        //                                     selectedOption: option.label
        //                                 }, () => {
        //                                     if (this.state.selectedOption == "ReceivePayment") {
        //                                         // this.props.selectedTab(0, this.state.selectedTableRow, "isReceivePayment", false)
        //                                         this.setState({ SelectedBill: this.state.selectedTableRow ,CreditBillPopUp:true})
        //                                     }
        //                                     if (this.state.selectedOption == "ViewPreviousReport") {
        //                                         this.setState({
        //                                             isViewReport: true
        //                                         })
        //                                     }
        //                                     if(this.state.selectedOption == "BarCode"){
        //                                         this.setState({
        //                                             SelectedBill: this.state.selectedTableRow
        //                                         },() => this.getCommonBarCodePrint(cellvalues))
        //                                     }
        //                                     if (this.state.selectedOption == "Cancel Bill") {
        //                                         let Data = {
        //                                             id: this.state.selectedTableRow?.lab_bill_summary,
        //                                             patient_id: this.state.selectedTableRow?.patient,
        //                                             laboratory_id: null,
        //                                             amount_net: this.state.selectedTableRow?.amount_net,
        //                                             appointment_id: this.state.selectedTableRow?.appointment_id,
        //                                             ip_admission_id: this.state.selectedTableRow?.ip_admission_id
        //                                         }

        //                                         this.props.cancelBillClick(Data)
        //                                     }
        //                                     if(this.state.selectedOption == "Delete Bill") {
        //                                         this.onDeleteBill()
        //                                     }
        //                                 })
        //                             }}>
        //                                 {t(option.label)}
        //                             </MenuItem>
        //                         ))
        //                         }
        //                     </Menu>
        //                     :null}
        //                 </div>
        //             </Stack>
        //         )
        //     }
        //     }

        //   ]
        return (
            <div className='Lab_Status_Container'>
                <div className='lab_status_sub_header'>
                    <div className='eMed_sts_date'>
                        <Box className="eMed_Search_Box_">
                            <TextField
                                variant='standard'
                                placeholder='Search'
                                value={this.state.TableSearchText}
                                onChange={(e) => { this.setState({ TableSearchText: e.target.value }) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ width: '15vw' }} />
                        </Box>
                        <FormControl >
                            <InputLabel>{t("SelectDate")}</InputLabel>
                            <Select sx={{ width: "10vw" }}
                                size='small'
                                onChange={(event) => { this.setState({ dateFilter: event.target.value }, () => this.handleDate()) }}
                                value={this.state.dateFilter}
                                labelId="demo-simple-select-required-label"
                                label={t("SelectDate")}
                            >
                                {/* <MenuItem value={0}>{t("All")}</MenuItem> */}
                                <MenuItem value={1}>{t("Today")}</MenuItem>
                                <MenuItem value={2}>{t("Yesterday")}</MenuItem>
                                <MenuItem value={3}>{t("LastWeek")}</MenuItem>
                                <MenuItem value={4}>{t("LastMonth")}</MenuItem>
                                <MenuItem value={5} onClick={() => { this.setState({ datePickerPop: true }) }} >{t("Custom")}</MenuItem>
                            </Select>
                            <FormHelperText id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
                        </FormControl>
                    </div>
                    {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}
                    {/* <button className= "lab_status_printBtn" ><img className="Lab_Status_Subcontainer_Img"src={ImagePaths.PrintOff.default} alt={"print"}
                     onClick={() => {
                        this.testReportPrint()
                     }} 
                    /></button>
                    <button className= "lab_status_printBtn" ><img className="Lab_Status_Subcontainer_Img"src={ImagePaths.ExportOff.default} alt={"export"} /></button> */}
                </div>
                <div className='labDataGrid'>
                    {/* <DataGrid
                        rows={this.state.statusSummaryList}
                        columns={columns}
                        hideFooterSelectedRowCount
                        getRowId={(row) => row.id}
                        // getRowClassName={(params) =>  params.row.lab_bill_summary__status == "Cancelled" ? 'Lab_report_hightlight' : null}
                        pageSize={this.state.pageSize}
                        onPageSizeChange={(newPageSize) => {
                            this.setState({ pageSize: newPageSize })
                        }}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        headerHeight={40}
                    /> */}
                    {this.renderStatusTable()}
                    {this.state.selectedOption == "Cancel Bill" ?
                        <Dialog
                            className="emed_DialogboxOrder"
                            open={this.state.isCancelBill}
                            onClose={() => {
                                this.setState({
                                    isCancelBill: false,
                                    reason: ""
                                })
                            }}
                            maxWidth={"md"}
                        >

                            <div className='Lab_cancel_bill'>
                                <div className='Lab_Status_Close_Button'>
                                    <IconButton size='small'
                                        onClick={() => {
                                            this.setState({
                                                isCancelBill: false,
                                                reason: ""

                                            })
                                        }}
                                    > <img width="2vw" height="2vw" src={ImagePaths.Close.default} className="labCancelStyle" alt='close'></img></IconButton>
                                </div>
                                <h1>{t("Conformation")}</h1>
                                <Typography>{t("ToRemoveBill")}</Typography>
                                <TextField id="outlined-basic" sx={{ height: "10vh", width: "56.5vw" }} inputProps={{
                                    style: {
                                        height: "5vh"
                                    }
                                }} value={this.state.reason} placeholder='Enter the reason' variant="outlined" className='CancelBillComment' onChange={(e) => {
                                    this.setState({
                                        reason: e.target.value
                                    })
                                }} />
                                {/* <h1>{"Refund"}</h1>
                            <div className="Lab_Cancel_Bill_Radio">
                                <p className="Lab_Status_Radio_Text">{t("ModeOfPayment")}</p>    
                                <div className="Lab_Home_Radio_Button1">
                                    <RadioGroup
                                        row
                                        value={this.state.selectedPaymentType}
                                        onClick={(e) => {
                                           
                                        }}
                                    >
                                        <FormControlLabel value={1} control={<Radio size="small" />} label={t("Cash")} />
                                        <FormControlLabel value={2} control={<Radio size="small" />} label={t("Card")} />
                                        <FormControlLabel value={3} control={<Radio size="small" />} label={t("CashandCard")} />
                                        <FormControlLabel value={4}control={<Radio size="small" />} label={t("UPI")} />
                                        <FormControlLabel value={5} control={<Radio size="small" />} label={t("BankTransfer")} />
                                        <FormControlLabel value={6} control={<Radio size="small" />} label={t("Insurance")} />
                                    </RadioGroup>
                                </div>
                            </div> */}
                                <div className="Lab_Cancel_Popup">
                                    <div>
                                        <p className="emedhub_popup_footer_Text emedhub_popup_footer_Text2">{t("CancelHelpMessage1")}</p>
                                        <p className="emedhub_popup_footer_Text emedhub_popup_footer_Text2">{t("CancelHelpMessage2")}</p>
                                    </div>
                                    <Button size="small" sx={{ backgroundColor: "red" }} variant={this.props.buttonTwo ? "outlined" : "contained"} onClick={() => { this.onCancelPost() }}>{"Cancel Bill"}</Button>

                                </div>
                            </div>
                        </Dialog> : null}
                </div>
                {this.state.isSpecimanClick ?
                    <Dialog
                        className="emed_DialogboxOrder"
                        open={this.state.isSpecimanClick}
                        onClose={this.specimanClose}
                        maxWidth={"md"}
                    >
                        <div className='emed_Dialogboxwidth_Lab_status'>
                            <div className='sample_collect_popup'>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <p>{t("Sample Collection")}</p>
                                    <Tooltip title={'Print Barcode'}>
                                        <IconButton emed_tid='Lab_Status_Print_btn' disabled={this.state.disableBtn} onClick={()=>{this.PrintBarcode()}}>
                                        <img style={{ marginLeft: '0.6vw', width: '1.3vw', height: '1.3vw', cursor: 'pointer' }}
                                            src={ImagePaths.PrintIcons.default} alt=''/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <img 
                                src={ImagePaths.LabCloseButton.default}
                                 alt="" 
                                 onClick={()=>{this.setState({
                                    isSpecimanClick: false,
                                    consumableUnit : "",
                                    consumableValue : "",
                                    selectConsumables : null
                                })}}
                                className='eMed_sts_samp_collect'
                                 />
                            </div>
                            <div className='Lab_status_table_container'>
                                <div className='Lab_Status_Tables_Main'>
                                    <TableContainer className='Lab_Status_Tables_Con' component={Paper}>
                                        <Table stickyHeader size="small">
                                            <TableHead id=' ' sx={{ height: "4vh" }} >
                                                <TableRow>
                                                    <TableCell id="lab_home_table_Head" sx={{ flex: 0.4 }} align="left" >{t("ServiceDescription")}</TableCell>
                                                    <TableCell id="lab_home_table_Head" align="left" sx={{ flex: 0.6 }}>{t("Laboratory")}</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="Lab_Status_Table_body" sx={{ height: "7vh" }}>
                                                {this.state.outSourceOptionList && this.state.outSourceOptionList.length > 0 ?
                                                    this.state.outSourceOptionList.map((item, index) => {
                                                        return (
                                                            <TableRow>
                                                                <TableCell align="left">{item.test_name}</TableCell>
                                                                <TableCell align="left">
                                                                    <FormControl id="eMed_dropDown_Lab_status">
                                                                        <Select
                                                                            disabled={selectedTableRow.status == "Specimen Collected" ? true : false}
                                                                            sx={{ height: "4vh" }}
                                                                            size='small'
                                                                            label="Age"
                                                                            value={outSourceOptionList[index].out_source__outsource_laboratory_name ? outSourceOptionList[index].out_source__outsource_laboratory_name : "Self"}
                                                                            onChange={(e) => {
                                                                                var { outSourceOptionList } = this.state
                                                                                let data = item.out_source_list
                                                                                if(e.target.value === 'Self'){
                                                                                    outSourceOptionList[index].out_source__outsource_laboratory_name = null
                                                                                    outSourceOptionList[index].out_source_id = ''
                                                                                }else{
                                                                                    for (let i = 0; i < data.length; i++) {
                                                                                        if (data[i].out_source__outsource_laboratory_name === e.target.value) {
                                                                                            outSourceOptionList[index].out_source_id = data[i].out_source_id
                                                                                        }
                                                                                    }
                                                                                    outSourceOptionList[index].out_source__outsource_laboratory_name = e.target.value
                                                                                }
                                                                                this.setState({
                                                                                    outSourceOptionList
                                                                                })
                                                                            }}
                                                                        >
                                                                            <MenuItem key={1} value={"Self"}>{t("Self")}</MenuItem>
                                                                            {item.out_source_list && item.out_source_list.length > 0 && item.out_source_list.map((data) => (
                                                                                <MenuItem key={1} value={data.out_source__outsource_laboratory_name}>{data.out_source__outsource_laboratory_name}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                            </TableRow>)
                                                    }) :
                                                    <TableRow>
                                                        <TableCell align="center">{t("NoRecordsFound")}</TableCell>
                                                        <TableCell align="center"></TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TableContainer className='Lab_Status_Tables_Con1' component={Paper} >
                                        <Table stickyHeader size="small" >
                                            <TableHead id='Lab_Home_Table_Header' sx={{ height: "4vh" }} >
                                                <TableRow >
                                                    <TableCell id="lab_home_table_Head" sx={{ flex: 0.5 }} align="left"> {t("Sample Collected In")}</TableCell>
                                                    <TableCell id="lab_home_table_Head" sx={{ flex: 0.2 }} align="left">{t("Quantity")}</TableCell>
                                                    <TableCell id="lab_home_table_Head" sx={{ flex: 0.3 }} align="left">{t("Unit")}</TableCell>
                                                    <TableCell id="lab_home_table_Head">{t("Action")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="Lab_Status_Table_body">
                                                {this.state.sampleCollectedIn && this.state.sampleCollectedIn.length > 0 ?
                                                    this.state.sampleCollectedIn.map((item, index) => {
                                                        return (
                                                            <TableRow sx={{ height: "4vh" }}>
                                                                <TableCell align="left" sx={{ flex: 0.5 }}>{item.brand_name}</TableCell>
                                                                <TableCell align="left" sx={{ flex: 0.3 }} >
                                                                    <div className="Lab_Extra_Speciman">
                                                                        <Typography>{item.quantity}</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell sx={{ flex: 0.2 }}>
                                                                    <Typography>{item.unit ? item.unit : 0}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <img className='eMed_TestConfig_DeleteIcon eMed_cursor' src={ImagePaths.Delete.default}
                                                                        onClick={() => {
                                                                            if (selectedTableRow.status !== "Specimen Collected") {
                                                                                var { sampleCollectedIn } = this.state
                                                                                sampleCollectedIn.splice(index, 1)
                                                                                this.setState({
                                                                                    sampleCollectedIn
                                                                                })
                                                                            }
                                                                        }}
                                                                        alt="Delete"
                                                                    />
                                                                </TableCell>
                                                                <TableCell></TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                    :
                                                    <TableRow>
                                                        <TableCell align="center">{t("NoRecordsFound")}</TableCell>
                                                        <TableCell align="center"></TableCell>
                                                        <TableCell align="center"></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>}
                                                <TableRow>
                                                    <TableCell sx={{ flex: 0.5 }} align="left">
                                                        {this.renderTextField(t("EnterConsumables"), this.state.consumablesName, "consumablesName")}
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className='Lab_status_Consumable'>
                                                            {this.renderTextField("", this.state.consumableValue, "consumableValue")}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {this.renderTextField("Units", this.state.consumableUnit, "consumableUnit")}
                                                    </TableCell>
                                                    <TableCell sx={{ flex: 0.2 }} >
                                                        <Button variant="contained" id="eMed_Btn_Text" sx={{ height: "4vh" }}
                                                            disabled={selectedTableRow.status == "Specimen Collected" ? true : false}
                                                            onClick={() => {
                                                                var { sampleCollectedIn } = this.state
                                                                if (this.state.consumableValue !== "") {
                                                                    var valid = this.validCheck(selectConsumables)
                                                                    if (valid) {
                                                                        this.setState({
                                                                            isErrorMsg: true,
                                                                            isErrorMsgText: t("NameDuplicated")
                                                                        })
                                                                    }
                                                                    if (selectConsumables == null) {
                                                                        this.setState({
                                                                            isErrorMsg: true,
                                                                            isErrorMsgText: "Please select the consumables"
                                                                        })
                                                                    } else if (selectConsumables && Object.keys(selectConsumables).length > 0 && this.state.consumableValue == "") {
                                                                        this.setState({
                                                                            isErrorMsg: true,
                                                                            isErrorMsgText: "Please enter the consumable quantity"
                                                                        })
                                                                    }
                                                                    else if (selectConsumables && Object.keys(selectConsumables).length > 0 && this.state.consumableValue && this.state.consumableUnit && !valid) {
                                                                        sampleCollectedIn.push({ "brand_name": selectConsumables.product_name, "quantity": this.state.consumableValue, "unit": this.state.consumableUnit, "product_ms_id": selectConsumables.product_ms_id })
                                                                        this.setState({
                                                                            sampleCollectedIn
                                                                        }, () => {
                                                                            this.setState({
                                                                                consumablesName: "",
                                                                                consumableValue: "",
                                                                                consumableUnit: "",
                                                                                selectConsumables: null
                                                                            })
                                                                        })
                                                                    }
                                                                } else {
                                                                    this.setState({
                                                                        isErrorMsg: true,
                                                                        isErrorMsgText: "Please Enter Quantity"
                                                                    })
                                                                }
                                                            }}
                                                        >{t("Add")}</Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                <div className="Lab_Home_Table_Container_status2">
                                    <TableContainer className='ConsumableTableContainer' component={Paper} >

                                        <Table stickyHeader size="small" >
                                            <TableHead id='Lab_Home_Table_Header' sx={{ height: "4vh" }} >
                                                <TableRow className='consumable_style'>
                                                    <TableCell sx={{ flex: 0.4 }} id="lab_home_table_Head"  >{t("Consumables")} </TableCell>
                                                    <TableCell sx={{ flex: 0.3 }} id="lab_home_table_Head"  >{t("Quantity")}</TableCell>
                                                    <TableCell sx={{ flex: 0.3 }} id="lab_home_table_Head"  >{t("Unit")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="Lab_Status_Table_body">
                                                {this.state.consumableList && this.state.consumableList.length > 0 ?
                                                    this.state.consumableList.map((item, index) => {
                                                        return (
                                                            <TableRow sx={{ height: "4vh" }}>
                                                                <TableCell sx={{ flex: 0.4 }} align="left">{item.brand_name}</TableCell>
                                                                <TableCell sx={{ flex: 0.3 }} align="left">{item.quantity}</TableCell>
                                                                <TableCell sx={{ flex: 0.3 }} align="left" >{item.unit}</TableCell>
                                                            </TableRow>)
                                                    }) :
                                                    <TableRow>
                                                        <TableCell align="center">{t("NoRecordsFound")}</TableCell>
                                                        <TableCell align="center"></TableCell>
                                                        <TableCell align="center"></TableCell>

                                                    </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </div>
                        <div className='Lab_status_sample_bottom'>
                            <Button variant="outlined" size="small" disabled={selectedTableRow.status == "Specimen Collected" ? true : false} sx={{ width: "13vw", marginRight: "1vw" }} onClick={() => { this.clearData() }}>{t("Reset")}</Button>
                            <Button variant="contained" size="small" disabled={selectedTableRow.status == "Specimen Collected" ? true : false} sx={{ width: "13vw", marginRight: "1vw" }} onClick={() => {
                                this.onClickSavedChanges()
                            }}>{t("SaveChanges")}</Button>
                            {/* <button className='lab_print'
                                onClick={() => {
                                    this.setState({
                                    })
                                }}
                            ><img width="27vw" height="27vh" src={ImagePaths.Print.default}></img></button> */}
                        </div>
                    </Dialog> : null
                }
                <div>
                    {this.state.selectedOption == "ViewPreviousReport" ?
                        < Dialog
                            className="emed_DialogboxOrder"
                            open={this.state.isViewReport}
                            onClose={() => {
                                this.setState({
                                    isViewReport: false
                                })
                            }}
                            maxWidth={"md"}
                        >
                            <div className='PreviousReportPopUp'>
                                <div className='sample_collect_popup'>
                                    <p>{t("PreviousReport")}</p>
                                    <Button variant="text" onClick={() => {
                                        this.setState({
                                            selectedOption: "",
                                            isViewReport: false
                                        })
                                    }}>X</Button>
                                </div>
                                <TableContainer className='Lab_Previous_Report_Con' component={Paper}>
                                    <Table stickyHeader size="small">
                                        <TableHead id=' ' sx={{ height: "4vh" }} >
                                            <TableRow>
                                                <TableCell id="lab_home_table_Head" sx={{ flex: 0.4 }} align="left" >{t("InvoiceNumber")}</TableCell>
                                                <TableCell id="lab_home_table_Head" sx={{ flex: 0.3 }} align="left" >{t("Invoice Date")}</TableCell>
                                                <TableCell id="lab_home_table_Head" align="left" sx={{ flex: 0.3 }}> {t("Action")} </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="Lab_Status_Table_body" sx={{ height: "7vh" }}>
                                            {this.state.preReportList && this.state.preReportList.length > 0 ?
                                                this.state.preReportList.map((item, index) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell sx={{ flex: 0.4 }} align="left">{item.bill_number}</TableCell>
                                                            <TableCell align="left" sx={{ flex: 0.3 }} >{item.bill_date}</TableCell>
                                                            <TableCell align="left" sx={{ flex: 0.3 }}>
                                                                <IconButton size='small'
                                                                    onClick={() => {
                                                                        this.individualReportPrint(item.id)
                                                                    }}
                                                                > <img width="30vw" height="30vh" src={ImagePaths.PrintIcons.default} className="labImageStyle" alt='print'></img></IconButton>
                                                            </TableCell>

                                                        </TableRow>)
                                                }) :
                                                <TableRow>
                                                    <TableCell align="center">{t("NoRecordsFound")}</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Dialog> : null
                    }
                </div>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : this.state.isSuccessMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.isSuccessMsgText}
                            msgPop={this.msgClose.bind(this)}
                        /> : null
                }
                {
                    this.state.CreditBillPopUp ?
                        <CreditBillCompletePoP
                            modules={['lab']}
                            Module_ID_Key_Name={"bill_id"}
                            Module_ID_Key_Value={this.state.SelectedBill?.lab_bill_summary_id}
                            POST_Module_ID_Key_Name={this.state.SelectedBill?.ip_admission_id ? "ip_admission_id" : this.state.SelectedBill?.appointment_id ? "appointment_id" : "bill_id"}
                            POST_Module_ID_Key_Value={this.state.SelectedBill?.ip_admission_id ? this.state.SelectedBill?.ip_admission_id : this.state.SelectedBill?.appointment_id ? this.state.SelectedBill?.appointment_id : this.state.SelectedBill?.lab_bill_summary_id}
                            BillData={this.state.SelectedBill}
                            CrebillPosted={this.CrebillPosted.bind(this)}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            CollectedIn={"LAB"}
                        /> : null}
                {
                    Object.keys(this.state.BillModifyHistoryItem).length > 0 ? <EditHistoryPopup BillType={"LAB"} BillId={this.state.BillModifyHistoryItem?.lab_bill_summary_id} SelectedBill={this.state.BillModifyHistoryItem} HistoryPopClose={this.HistoryPopClose.bind(this)} sendClinicId={true}/> : null
                }
                {this.state.receiptID || this.state.pharmareceipt ?
                    <BillSuccessPoPUp creditreceipt={this.state.receiptID} phrma_creditreceipt={this.state.pharmareceipt} ClosePopUp={this.ClosesuccessPopUp.bind(this)} /> : null}
            </div>
        );
    }
    specimanClose = () => {
        this.setState({
            isSpecimanClick: false,
            resetSourceOptionList: []
        })
    }
    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: +event.target.value,
            page: 0
        });
    };
}
export default withTranslation()(Status);